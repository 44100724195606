/*eslint-disable*/
// @ts-nocheck
/* eslint-disable */
import _m0 from "protobufjs/minimal";
import { Timestamp } from "../../../google/protobuf/timestamp";
export var protobufPackage = "tumelo.votingpolicy.v1";
/** VotingInstruction provides an instruction of how someone should vote: FOR, AGAINST, ABSTAIN, WITHHOLD, NO ACTION */
export var VotingInstruction;
(function (VotingInstruction) {
    VotingInstruction[VotingInstruction["VOTING_INSTRUCTION_INVALID"] = 0] = "VOTING_INSTRUCTION_INVALID";
    VotingInstruction[VotingInstruction["VOTING_INSTRUCTION_FOR"] = 1] = "VOTING_INSTRUCTION_FOR";
    VotingInstruction[VotingInstruction["VOTING_INSTRUCTION_AGAINST"] = 2] = "VOTING_INSTRUCTION_AGAINST";
    VotingInstruction[VotingInstruction["VOTING_INSTRUCTION_ABSTAIN"] = 3] = "VOTING_INSTRUCTION_ABSTAIN";
    VotingInstruction[VotingInstruction["VOTING_INSTRUCTION_WITHHOLD"] = 4] = "VOTING_INSTRUCTION_WITHHOLD";
    VotingInstruction[VotingInstruction["VOTING_INSTRUCTION_NO_ACTION"] = 5] = "VOTING_INSTRUCTION_NO_ACTION";
    /** VOTING_INSTRUCTION_ONE_YEAR - These options are used for say on frequency votes */
    VotingInstruction[VotingInstruction["VOTING_INSTRUCTION_ONE_YEAR"] = 6] = "VOTING_INSTRUCTION_ONE_YEAR";
    VotingInstruction[VotingInstruction["VOTING_INSTRUCTION_TWO_YEARS"] = 7] = "VOTING_INSTRUCTION_TWO_YEARS";
    VotingInstruction[VotingInstruction["VOTING_INSTRUCTION_THREE_YEARS"] = 8] = "VOTING_INSTRUCTION_THREE_YEARS";
    VotingInstruction[VotingInstruction["UNRECOGNIZED"] = -1] = "UNRECOGNIZED";
})(VotingInstruction || (VotingInstruction = {}));
export function votingInstructionFromJSON(object) {
    switch (object) {
        case 0:
        case "VOTING_INSTRUCTION_INVALID":
            return VotingInstruction.VOTING_INSTRUCTION_INVALID;
        case 1:
        case "VOTING_INSTRUCTION_FOR":
            return VotingInstruction.VOTING_INSTRUCTION_FOR;
        case 2:
        case "VOTING_INSTRUCTION_AGAINST":
            return VotingInstruction.VOTING_INSTRUCTION_AGAINST;
        case 3:
        case "VOTING_INSTRUCTION_ABSTAIN":
            return VotingInstruction.VOTING_INSTRUCTION_ABSTAIN;
        case 4:
        case "VOTING_INSTRUCTION_WITHHOLD":
            return VotingInstruction.VOTING_INSTRUCTION_WITHHOLD;
        case 5:
        case "VOTING_INSTRUCTION_NO_ACTION":
            return VotingInstruction.VOTING_INSTRUCTION_NO_ACTION;
        case 6:
        case "VOTING_INSTRUCTION_ONE_YEAR":
            return VotingInstruction.VOTING_INSTRUCTION_ONE_YEAR;
        case 7:
        case "VOTING_INSTRUCTION_TWO_YEARS":
            return VotingInstruction.VOTING_INSTRUCTION_TWO_YEARS;
        case 8:
        case "VOTING_INSTRUCTION_THREE_YEARS":
            return VotingInstruction.VOTING_INSTRUCTION_THREE_YEARS;
        case -1:
        case "UNRECOGNIZED":
        default:
            return VotingInstruction.UNRECOGNIZED;
    }
}
export function votingInstructionToJSON(object) {
    switch (object) {
        case VotingInstruction.VOTING_INSTRUCTION_INVALID:
            return "VOTING_INSTRUCTION_INVALID";
        case VotingInstruction.VOTING_INSTRUCTION_FOR:
            return "VOTING_INSTRUCTION_FOR";
        case VotingInstruction.VOTING_INSTRUCTION_AGAINST:
            return "VOTING_INSTRUCTION_AGAINST";
        case VotingInstruction.VOTING_INSTRUCTION_ABSTAIN:
            return "VOTING_INSTRUCTION_ABSTAIN";
        case VotingInstruction.VOTING_INSTRUCTION_WITHHOLD:
            return "VOTING_INSTRUCTION_WITHHOLD";
        case VotingInstruction.VOTING_INSTRUCTION_NO_ACTION:
            return "VOTING_INSTRUCTION_NO_ACTION";
        case VotingInstruction.VOTING_INSTRUCTION_ONE_YEAR:
            return "VOTING_INSTRUCTION_ONE_YEAR";
        case VotingInstruction.VOTING_INSTRUCTION_TWO_YEARS:
            return "VOTING_INSTRUCTION_TWO_YEARS";
        case VotingInstruction.VOTING_INSTRUCTION_THREE_YEARS:
            return "VOTING_INSTRUCTION_THREE_YEARS";
        case VotingInstruction.UNRECOGNIZED:
        default:
            return "UNRECOGNIZED";
    }
}
function createBaseVotingRecommendation() {
    return {
        name: "",
        proposalName: "",
        votingInstruction: 0,
        rationale: "",
        createTime: undefined,
        updateTime: undefined,
        proposalText: "",
        proposalLabel: "",
        proposalSequenceNumber: 0,
    };
}
export var VotingRecommendation = {
    encode: function (message, writer) {
        if (writer === void 0) { writer = _m0.Writer.create(); }
        if (message.name !== "") {
            writer.uint32(10).string(message.name);
        }
        if (message.proposalName !== "") {
            writer.uint32(26).string(message.proposalName);
        }
        if (message.votingInstruction !== 0) {
            writer.uint32(32).int32(message.votingInstruction);
        }
        if (message.rationale !== "") {
            writer.uint32(42).string(message.rationale);
        }
        if (message.createTime !== undefined) {
            Timestamp.encode(toTimestamp(message.createTime), writer.uint32(50).fork()).ldelim();
        }
        if (message.updateTime !== undefined) {
            Timestamp.encode(toTimestamp(message.updateTime), writer.uint32(58).fork()).ldelim();
        }
        if (message.proposalText !== "") {
            writer.uint32(66).string(message.proposalText);
        }
        if (message.proposalLabel !== "") {
            writer.uint32(74).string(message.proposalLabel);
        }
        if (message.proposalSequenceNumber !== 0) {
            writer.uint32(80).int32(message.proposalSequenceNumber);
        }
        return writer;
    },
    decode: function (input, length) {
        var reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
        var end = length === undefined ? reader.len : reader.pos + length;
        var message = createBaseVotingRecommendation();
        while (reader.pos < end) {
            var tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    if (tag !== 10) {
                        break;
                    }
                    message.name = reader.string();
                    continue;
                case 3:
                    if (tag !== 26) {
                        break;
                    }
                    message.proposalName = reader.string();
                    continue;
                case 4:
                    if (tag !== 32) {
                        break;
                    }
                    message.votingInstruction = reader.int32();
                    continue;
                case 5:
                    if (tag !== 42) {
                        break;
                    }
                    message.rationale = reader.string();
                    continue;
                case 6:
                    if (tag !== 50) {
                        break;
                    }
                    message.createTime = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
                    continue;
                case 7:
                    if (tag !== 58) {
                        break;
                    }
                    message.updateTime = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
                    continue;
                case 8:
                    if (tag !== 66) {
                        break;
                    }
                    message.proposalText = reader.string();
                    continue;
                case 9:
                    if (tag !== 74) {
                        break;
                    }
                    message.proposalLabel = reader.string();
                    continue;
                case 10:
                    if (tag !== 80) {
                        break;
                    }
                    message.proposalSequenceNumber = reader.int32();
                    continue;
            }
            if ((tag & 7) === 4 || tag === 0) {
                break;
            }
            reader.skipType(tag & 7);
        }
        return message;
    },
    fromJSON: function (object) {
        return {
            name: isSet(object.name) ? String(object.name) : "",
            proposalName: isSet(object.proposalName) ? String(object.proposalName) : "",
            votingInstruction: isSet(object.votingInstruction) ? votingInstructionFromJSON(object.votingInstruction) : 0,
            rationale: isSet(object.rationale) ? String(object.rationale) : "",
            createTime: isSet(object.createTime) ? fromJsonTimestamp(object.createTime) : undefined,
            updateTime: isSet(object.updateTime) ? fromJsonTimestamp(object.updateTime) : undefined,
            proposalText: isSet(object.proposalText) ? String(object.proposalText) : "",
            proposalLabel: isSet(object.proposalLabel) ? String(object.proposalLabel) : "",
            proposalSequenceNumber: isSet(object.proposalSequenceNumber) ? Number(object.proposalSequenceNumber) : 0,
        };
    },
    toJSON: function (message) {
        var obj = {};
        if (message.name !== "") {
            obj.name = message.name;
        }
        if (message.proposalName !== "") {
            obj.proposalName = message.proposalName;
        }
        if (message.votingInstruction !== 0) {
            obj.votingInstruction = votingInstructionToJSON(message.votingInstruction);
        }
        if (message.rationale !== "") {
            obj.rationale = message.rationale;
        }
        if (message.createTime !== undefined) {
            obj.createTime = message.createTime.toISOString();
        }
        if (message.updateTime !== undefined) {
            obj.updateTime = message.updateTime.toISOString();
        }
        if (message.proposalText !== "") {
            obj.proposalText = message.proposalText;
        }
        if (message.proposalLabel !== "") {
            obj.proposalLabel = message.proposalLabel;
        }
        if (message.proposalSequenceNumber !== 0) {
            obj.proposalSequenceNumber = Math.round(message.proposalSequenceNumber);
        }
        return obj;
    },
    create: function (base) {
        return VotingRecommendation.fromPartial(base !== null && base !== void 0 ? base : {});
    },
    fromPartial: function (object) {
        var _a, _b, _c, _d, _e, _f, _g, _h, _j;
        var message = createBaseVotingRecommendation();
        message.name = (_a = object.name) !== null && _a !== void 0 ? _a : "";
        message.proposalName = (_b = object.proposalName) !== null && _b !== void 0 ? _b : "";
        message.votingInstruction = (_c = object.votingInstruction) !== null && _c !== void 0 ? _c : 0;
        message.rationale = (_d = object.rationale) !== null && _d !== void 0 ? _d : "";
        message.createTime = (_e = object.createTime) !== null && _e !== void 0 ? _e : undefined;
        message.updateTime = (_f = object.updateTime) !== null && _f !== void 0 ? _f : undefined;
        message.proposalText = (_g = object.proposalText) !== null && _g !== void 0 ? _g : "";
        message.proposalLabel = (_h = object.proposalLabel) !== null && _h !== void 0 ? _h : "";
        message.proposalSequenceNumber = (_j = object.proposalSequenceNumber) !== null && _j !== void 0 ? _j : 0;
        return message;
    },
};
function toTimestamp(date) {
    var seconds = date.getTime() / 1000;
    var nanos = (date.getTime() % 1000) * 1000000;
    return { seconds: seconds, nanos: nanos };
}
function fromTimestamp(t) {
    var millis = (t.seconds || 0) * 1000;
    millis += (t.nanos || 0) / 1000000;
    return new globalThis.Date(millis);
}
function fromJsonTimestamp(o) {
    if (o instanceof globalThis.Date) {
        return o;
    }
    else if (typeof o === "string") {
        return new globalThis.Date(o);
    }
    else {
        return fromTimestamp(Timestamp.fromJSON(o));
    }
}
function isSet(value) {
    return value !== null && value !== undefined;
}
