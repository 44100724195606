import React from 'react'
import { PageWrapper, SpacerProps } from '@tumelo/designsystem'
import styled, { css } from 'styled-components'
import { Header } from './Header'
import { Footer } from './Footer'
import { LayoutWrapper } from './LayoutWrapper'

interface PageProps {
  children: React.ReactNode
  mainMarginTop?: SpacerProps
  mainMarginBottom?: SpacerProps
  columns?: boolean
}

export const PageLayout: React.FC<PageProps> = ({ children, mainMarginTop, mainMarginBottom, columns }) => {
  return (
    <LayoutWrapper>
      <Header />
      <div>
        <PageWrapper center>
          <StyledMain mainMarginTop={mainMarginTop} mainMarginBottom={mainMarginBottom} columns={columns}>
            {children}
          </StyledMain>
        </PageWrapper>
      </div>
      <StyledFooter />
    </LayoutWrapper>
  )
}

const StyledMain = styled.main<PageProps>`
  margin-top: ${props => props.mainMarginTop || '0'};
  margin-bottom: ${props => props.mainMarginBottom || '0'};
  min-height: auto;
  ${({ columns }) =>
    columns &&
    css`
      height: 100%;
    `};
`

const StyledFooter = styled(Footer)`
  margin-top: auto;
`
