/*eslint-disable*/
// @ts-nocheck
/* eslint-disable */
import { grpc } from "@improbable-eng/grpc-web";
import { BrowserHeaders } from "browser-headers";
import _m0 from "protobufjs/minimal";
import { Empty } from "../../../google/protobuf/empty";
import { Timestamp } from "../../../google/protobuf/timestamp";
import { File } from "../../lib/v1/file";
import { Poll } from "../../poll/v1/poll";
import { GroupedVoteComments, PollVoteComments } from "../../poll/v1/vote_comments";
import { GroupedVotePreferences, PollVotePreferences } from "../../poll/v1/vote_preferences";
import { GeneralMeeting } from "../../transparency/v1/general_meeting";
import { Organization } from "../../transparency/v1/organization";
import { Proposal } from "../../transparency/v1/proposal";
import { Ballot } from "./ballot";
import { CompositeInstrument, CompositeTreeEdge } from "./composite_instrument";
import { DetailedUser } from "./detailed_user";
import { FeatureFlag } from "./feature_flag";
import { FundManager, FundManagerPolicy } from "./fund_manager";
import { GeneralMeeting as GeneralMeeting1 } from "./general_meeting";
import { Option, optionFromJSON, optionToJSON } from "./option";
import { Organization as Organization2 } from "./organization";
import { Proposal as Proposal3 } from "./proposal";
import { CalendarQuarter, QuarterlyReport } from "./quarterly_report";
import { Scheme } from "./scheme";
import { Privileges, User, UserSchemeLink } from "./user";
import { VotingPolicy } from "./voting_policy";

export const protobufPackage = "tumelo.stewardshipbff.v1";

export interface GetFundManagerRequest {
  /** name in format fundManagers/{uuid} */
  name: string;
  /** OPTIONAL scheme name. */
  schemeName: string;
}

export interface GetFundManagerResponse {
  /** the requested fund manager */
  fundManager:
    | FundManager
    | undefined;
  /** a list of composite instruments managed by this fund manager */
  compositeInstruments: CompositeInstrument[];
  /** a list of policy documents for this fund manager */
  policies: FundManagerPolicy[];
}

export interface AdminListUsersRequest {
}

export interface AdminListUsersResponse {
  users: User[];
}

export interface AdminListDetailedUsersRequest {
}

export interface AdminListDetailedUsersResponse {
  detailedUsers: DetailedUser[];
}

export interface AdminUpdateUserRequest {
  user: User | undefined;
}

export interface AdminCreateUserRequest {
  email: string;
  linkedSchemes: UserSchemeLink[];
}

export interface AdminDeleteUserRequest {
  email: string;
  sub: string;
}

export interface FetchAnonymizedVotePreferencesBySchemeRequest {
  /**
   * REQUIRED scheme_names a list of names of schemes to report on
   * Each scheme name in the format 'habitats/{uuid}/schemes/{uuid}'.
   */
  schemeNames: string[];
}

/** FetchAnonymizedVotePreferencesBySchemeResponse contains anonymized vote preferences grouped by scheme as well as the referenced polls, proposals, general meetings and organizations */
export interface FetchAnonymizedVotePreferencesBySchemeResponse {
  /** the time at which the summary was generated */
  readTime:
    | Date
    | undefined;
  /** list of summaries (one per poll). A summary contains the sum of vote preferences (total/for/against/...) for each poll across the requested schemes */
  summary: PollVotePreferences[];
  /** map of vote preferences, key is scheme name (format 'habitats/{uuid}/schemes/{uuid}'), value is the vote preferences object */
  votePreferencesByScheme: { [key: string]: GroupedVotePreferences };
  /** map of polls, key is the poll name (format polls/{uuid}), value is the poll object */
  polls: { [key: string]: Poll };
  /** map of proposals, key is the proposal name (format organizations/{uuid}/generalMeetings/{uuid}/proposals/{uuid}), value is the proposal object */
  proposals: { [key: string]: Proposal };
  /** map of general meetings, key is the general meeting name (format organizations/{uuid}/generalMeetings/{uuid}), value is the general meeting object */
  generalMeetings: { [key: string]: GeneralMeeting };
  /** map of organizations, key is the organization name (format organizations/{uuid}), value is the organization object */
  organizations: { [key: string]: Organization };
}

export interface FetchAnonymizedVotePreferencesBySchemeResponse_VotePreferencesBySchemeEntry {
  key: string;
  value: GroupedVotePreferences | undefined;
}

export interface FetchAnonymizedVotePreferencesBySchemeResponse_PollsEntry {
  key: string;
  value: Poll | undefined;
}

export interface FetchAnonymizedVotePreferencesBySchemeResponse_ProposalsEntry {
  key: string;
  value: Proposal | undefined;
}

export interface FetchAnonymizedVotePreferencesBySchemeResponse_GeneralMeetingsEntry {
  key: string;
  value: GeneralMeeting | undefined;
}

export interface FetchAnonymizedVotePreferencesBySchemeResponse_OrganizationsEntry {
  key: string;
  value: Organization | undefined;
}

export interface FetchAnonymizedVoteCommentsBySchemeRequest {
  /**
   * REQUIRED scheme_names is the names of the schemes to report on
   * In the format 'habitats/{uuid}/schemes/{uuid}'.
   */
  schemeNames: string[];
}

/** FetchAnonymizedVoteCommentsBySchemeResponse contains anonymized vote preferences grouped by scheme as well as the referenced polls, proposals, general meetings and organizations */
export interface FetchAnonymizedVoteCommentsBySchemeResponse {
  /** the time at which the summary was generated */
  readTime:
    | Date
    | undefined;
  /** list of summaries (one per poll). A summary contains the list of vote/comment pairs for each poll across the requested schemes */
  summary: PollVoteComments[];
  /** map of vote preferences, key is scheme name (format 'habitats/{uuid}/schemes/{uuid}'), value is the vote comments object */
  voteCommentsByScheme: { [key: string]: GroupedVoteComments };
  /** map of polls, key is the poll name (format polls/{uuid}), value is the poll object */
  polls: { [key: string]: Poll };
  /** map of proposals, key is the proposal name (format organizations/{uuid}/generalMeetings/{uuid}/proposals/{uuid}), value is the proposal object */
  proposals: { [key: string]: Proposal };
  /** map of general meetings, key is the general meeting name (format organizations/{uuid}/generalMeetings/{uuid}), value is the general meeting object */
  generalMeetings: { [key: string]: GeneralMeeting };
  /** map of organizations, key is the organization name (format organizations/{uuid}), value is the organization object */
  organizations: { [key: string]: Organization };
}

export interface FetchAnonymizedVoteCommentsBySchemeResponse_VoteCommentsBySchemeEntry {
  key: string;
  value: GroupedVoteComments | undefined;
}

export interface FetchAnonymizedVoteCommentsBySchemeResponse_PollsEntry {
  key: string;
  value: Poll | undefined;
}

export interface FetchAnonymizedVoteCommentsBySchemeResponse_ProposalsEntry {
  key: string;
  value: Proposal | undefined;
}

export interface FetchAnonymizedVoteCommentsBySchemeResponse_GeneralMeetingsEntry {
  key: string;
  value: GeneralMeeting | undefined;
}

export interface FetchAnonymizedVoteCommentsBySchemeResponse_OrganizationsEntry {
  key: string;
  value: Organization | undefined;
}

export interface FetchPrivilegesRequest {
}

export interface GetUserRequest {
}

export interface ListSchemesRequest {
  /**
   * OPTIONAL pagination token
   * TODO (TUM-1808) Parameter is ignored as pagination is not implemented on this endpoint currently.
   */
  pageToken: string;
  /**
   * OPTIONAL page size
   * TODO (TUM-1808) Parameter is ignored as pagination is not implemented on this endpoint currently.
   */
  pageSize: number;
  /**
   * OPTIONAL filter on scheme names (format 'habitats/{habitatID}/schemes/{schemeID}')
   * if blank all schemes that can be accessed by the current user will be returned
   */
  names: string[];
}

export interface ListSchemesResponse {
  /** a list of schemes */
  schemes: Scheme[];
  /** pagination token */
  nextPageToken: string;
}

export interface SubmitVoteRequest {
  /** REQUIRED the name of the ballot (format 'ballots/{uuid}') */
  ballotName: string;
  /** REQUIRED the name of the scheme (format 'habitats/{habitatID}/schemes/{schemeID}') */
  schemeName: string;
  /** REQUIRED how the investor voted on this ballot */
  response: Option;
  /** OPTIONAL the name of the proposal (format 'organizations/{uuid}/generalMeetings/{uuid}/proposals/{uuid}') */
  proposalName: string;
}

export interface SubmitVoteResponse {
  /** REQUIRED how the investor voted on this ballot */
  response: Option;
}

export interface SubmitBallotCommentRequest {
  /** REQUIRED the name of the ballot (format 'ballots/{uuid}') */
  ballotName: string;
  /** REQUIRED the name of the scheme (format 'habitats/{habitatID}/schemes/{schemeID}') */
  schemeName: string;
  /** REQUIRED the investor's comment on this ballot */
  investorComment: string;
  /** OPTIONAL the name of the proposal (format 'organizations/{uuid}/generalMeetings/{uuid}/proposals/{uuid}') */
  proposalName: string;
}

export interface SubmitBallotCommentResponse {
  /** the comment the investor wishes to submit */
  investorComment: string;
}

export interface ListGeneralMeetingsRequest {
  /** OPTIONAL page size */
  pageSize: number;
  /** OPTIONAL pagination token */
  pageToken: string;
  /** REQUIRED filter on scheme names (format 'habitats/{habitatID}/schemes/{schemeID}') */
  schemeName: string;
  /** OPTIONAL case insensitive filter that can be used to search by organization title (our display title or the legal title) */
  search: string;
}

export interface ListGeneralMeetingsResponse {
  /**
   * next_page_token contains a string which can be used to get the next page of results
   * It will be empty if there are no more results
   */
  nextPageToken: string;
  /** list of meetings that matched the request */
  meetings: GeneralMeeting1[];
  /**
   * map of organization names to organizations of meetings that matched the request
   * key is the organization name (format organizations/{uuid}), value is the organization object
   */
  organizations: { [key: string]: Organization2 };
  /**
   * map of general meetings to ballot names
   * key is the general meeting name (format organizations/{uuid}/generalmeetings/{uuid}), value is the ballot name (format habitat/{uuid}/meetingBallots/{uuid})
   */
  meetingNamesToBallotNames: { [key: string]: string };
}

export interface ListGeneralMeetingsResponse_OrganizationsEntry {
  key: string;
  value: Organization2 | undefined;
}

export interface ListGeneralMeetingsResponse_MeetingNamesToBallotNamesEntry {
  key: string;
  value: string;
}

export interface GetGeneralMeetingRequest {
  /**
   * REQUIRED the current scheme (format 'habitats/{habitatID}/schemes/{schemeID}')
   *
   * Used to fetch the correct vote and comment data.
   */
  schemeName: string;
  /** REQUIRED the meeting ballot to fetch (format 'habitats/{uuid}/meetingBallots/{uuid}') */
  meetingBallotName: string;
}

export interface GetGeneralMeetingResponse {
  /** The general meeting that was requested */
  meeting:
    | GeneralMeeting1
    | undefined;
  /** organization the general meeting is for */
  organization:
    | Organization2
    | undefined;
  /** list of proposals, ordered by their sequence identifier */
  proposals: Proposal3[];
  /**
   * map of proposal names to ballots containing the vote instruction (for/against/..., if any) and comment (if any)
   * key is the proposal name (format organizations/{uuid}/generalmeetings/{uuid}/proposals/{uuid}), value is the ballot object
   */
  ballots: { [key: string]: Ballot };
}

export interface GetGeneralMeetingResponse_BallotsEntry {
  key: string;
  value: Ballot | undefined;
}

export interface ListFeatureFlagsRequest {
  /** OPTIONAL pagination token */
  pageToken: string;
  /** OPTIONAL page size */
  pageSize: number;
  /**
   * OPTIONAL filter on feature flag keys
   * if blank all feature flags will be returned
   */
  keys: string[];
}

export interface AdminListFeatureFlagsRequest {
  /** OPTIONAL pagination token */
  pageToken: string;
  /** OPTIONAL page size */
  pageSize: number;
  /**
   * OPTIONAL filter on feature flag keys
   * if blank all feature flags will be returned
   */
  keys: string[];
}

export interface ListFeatureFlagsResponse {
  /** a list of feature flags */
  featureFlags: FeatureFlag[];
  /** pagination token */
  nextPageToken: string;
}

export interface AdminUpdateFeatureFlagRequest {
  /** the feature flag to be updated */
  featureFlag: FeatureFlag | undefined;
}

export interface AdminCreateUserFeatureFlagOverrideRequest {
  /** the user ID of the user */
  userId: string;
  /** the feature flag to override */
  featureFlag: FeatureFlag | undefined;
}

export interface AdminDeleteUserFeatureFlagOverrideRequest {
  /** the user ID of the user */
  userId: string;
  /** the key of the feature flag */
  featureFlagKey: string;
}

export interface FetchInvestmentsRequest {
  /** OPTIONAL The name of the Scheme to fetch investment data for. If no scheme name is provided, all data for all Schemes the current user has access to will be retrieved. */
  schemeName: string;
}

export interface FetchInvestmentsResponse {
  /**
   * Map of Security ID to Composite Instrument. The key is Security ID, the value is the composite instrument object
   * Valid formats for the Security ID:
   * - 'securityIDTypes/{type}/securityIDs/{value}'
   * - 'fundManagers/{UUID}/securityIDTypes/{type}/securityIDs/{value}'
   * - 'providers/{UUID}/securityIDTypes/{type}/securityIDs/{value}'
   */
  compositeInstruments: { [key: string]: CompositeInstrument };
  /** Map of Fund Manager Name to Title. Key is fund manager name (format fundmanagers/{uuid}), value is fund manager title. */
  fundManagerNameToTitle: { [key: string]: string };
  /** Map of Scheme Name to Title. Key is scheme name (format habitats/{uuid}/schemes/{uuid}), value is scheme title. */
  schemeNameToTitle: { [key: string]: string };
  /** List of Edges between Composite Instruments */
  edges: CompositeTreeEdge[];
}

export interface FetchInvestmentsResponse_CompositeInstrumentsEntry {
  key: string;
  value: CompositeInstrument | undefined;
}

export interface FetchInvestmentsResponse_FundManagerNameToTitleEntry {
  key: string;
  value: string;
}

export interface FetchInvestmentsResponse_SchemeNameToTitleEntry {
  key: string;
  value: string;
}

export interface ListFundManagersRequest {
  /** OPTIONAL pagination token. */
  pageToken: string;
  /** OPTIONAL page size. */
  pageSize: number;
  /** OPTIONAL scheme name. */
  schemeName: string;
}

export interface ListFundManagersResponse {
  /** List of fund managers available to the logged in user. */
  fundManagers: FundManager[];
  /** Token to fetch the next page. */
  nextPageToken: string;
}

export interface GetVotingPolicyRequest {
  /**
   * REQUIRED scheme_name which the voting policy should be generated for.
   *
   * Example value: habitats/{uuid}/schemes/{uuid}
   */
  schemeName: string;
}

export interface GetVotingPolicyResponse {
  /** OPTIONAL voting_policy associated with the given scheme. */
  votingPolicy: VotingPolicy | undefined;
}

export interface AdminDeleteUserResponse {
}

export interface AdminPutQuarterlyReportRequest {
  /** REQUIRED the calendar quarter which the report covers (e.g Q1 2024). */
  calendarQuarter:
    | CalendarQuarter
    | undefined;
  /** REQUIRED the scheme to which the report belongs, in the form habitats/{uuid}/schemes/{uuid}. */
  parent: string;
  /** REQUIRED the file to be uploaded, must be < 10mb in size. The file name will be displayed in the Stewardship Platform UI and should include the file extension. */
  file: File | undefined;
}

/** ListQuarterlyReportsRequest does not include pagination, as the expected number of reports is low. */
export interface ListQuarterlyReportsRequest {
  /** REQUIRED The scheme to which the report belongs. */
  parent: string;
  /** OPTIONAL The calendar quarter for which to retrieve reports (e.g. Q1 2024). */
  calendarQuarter: CalendarQuarter | undefined;
}

export interface ListQuarterlyReportsResponse {
  /** Quarterly reports */
  quarterlyReports: QuarterlyReport[];
}

function createBaseGetFundManagerRequest(): GetFundManagerRequest {
  return { name: "", schemeName: "" };
}

export const GetFundManagerRequest = {
  encode(message: GetFundManagerRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.name !== "") {
      writer.uint32(10).string(message.name);
    }
    if (message.schemeName !== "") {
      writer.uint32(26).string(message.schemeName);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GetFundManagerRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetFundManagerRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.name = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.schemeName = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): GetFundManagerRequest {
    return {
      name: isSet(object.name) ? String(object.name) : "",
      schemeName: isSet(object.schemeName) ? String(object.schemeName) : "",
    };
  },

  toJSON(message: GetFundManagerRequest): unknown {
    const obj: any = {};
    if (message.name !== "") {
      obj.name = message.name;
    }
    if (message.schemeName !== "") {
      obj.schemeName = message.schemeName;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<GetFundManagerRequest>, I>>(base?: I): GetFundManagerRequest {
    return GetFundManagerRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<GetFundManagerRequest>, I>>(object: I): GetFundManagerRequest {
    const message = createBaseGetFundManagerRequest();
    message.name = object.name ?? "";
    message.schemeName = object.schemeName ?? "";
    return message;
  },
};

function createBaseGetFundManagerResponse(): GetFundManagerResponse {
  return { fundManager: undefined, compositeInstruments: [], policies: [] };
}

export const GetFundManagerResponse = {
  encode(message: GetFundManagerResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.fundManager !== undefined) {
      FundManager.encode(message.fundManager, writer.uint32(10).fork()).ldelim();
    }
    for (const v of message.compositeInstruments) {
      CompositeInstrument.encode(v!, writer.uint32(18).fork()).ldelim();
    }
    for (const v of message.policies) {
      FundManagerPolicy.encode(v!, writer.uint32(26).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GetFundManagerResponse {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetFundManagerResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.fundManager = FundManager.decode(reader, reader.uint32());
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.compositeInstruments.push(CompositeInstrument.decode(reader, reader.uint32()));
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.policies.push(FundManagerPolicy.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): GetFundManagerResponse {
    return {
      fundManager: isSet(object.fundManager) ? FundManager.fromJSON(object.fundManager) : undefined,
      compositeInstruments: globalThis.Array.isArray(object?.compositeInstruments)
        ? object.compositeInstruments.map((e: any) => CompositeInstrument.fromJSON(e))
        : [],
      policies: globalThis.Array.isArray(object?.policies)
        ? object.policies.map((e: any) => FundManagerPolicy.fromJSON(e))
        : [],
    };
  },

  toJSON(message: GetFundManagerResponse): unknown {
    const obj: any = {};
    if (message.fundManager !== undefined) {
      obj.fundManager = FundManager.toJSON(message.fundManager);
    }
    if (message.compositeInstruments?.length) {
      obj.compositeInstruments = message.compositeInstruments.map((e) => CompositeInstrument.toJSON(e));
    }
    if (message.policies?.length) {
      obj.policies = message.policies.map((e) => FundManagerPolicy.toJSON(e));
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<GetFundManagerResponse>, I>>(base?: I): GetFundManagerResponse {
    return GetFundManagerResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<GetFundManagerResponse>, I>>(object: I): GetFundManagerResponse {
    const message = createBaseGetFundManagerResponse();
    message.fundManager = (object.fundManager !== undefined && object.fundManager !== null)
      ? FundManager.fromPartial(object.fundManager)
      : undefined;
    message.compositeInstruments = object.compositeInstruments?.map((e) => CompositeInstrument.fromPartial(e)) || [];
    message.policies = object.policies?.map((e) => FundManagerPolicy.fromPartial(e)) || [];
    return message;
  },
};

function createBaseAdminListUsersRequest(): AdminListUsersRequest {
  return {};
}

export const AdminListUsersRequest = {
  encode(_: AdminListUsersRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): AdminListUsersRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseAdminListUsersRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(_: any): AdminListUsersRequest {
    return {};
  },

  toJSON(_: AdminListUsersRequest): unknown {
    const obj: any = {};
    return obj;
  },

  create<I extends Exact<DeepPartial<AdminListUsersRequest>, I>>(base?: I): AdminListUsersRequest {
    return AdminListUsersRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<AdminListUsersRequest>, I>>(_: I): AdminListUsersRequest {
    const message = createBaseAdminListUsersRequest();
    return message;
  },
};

function createBaseAdminListUsersResponse(): AdminListUsersResponse {
  return { users: [] };
}

export const AdminListUsersResponse = {
  encode(message: AdminListUsersResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.users) {
      User.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): AdminListUsersResponse {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseAdminListUsersResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.users.push(User.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): AdminListUsersResponse {
    return { users: globalThis.Array.isArray(object?.users) ? object.users.map((e: any) => User.fromJSON(e)) : [] };
  },

  toJSON(message: AdminListUsersResponse): unknown {
    const obj: any = {};
    if (message.users?.length) {
      obj.users = message.users.map((e) => User.toJSON(e));
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<AdminListUsersResponse>, I>>(base?: I): AdminListUsersResponse {
    return AdminListUsersResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<AdminListUsersResponse>, I>>(object: I): AdminListUsersResponse {
    const message = createBaseAdminListUsersResponse();
    message.users = object.users?.map((e) => User.fromPartial(e)) || [];
    return message;
  },
};

function createBaseAdminListDetailedUsersRequest(): AdminListDetailedUsersRequest {
  return {};
}

export const AdminListDetailedUsersRequest = {
  encode(_: AdminListDetailedUsersRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): AdminListDetailedUsersRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseAdminListDetailedUsersRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(_: any): AdminListDetailedUsersRequest {
    return {};
  },

  toJSON(_: AdminListDetailedUsersRequest): unknown {
    const obj: any = {};
    return obj;
  },

  create<I extends Exact<DeepPartial<AdminListDetailedUsersRequest>, I>>(base?: I): AdminListDetailedUsersRequest {
    return AdminListDetailedUsersRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<AdminListDetailedUsersRequest>, I>>(_: I): AdminListDetailedUsersRequest {
    const message = createBaseAdminListDetailedUsersRequest();
    return message;
  },
};

function createBaseAdminListDetailedUsersResponse(): AdminListDetailedUsersResponse {
  return { detailedUsers: [] };
}

export const AdminListDetailedUsersResponse = {
  encode(message: AdminListDetailedUsersResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.detailedUsers) {
      DetailedUser.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): AdminListDetailedUsersResponse {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseAdminListDetailedUsersResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.detailedUsers.push(DetailedUser.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): AdminListDetailedUsersResponse {
    return {
      detailedUsers: globalThis.Array.isArray(object?.detailedUsers)
        ? object.detailedUsers.map((e: any) => DetailedUser.fromJSON(e))
        : [],
    };
  },

  toJSON(message: AdminListDetailedUsersResponse): unknown {
    const obj: any = {};
    if (message.detailedUsers?.length) {
      obj.detailedUsers = message.detailedUsers.map((e) => DetailedUser.toJSON(e));
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<AdminListDetailedUsersResponse>, I>>(base?: I): AdminListDetailedUsersResponse {
    return AdminListDetailedUsersResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<AdminListDetailedUsersResponse>, I>>(
    object: I,
  ): AdminListDetailedUsersResponse {
    const message = createBaseAdminListDetailedUsersResponse();
    message.detailedUsers = object.detailedUsers?.map((e) => DetailedUser.fromPartial(e)) || [];
    return message;
  },
};

function createBaseAdminUpdateUserRequest(): AdminUpdateUserRequest {
  return { user: undefined };
}

export const AdminUpdateUserRequest = {
  encode(message: AdminUpdateUserRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.user !== undefined) {
      User.encode(message.user, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): AdminUpdateUserRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseAdminUpdateUserRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.user = User.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): AdminUpdateUserRequest {
    return { user: isSet(object.user) ? User.fromJSON(object.user) : undefined };
  },

  toJSON(message: AdminUpdateUserRequest): unknown {
    const obj: any = {};
    if (message.user !== undefined) {
      obj.user = User.toJSON(message.user);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<AdminUpdateUserRequest>, I>>(base?: I): AdminUpdateUserRequest {
    return AdminUpdateUserRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<AdminUpdateUserRequest>, I>>(object: I): AdminUpdateUserRequest {
    const message = createBaseAdminUpdateUserRequest();
    message.user = (object.user !== undefined && object.user !== null) ? User.fromPartial(object.user) : undefined;
    return message;
  },
};

function createBaseAdminCreateUserRequest(): AdminCreateUserRequest {
  return { email: "", linkedSchemes: [] };
}

export const AdminCreateUserRequest = {
  encode(message: AdminCreateUserRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.email !== "") {
      writer.uint32(10).string(message.email);
    }
    for (const v of message.linkedSchemes) {
      UserSchemeLink.encode(v!, writer.uint32(106).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): AdminCreateUserRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseAdminCreateUserRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.email = reader.string();
          continue;
        case 13:
          if (tag !== 106) {
            break;
          }

          message.linkedSchemes.push(UserSchemeLink.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): AdminCreateUserRequest {
    return {
      email: isSet(object.email) ? String(object.email) : "",
      linkedSchemes: globalThis.Array.isArray(object?.linkedSchemes)
        ? object.linkedSchemes.map((e: any) => UserSchemeLink.fromJSON(e))
        : [],
    };
  },

  toJSON(message: AdminCreateUserRequest): unknown {
    const obj: any = {};
    if (message.email !== "") {
      obj.email = message.email;
    }
    if (message.linkedSchemes?.length) {
      obj.linkedSchemes = message.linkedSchemes.map((e) => UserSchemeLink.toJSON(e));
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<AdminCreateUserRequest>, I>>(base?: I): AdminCreateUserRequest {
    return AdminCreateUserRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<AdminCreateUserRequest>, I>>(object: I): AdminCreateUserRequest {
    const message = createBaseAdminCreateUserRequest();
    message.email = object.email ?? "";
    message.linkedSchemes = object.linkedSchemes?.map((e) => UserSchemeLink.fromPartial(e)) || [];
    return message;
  },
};

function createBaseAdminDeleteUserRequest(): AdminDeleteUserRequest {
  return { email: "", sub: "" };
}

export const AdminDeleteUserRequest = {
  encode(message: AdminDeleteUserRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.email !== "") {
      writer.uint32(10).string(message.email);
    }
    if (message.sub !== "") {
      writer.uint32(18).string(message.sub);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): AdminDeleteUserRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseAdminDeleteUserRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.email = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.sub = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): AdminDeleteUserRequest {
    return { email: isSet(object.email) ? String(object.email) : "", sub: isSet(object.sub) ? String(object.sub) : "" };
  },

  toJSON(message: AdminDeleteUserRequest): unknown {
    const obj: any = {};
    if (message.email !== "") {
      obj.email = message.email;
    }
    if (message.sub !== "") {
      obj.sub = message.sub;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<AdminDeleteUserRequest>, I>>(base?: I): AdminDeleteUserRequest {
    return AdminDeleteUserRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<AdminDeleteUserRequest>, I>>(object: I): AdminDeleteUserRequest {
    const message = createBaseAdminDeleteUserRequest();
    message.email = object.email ?? "";
    message.sub = object.sub ?? "";
    return message;
  },
};

function createBaseFetchAnonymizedVotePreferencesBySchemeRequest(): FetchAnonymizedVotePreferencesBySchemeRequest {
  return { schemeNames: [] };
}

export const FetchAnonymizedVotePreferencesBySchemeRequest = {
  encode(message: FetchAnonymizedVotePreferencesBySchemeRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.schemeNames) {
      writer.uint32(10).string(v!);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): FetchAnonymizedVotePreferencesBySchemeRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseFetchAnonymizedVotePreferencesBySchemeRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.schemeNames.push(reader.string());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): FetchAnonymizedVotePreferencesBySchemeRequest {
    return {
      schemeNames: globalThis.Array.isArray(object?.schemeNames) ? object.schemeNames.map((e: any) => String(e)) : [],
    };
  },

  toJSON(message: FetchAnonymizedVotePreferencesBySchemeRequest): unknown {
    const obj: any = {};
    if (message.schemeNames?.length) {
      obj.schemeNames = message.schemeNames;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<FetchAnonymizedVotePreferencesBySchemeRequest>, I>>(
    base?: I,
  ): FetchAnonymizedVotePreferencesBySchemeRequest {
    return FetchAnonymizedVotePreferencesBySchemeRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<FetchAnonymizedVotePreferencesBySchemeRequest>, I>>(
    object: I,
  ): FetchAnonymizedVotePreferencesBySchemeRequest {
    const message = createBaseFetchAnonymizedVotePreferencesBySchemeRequest();
    message.schemeNames = object.schemeNames?.map((e) => e) || [];
    return message;
  },
};

function createBaseFetchAnonymizedVotePreferencesBySchemeResponse(): FetchAnonymizedVotePreferencesBySchemeResponse {
  return {
    readTime: undefined,
    summary: [],
    votePreferencesByScheme: {},
    polls: {},
    proposals: {},
    generalMeetings: {},
    organizations: {},
  };
}

export const FetchAnonymizedVotePreferencesBySchemeResponse = {
  encode(
    message: FetchAnonymizedVotePreferencesBySchemeResponse,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.readTime !== undefined) {
      Timestamp.encode(toTimestamp(message.readTime), writer.uint32(10).fork()).ldelim();
    }
    for (const v of message.summary) {
      PollVotePreferences.encode(v!, writer.uint32(18).fork()).ldelim();
    }
    Object.entries(message.votePreferencesByScheme).forEach(([key, value]) => {
      FetchAnonymizedVotePreferencesBySchemeResponse_VotePreferencesBySchemeEntry.encode(
        { key: key as any, value },
        writer.uint32(26).fork(),
      ).ldelim();
    });
    Object.entries(message.polls).forEach(([key, value]) => {
      FetchAnonymizedVotePreferencesBySchemeResponse_PollsEntry.encode(
        { key: key as any, value },
        writer.uint32(34).fork(),
      ).ldelim();
    });
    Object.entries(message.proposals).forEach(([key, value]) => {
      FetchAnonymizedVotePreferencesBySchemeResponse_ProposalsEntry.encode(
        { key: key as any, value },
        writer.uint32(42).fork(),
      ).ldelim();
    });
    Object.entries(message.generalMeetings).forEach(([key, value]) => {
      FetchAnonymizedVotePreferencesBySchemeResponse_GeneralMeetingsEntry.encode(
        { key: key as any, value },
        writer.uint32(50).fork(),
      ).ldelim();
    });
    Object.entries(message.organizations).forEach(([key, value]) => {
      FetchAnonymizedVotePreferencesBySchemeResponse_OrganizationsEntry.encode(
        { key: key as any, value },
        writer.uint32(58).fork(),
      ).ldelim();
    });
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): FetchAnonymizedVotePreferencesBySchemeResponse {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseFetchAnonymizedVotePreferencesBySchemeResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.readTime = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.summary.push(PollVotePreferences.decode(reader, reader.uint32()));
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          const entry3 = FetchAnonymizedVotePreferencesBySchemeResponse_VotePreferencesBySchemeEntry.decode(
            reader,
            reader.uint32(),
          );
          if (entry3.value !== undefined) {
            message.votePreferencesByScheme[entry3.key] = entry3.value;
          }
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          const entry4 = FetchAnonymizedVotePreferencesBySchemeResponse_PollsEntry.decode(reader, reader.uint32());
          if (entry4.value !== undefined) {
            message.polls[entry4.key] = entry4.value;
          }
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          const entry5 = FetchAnonymizedVotePreferencesBySchemeResponse_ProposalsEntry.decode(reader, reader.uint32());
          if (entry5.value !== undefined) {
            message.proposals[entry5.key] = entry5.value;
          }
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          const entry6 = FetchAnonymizedVotePreferencesBySchemeResponse_GeneralMeetingsEntry.decode(
            reader,
            reader.uint32(),
          );
          if (entry6.value !== undefined) {
            message.generalMeetings[entry6.key] = entry6.value;
          }
          continue;
        case 7:
          if (tag !== 58) {
            break;
          }

          const entry7 = FetchAnonymizedVotePreferencesBySchemeResponse_OrganizationsEntry.decode(
            reader,
            reader.uint32(),
          );
          if (entry7.value !== undefined) {
            message.organizations[entry7.key] = entry7.value;
          }
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): FetchAnonymizedVotePreferencesBySchemeResponse {
    return {
      readTime: isSet(object.readTime) ? fromJsonTimestamp(object.readTime) : undefined,
      summary: globalThis.Array.isArray(object?.summary)
        ? object.summary.map((e: any) => PollVotePreferences.fromJSON(e))
        : [],
      votePreferencesByScheme: isObject(object.votePreferencesByScheme)
        ? Object.entries(object.votePreferencesByScheme).reduce<{ [key: string]: GroupedVotePreferences }>(
          (acc, [key, value]) => {
            acc[key] = GroupedVotePreferences.fromJSON(value);
            return acc;
          },
          {},
        )
        : {},
      polls: isObject(object.polls)
        ? Object.entries(object.polls).reduce<{ [key: string]: Poll }>((acc, [key, value]) => {
          acc[key] = Poll.fromJSON(value);
          return acc;
        }, {})
        : {},
      proposals: isObject(object.proposals)
        ? Object.entries(object.proposals).reduce<{ [key: string]: Proposal }>((acc, [key, value]) => {
          acc[key] = Proposal.fromJSON(value);
          return acc;
        }, {})
        : {},
      generalMeetings: isObject(object.generalMeetings)
        ? Object.entries(object.generalMeetings).reduce<{ [key: string]: GeneralMeeting }>((acc, [key, value]) => {
          acc[key] = GeneralMeeting.fromJSON(value);
          return acc;
        }, {})
        : {},
      organizations: isObject(object.organizations)
        ? Object.entries(object.organizations).reduce<{ [key: string]: Organization }>((acc, [key, value]) => {
          acc[key] = Organization.fromJSON(value);
          return acc;
        }, {})
        : {},
    };
  },

  toJSON(message: FetchAnonymizedVotePreferencesBySchemeResponse): unknown {
    const obj: any = {};
    if (message.readTime !== undefined) {
      obj.readTime = message.readTime.toISOString();
    }
    if (message.summary?.length) {
      obj.summary = message.summary.map((e) => PollVotePreferences.toJSON(e));
    }
    if (message.votePreferencesByScheme) {
      const entries = Object.entries(message.votePreferencesByScheme);
      if (entries.length > 0) {
        obj.votePreferencesByScheme = {};
        entries.forEach(([k, v]) => {
          obj.votePreferencesByScheme[k] = GroupedVotePreferences.toJSON(v);
        });
      }
    }
    if (message.polls) {
      const entries = Object.entries(message.polls);
      if (entries.length > 0) {
        obj.polls = {};
        entries.forEach(([k, v]) => {
          obj.polls[k] = Poll.toJSON(v);
        });
      }
    }
    if (message.proposals) {
      const entries = Object.entries(message.proposals);
      if (entries.length > 0) {
        obj.proposals = {};
        entries.forEach(([k, v]) => {
          obj.proposals[k] = Proposal.toJSON(v);
        });
      }
    }
    if (message.generalMeetings) {
      const entries = Object.entries(message.generalMeetings);
      if (entries.length > 0) {
        obj.generalMeetings = {};
        entries.forEach(([k, v]) => {
          obj.generalMeetings[k] = GeneralMeeting.toJSON(v);
        });
      }
    }
    if (message.organizations) {
      const entries = Object.entries(message.organizations);
      if (entries.length > 0) {
        obj.organizations = {};
        entries.forEach(([k, v]) => {
          obj.organizations[k] = Organization.toJSON(v);
        });
      }
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<FetchAnonymizedVotePreferencesBySchemeResponse>, I>>(
    base?: I,
  ): FetchAnonymizedVotePreferencesBySchemeResponse {
    return FetchAnonymizedVotePreferencesBySchemeResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<FetchAnonymizedVotePreferencesBySchemeResponse>, I>>(
    object: I,
  ): FetchAnonymizedVotePreferencesBySchemeResponse {
    const message = createBaseFetchAnonymizedVotePreferencesBySchemeResponse();
    message.readTime = object.readTime ?? undefined;
    message.summary = object.summary?.map((e) => PollVotePreferences.fromPartial(e)) || [];
    message.votePreferencesByScheme = Object.entries(object.votePreferencesByScheme ?? {}).reduce<
      { [key: string]: GroupedVotePreferences }
    >((acc, [key, value]) => {
      if (value !== undefined) {
        acc[key] = GroupedVotePreferences.fromPartial(value);
      }
      return acc;
    }, {});
    message.polls = Object.entries(object.polls ?? {}).reduce<{ [key: string]: Poll }>((acc, [key, value]) => {
      if (value !== undefined) {
        acc[key] = Poll.fromPartial(value);
      }
      return acc;
    }, {});
    message.proposals = Object.entries(object.proposals ?? {}).reduce<{ [key: string]: Proposal }>(
      (acc, [key, value]) => {
        if (value !== undefined) {
          acc[key] = Proposal.fromPartial(value);
        }
        return acc;
      },
      {},
    );
    message.generalMeetings = Object.entries(object.generalMeetings ?? {}).reduce<{ [key: string]: GeneralMeeting }>(
      (acc, [key, value]) => {
        if (value !== undefined) {
          acc[key] = GeneralMeeting.fromPartial(value);
        }
        return acc;
      },
      {},
    );
    message.organizations = Object.entries(object.organizations ?? {}).reduce<{ [key: string]: Organization }>(
      (acc, [key, value]) => {
        if (value !== undefined) {
          acc[key] = Organization.fromPartial(value);
        }
        return acc;
      },
      {},
    );
    return message;
  },
};

function createBaseFetchAnonymizedVotePreferencesBySchemeResponse_VotePreferencesBySchemeEntry(): FetchAnonymizedVotePreferencesBySchemeResponse_VotePreferencesBySchemeEntry {
  return { key: "", value: undefined };
}

export const FetchAnonymizedVotePreferencesBySchemeResponse_VotePreferencesBySchemeEntry = {
  encode(
    message: FetchAnonymizedVotePreferencesBySchemeResponse_VotePreferencesBySchemeEntry,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.key !== "") {
      writer.uint32(10).string(message.key);
    }
    if (message.value !== undefined) {
      GroupedVotePreferences.encode(message.value, writer.uint32(18).fork()).ldelim();
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number,
  ): FetchAnonymizedVotePreferencesBySchemeResponse_VotePreferencesBySchemeEntry {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseFetchAnonymizedVotePreferencesBySchemeResponse_VotePreferencesBySchemeEntry();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.key = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.value = GroupedVotePreferences.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): FetchAnonymizedVotePreferencesBySchemeResponse_VotePreferencesBySchemeEntry {
    return {
      key: isSet(object.key) ? String(object.key) : "",
      value: isSet(object.value) ? GroupedVotePreferences.fromJSON(object.value) : undefined,
    };
  },

  toJSON(message: FetchAnonymizedVotePreferencesBySchemeResponse_VotePreferencesBySchemeEntry): unknown {
    const obj: any = {};
    if (message.key !== "") {
      obj.key = message.key;
    }
    if (message.value !== undefined) {
      obj.value = GroupedVotePreferences.toJSON(message.value);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<FetchAnonymizedVotePreferencesBySchemeResponse_VotePreferencesBySchemeEntry>, I>>(
    base?: I,
  ): FetchAnonymizedVotePreferencesBySchemeResponse_VotePreferencesBySchemeEntry {
    return FetchAnonymizedVotePreferencesBySchemeResponse_VotePreferencesBySchemeEntry.fromPartial(base ?? ({} as any));
  },
  fromPartial<
    I extends Exact<DeepPartial<FetchAnonymizedVotePreferencesBySchemeResponse_VotePreferencesBySchemeEntry>, I>,
  >(object: I): FetchAnonymizedVotePreferencesBySchemeResponse_VotePreferencesBySchemeEntry {
    const message = createBaseFetchAnonymizedVotePreferencesBySchemeResponse_VotePreferencesBySchemeEntry();
    message.key = object.key ?? "";
    message.value = (object.value !== undefined && object.value !== null)
      ? GroupedVotePreferences.fromPartial(object.value)
      : undefined;
    return message;
  },
};

function createBaseFetchAnonymizedVotePreferencesBySchemeResponse_PollsEntry(): FetchAnonymizedVotePreferencesBySchemeResponse_PollsEntry {
  return { key: "", value: undefined };
}

export const FetchAnonymizedVotePreferencesBySchemeResponse_PollsEntry = {
  encode(
    message: FetchAnonymizedVotePreferencesBySchemeResponse_PollsEntry,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.key !== "") {
      writer.uint32(10).string(message.key);
    }
    if (message.value !== undefined) {
      Poll.encode(message.value, writer.uint32(18).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): FetchAnonymizedVotePreferencesBySchemeResponse_PollsEntry {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseFetchAnonymizedVotePreferencesBySchemeResponse_PollsEntry();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.key = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.value = Poll.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): FetchAnonymizedVotePreferencesBySchemeResponse_PollsEntry {
    return {
      key: isSet(object.key) ? String(object.key) : "",
      value: isSet(object.value) ? Poll.fromJSON(object.value) : undefined,
    };
  },

  toJSON(message: FetchAnonymizedVotePreferencesBySchemeResponse_PollsEntry): unknown {
    const obj: any = {};
    if (message.key !== "") {
      obj.key = message.key;
    }
    if (message.value !== undefined) {
      obj.value = Poll.toJSON(message.value);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<FetchAnonymizedVotePreferencesBySchemeResponse_PollsEntry>, I>>(
    base?: I,
  ): FetchAnonymizedVotePreferencesBySchemeResponse_PollsEntry {
    return FetchAnonymizedVotePreferencesBySchemeResponse_PollsEntry.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<FetchAnonymizedVotePreferencesBySchemeResponse_PollsEntry>, I>>(
    object: I,
  ): FetchAnonymizedVotePreferencesBySchemeResponse_PollsEntry {
    const message = createBaseFetchAnonymizedVotePreferencesBySchemeResponse_PollsEntry();
    message.key = object.key ?? "";
    message.value = (object.value !== undefined && object.value !== null) ? Poll.fromPartial(object.value) : undefined;
    return message;
  },
};

function createBaseFetchAnonymizedVotePreferencesBySchemeResponse_ProposalsEntry(): FetchAnonymizedVotePreferencesBySchemeResponse_ProposalsEntry {
  return { key: "", value: undefined };
}

export const FetchAnonymizedVotePreferencesBySchemeResponse_ProposalsEntry = {
  encode(
    message: FetchAnonymizedVotePreferencesBySchemeResponse_ProposalsEntry,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.key !== "") {
      writer.uint32(10).string(message.key);
    }
    if (message.value !== undefined) {
      Proposal.encode(message.value, writer.uint32(18).fork()).ldelim();
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number,
  ): FetchAnonymizedVotePreferencesBySchemeResponse_ProposalsEntry {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseFetchAnonymizedVotePreferencesBySchemeResponse_ProposalsEntry();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.key = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.value = Proposal.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): FetchAnonymizedVotePreferencesBySchemeResponse_ProposalsEntry {
    return {
      key: isSet(object.key) ? String(object.key) : "",
      value: isSet(object.value) ? Proposal.fromJSON(object.value) : undefined,
    };
  },

  toJSON(message: FetchAnonymizedVotePreferencesBySchemeResponse_ProposalsEntry): unknown {
    const obj: any = {};
    if (message.key !== "") {
      obj.key = message.key;
    }
    if (message.value !== undefined) {
      obj.value = Proposal.toJSON(message.value);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<FetchAnonymizedVotePreferencesBySchemeResponse_ProposalsEntry>, I>>(
    base?: I,
  ): FetchAnonymizedVotePreferencesBySchemeResponse_ProposalsEntry {
    return FetchAnonymizedVotePreferencesBySchemeResponse_ProposalsEntry.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<FetchAnonymizedVotePreferencesBySchemeResponse_ProposalsEntry>, I>>(
    object: I,
  ): FetchAnonymizedVotePreferencesBySchemeResponse_ProposalsEntry {
    const message = createBaseFetchAnonymizedVotePreferencesBySchemeResponse_ProposalsEntry();
    message.key = object.key ?? "";
    message.value = (object.value !== undefined && object.value !== null)
      ? Proposal.fromPartial(object.value)
      : undefined;
    return message;
  },
};

function createBaseFetchAnonymizedVotePreferencesBySchemeResponse_GeneralMeetingsEntry(): FetchAnonymizedVotePreferencesBySchemeResponse_GeneralMeetingsEntry {
  return { key: "", value: undefined };
}

export const FetchAnonymizedVotePreferencesBySchemeResponse_GeneralMeetingsEntry = {
  encode(
    message: FetchAnonymizedVotePreferencesBySchemeResponse_GeneralMeetingsEntry,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.key !== "") {
      writer.uint32(10).string(message.key);
    }
    if (message.value !== undefined) {
      GeneralMeeting.encode(message.value, writer.uint32(18).fork()).ldelim();
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number,
  ): FetchAnonymizedVotePreferencesBySchemeResponse_GeneralMeetingsEntry {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseFetchAnonymizedVotePreferencesBySchemeResponse_GeneralMeetingsEntry();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.key = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.value = GeneralMeeting.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): FetchAnonymizedVotePreferencesBySchemeResponse_GeneralMeetingsEntry {
    return {
      key: isSet(object.key) ? String(object.key) : "",
      value: isSet(object.value) ? GeneralMeeting.fromJSON(object.value) : undefined,
    };
  },

  toJSON(message: FetchAnonymizedVotePreferencesBySchemeResponse_GeneralMeetingsEntry): unknown {
    const obj: any = {};
    if (message.key !== "") {
      obj.key = message.key;
    }
    if (message.value !== undefined) {
      obj.value = GeneralMeeting.toJSON(message.value);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<FetchAnonymizedVotePreferencesBySchemeResponse_GeneralMeetingsEntry>, I>>(
    base?: I,
  ): FetchAnonymizedVotePreferencesBySchemeResponse_GeneralMeetingsEntry {
    return FetchAnonymizedVotePreferencesBySchemeResponse_GeneralMeetingsEntry.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<FetchAnonymizedVotePreferencesBySchemeResponse_GeneralMeetingsEntry>, I>>(
    object: I,
  ): FetchAnonymizedVotePreferencesBySchemeResponse_GeneralMeetingsEntry {
    const message = createBaseFetchAnonymizedVotePreferencesBySchemeResponse_GeneralMeetingsEntry();
    message.key = object.key ?? "";
    message.value = (object.value !== undefined && object.value !== null)
      ? GeneralMeeting.fromPartial(object.value)
      : undefined;
    return message;
  },
};

function createBaseFetchAnonymizedVotePreferencesBySchemeResponse_OrganizationsEntry(): FetchAnonymizedVotePreferencesBySchemeResponse_OrganizationsEntry {
  return { key: "", value: undefined };
}

export const FetchAnonymizedVotePreferencesBySchemeResponse_OrganizationsEntry = {
  encode(
    message: FetchAnonymizedVotePreferencesBySchemeResponse_OrganizationsEntry,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.key !== "") {
      writer.uint32(10).string(message.key);
    }
    if (message.value !== undefined) {
      Organization.encode(message.value, writer.uint32(18).fork()).ldelim();
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number,
  ): FetchAnonymizedVotePreferencesBySchemeResponse_OrganizationsEntry {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseFetchAnonymizedVotePreferencesBySchemeResponse_OrganizationsEntry();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.key = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.value = Organization.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): FetchAnonymizedVotePreferencesBySchemeResponse_OrganizationsEntry {
    return {
      key: isSet(object.key) ? String(object.key) : "",
      value: isSet(object.value) ? Organization.fromJSON(object.value) : undefined,
    };
  },

  toJSON(message: FetchAnonymizedVotePreferencesBySchemeResponse_OrganizationsEntry): unknown {
    const obj: any = {};
    if (message.key !== "") {
      obj.key = message.key;
    }
    if (message.value !== undefined) {
      obj.value = Organization.toJSON(message.value);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<FetchAnonymizedVotePreferencesBySchemeResponse_OrganizationsEntry>, I>>(
    base?: I,
  ): FetchAnonymizedVotePreferencesBySchemeResponse_OrganizationsEntry {
    return FetchAnonymizedVotePreferencesBySchemeResponse_OrganizationsEntry.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<FetchAnonymizedVotePreferencesBySchemeResponse_OrganizationsEntry>, I>>(
    object: I,
  ): FetchAnonymizedVotePreferencesBySchemeResponse_OrganizationsEntry {
    const message = createBaseFetchAnonymizedVotePreferencesBySchemeResponse_OrganizationsEntry();
    message.key = object.key ?? "";
    message.value = (object.value !== undefined && object.value !== null)
      ? Organization.fromPartial(object.value)
      : undefined;
    return message;
  },
};

function createBaseFetchAnonymizedVoteCommentsBySchemeRequest(): FetchAnonymizedVoteCommentsBySchemeRequest {
  return { schemeNames: [] };
}

export const FetchAnonymizedVoteCommentsBySchemeRequest = {
  encode(message: FetchAnonymizedVoteCommentsBySchemeRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.schemeNames) {
      writer.uint32(10).string(v!);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): FetchAnonymizedVoteCommentsBySchemeRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseFetchAnonymizedVoteCommentsBySchemeRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.schemeNames.push(reader.string());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): FetchAnonymizedVoteCommentsBySchemeRequest {
    return {
      schemeNames: globalThis.Array.isArray(object?.schemeNames) ? object.schemeNames.map((e: any) => String(e)) : [],
    };
  },

  toJSON(message: FetchAnonymizedVoteCommentsBySchemeRequest): unknown {
    const obj: any = {};
    if (message.schemeNames?.length) {
      obj.schemeNames = message.schemeNames;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<FetchAnonymizedVoteCommentsBySchemeRequest>, I>>(
    base?: I,
  ): FetchAnonymizedVoteCommentsBySchemeRequest {
    return FetchAnonymizedVoteCommentsBySchemeRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<FetchAnonymizedVoteCommentsBySchemeRequest>, I>>(
    object: I,
  ): FetchAnonymizedVoteCommentsBySchemeRequest {
    const message = createBaseFetchAnonymizedVoteCommentsBySchemeRequest();
    message.schemeNames = object.schemeNames?.map((e) => e) || [];
    return message;
  },
};

function createBaseFetchAnonymizedVoteCommentsBySchemeResponse(): FetchAnonymizedVoteCommentsBySchemeResponse {
  return {
    readTime: undefined,
    summary: [],
    voteCommentsByScheme: {},
    polls: {},
    proposals: {},
    generalMeetings: {},
    organizations: {},
  };
}

export const FetchAnonymizedVoteCommentsBySchemeResponse = {
  encode(message: FetchAnonymizedVoteCommentsBySchemeResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.readTime !== undefined) {
      Timestamp.encode(toTimestamp(message.readTime), writer.uint32(10).fork()).ldelim();
    }
    for (const v of message.summary) {
      PollVoteComments.encode(v!, writer.uint32(18).fork()).ldelim();
    }
    Object.entries(message.voteCommentsByScheme).forEach(([key, value]) => {
      FetchAnonymizedVoteCommentsBySchemeResponse_VoteCommentsBySchemeEntry.encode(
        { key: key as any, value },
        writer.uint32(26).fork(),
      ).ldelim();
    });
    Object.entries(message.polls).forEach(([key, value]) => {
      FetchAnonymizedVoteCommentsBySchemeResponse_PollsEntry.encode(
        { key: key as any, value },
        writer.uint32(34).fork(),
      ).ldelim();
    });
    Object.entries(message.proposals).forEach(([key, value]) => {
      FetchAnonymizedVoteCommentsBySchemeResponse_ProposalsEntry.encode(
        { key: key as any, value },
        writer.uint32(42).fork(),
      ).ldelim();
    });
    Object.entries(message.generalMeetings).forEach(([key, value]) => {
      FetchAnonymizedVoteCommentsBySchemeResponse_GeneralMeetingsEntry.encode(
        { key: key as any, value },
        writer.uint32(50).fork(),
      ).ldelim();
    });
    Object.entries(message.organizations).forEach(([key, value]) => {
      FetchAnonymizedVoteCommentsBySchemeResponse_OrganizationsEntry.encode(
        { key: key as any, value },
        writer.uint32(58).fork(),
      ).ldelim();
    });
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): FetchAnonymizedVoteCommentsBySchemeResponse {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseFetchAnonymizedVoteCommentsBySchemeResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.readTime = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.summary.push(PollVoteComments.decode(reader, reader.uint32()));
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          const entry3 = FetchAnonymizedVoteCommentsBySchemeResponse_VoteCommentsBySchemeEntry.decode(
            reader,
            reader.uint32(),
          );
          if (entry3.value !== undefined) {
            message.voteCommentsByScheme[entry3.key] = entry3.value;
          }
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          const entry4 = FetchAnonymizedVoteCommentsBySchemeResponse_PollsEntry.decode(reader, reader.uint32());
          if (entry4.value !== undefined) {
            message.polls[entry4.key] = entry4.value;
          }
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          const entry5 = FetchAnonymizedVoteCommentsBySchemeResponse_ProposalsEntry.decode(reader, reader.uint32());
          if (entry5.value !== undefined) {
            message.proposals[entry5.key] = entry5.value;
          }
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          const entry6 = FetchAnonymizedVoteCommentsBySchemeResponse_GeneralMeetingsEntry.decode(
            reader,
            reader.uint32(),
          );
          if (entry6.value !== undefined) {
            message.generalMeetings[entry6.key] = entry6.value;
          }
          continue;
        case 7:
          if (tag !== 58) {
            break;
          }

          const entry7 = FetchAnonymizedVoteCommentsBySchemeResponse_OrganizationsEntry.decode(reader, reader.uint32());
          if (entry7.value !== undefined) {
            message.organizations[entry7.key] = entry7.value;
          }
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): FetchAnonymizedVoteCommentsBySchemeResponse {
    return {
      readTime: isSet(object.readTime) ? fromJsonTimestamp(object.readTime) : undefined,
      summary: globalThis.Array.isArray(object?.summary)
        ? object.summary.map((e: any) => PollVoteComments.fromJSON(e))
        : [],
      voteCommentsByScheme: isObject(object.voteCommentsByScheme)
        ? Object.entries(object.voteCommentsByScheme).reduce<{ [key: string]: GroupedVoteComments }>(
          (acc, [key, value]) => {
            acc[key] = GroupedVoteComments.fromJSON(value);
            return acc;
          },
          {},
        )
        : {},
      polls: isObject(object.polls)
        ? Object.entries(object.polls).reduce<{ [key: string]: Poll }>((acc, [key, value]) => {
          acc[key] = Poll.fromJSON(value);
          return acc;
        }, {})
        : {},
      proposals: isObject(object.proposals)
        ? Object.entries(object.proposals).reduce<{ [key: string]: Proposal }>((acc, [key, value]) => {
          acc[key] = Proposal.fromJSON(value);
          return acc;
        }, {})
        : {},
      generalMeetings: isObject(object.generalMeetings)
        ? Object.entries(object.generalMeetings).reduce<{ [key: string]: GeneralMeeting }>((acc, [key, value]) => {
          acc[key] = GeneralMeeting.fromJSON(value);
          return acc;
        }, {})
        : {},
      organizations: isObject(object.organizations)
        ? Object.entries(object.organizations).reduce<{ [key: string]: Organization }>((acc, [key, value]) => {
          acc[key] = Organization.fromJSON(value);
          return acc;
        }, {})
        : {},
    };
  },

  toJSON(message: FetchAnonymizedVoteCommentsBySchemeResponse): unknown {
    const obj: any = {};
    if (message.readTime !== undefined) {
      obj.readTime = message.readTime.toISOString();
    }
    if (message.summary?.length) {
      obj.summary = message.summary.map((e) => PollVoteComments.toJSON(e));
    }
    if (message.voteCommentsByScheme) {
      const entries = Object.entries(message.voteCommentsByScheme);
      if (entries.length > 0) {
        obj.voteCommentsByScheme = {};
        entries.forEach(([k, v]) => {
          obj.voteCommentsByScheme[k] = GroupedVoteComments.toJSON(v);
        });
      }
    }
    if (message.polls) {
      const entries = Object.entries(message.polls);
      if (entries.length > 0) {
        obj.polls = {};
        entries.forEach(([k, v]) => {
          obj.polls[k] = Poll.toJSON(v);
        });
      }
    }
    if (message.proposals) {
      const entries = Object.entries(message.proposals);
      if (entries.length > 0) {
        obj.proposals = {};
        entries.forEach(([k, v]) => {
          obj.proposals[k] = Proposal.toJSON(v);
        });
      }
    }
    if (message.generalMeetings) {
      const entries = Object.entries(message.generalMeetings);
      if (entries.length > 0) {
        obj.generalMeetings = {};
        entries.forEach(([k, v]) => {
          obj.generalMeetings[k] = GeneralMeeting.toJSON(v);
        });
      }
    }
    if (message.organizations) {
      const entries = Object.entries(message.organizations);
      if (entries.length > 0) {
        obj.organizations = {};
        entries.forEach(([k, v]) => {
          obj.organizations[k] = Organization.toJSON(v);
        });
      }
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<FetchAnonymizedVoteCommentsBySchemeResponse>, I>>(
    base?: I,
  ): FetchAnonymizedVoteCommentsBySchemeResponse {
    return FetchAnonymizedVoteCommentsBySchemeResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<FetchAnonymizedVoteCommentsBySchemeResponse>, I>>(
    object: I,
  ): FetchAnonymizedVoteCommentsBySchemeResponse {
    const message = createBaseFetchAnonymizedVoteCommentsBySchemeResponse();
    message.readTime = object.readTime ?? undefined;
    message.summary = object.summary?.map((e) => PollVoteComments.fromPartial(e)) || [];
    message.voteCommentsByScheme = Object.entries(object.voteCommentsByScheme ?? {}).reduce<
      { [key: string]: GroupedVoteComments }
    >((acc, [key, value]) => {
      if (value !== undefined) {
        acc[key] = GroupedVoteComments.fromPartial(value);
      }
      return acc;
    }, {});
    message.polls = Object.entries(object.polls ?? {}).reduce<{ [key: string]: Poll }>((acc, [key, value]) => {
      if (value !== undefined) {
        acc[key] = Poll.fromPartial(value);
      }
      return acc;
    }, {});
    message.proposals = Object.entries(object.proposals ?? {}).reduce<{ [key: string]: Proposal }>(
      (acc, [key, value]) => {
        if (value !== undefined) {
          acc[key] = Proposal.fromPartial(value);
        }
        return acc;
      },
      {},
    );
    message.generalMeetings = Object.entries(object.generalMeetings ?? {}).reduce<{ [key: string]: GeneralMeeting }>(
      (acc, [key, value]) => {
        if (value !== undefined) {
          acc[key] = GeneralMeeting.fromPartial(value);
        }
        return acc;
      },
      {},
    );
    message.organizations = Object.entries(object.organizations ?? {}).reduce<{ [key: string]: Organization }>(
      (acc, [key, value]) => {
        if (value !== undefined) {
          acc[key] = Organization.fromPartial(value);
        }
        return acc;
      },
      {},
    );
    return message;
  },
};

function createBaseFetchAnonymizedVoteCommentsBySchemeResponse_VoteCommentsBySchemeEntry(): FetchAnonymizedVoteCommentsBySchemeResponse_VoteCommentsBySchemeEntry {
  return { key: "", value: undefined };
}

export const FetchAnonymizedVoteCommentsBySchemeResponse_VoteCommentsBySchemeEntry = {
  encode(
    message: FetchAnonymizedVoteCommentsBySchemeResponse_VoteCommentsBySchemeEntry,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.key !== "") {
      writer.uint32(10).string(message.key);
    }
    if (message.value !== undefined) {
      GroupedVoteComments.encode(message.value, writer.uint32(18).fork()).ldelim();
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number,
  ): FetchAnonymizedVoteCommentsBySchemeResponse_VoteCommentsBySchemeEntry {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseFetchAnonymizedVoteCommentsBySchemeResponse_VoteCommentsBySchemeEntry();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.key = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.value = GroupedVoteComments.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): FetchAnonymizedVoteCommentsBySchemeResponse_VoteCommentsBySchemeEntry {
    return {
      key: isSet(object.key) ? String(object.key) : "",
      value: isSet(object.value) ? GroupedVoteComments.fromJSON(object.value) : undefined,
    };
  },

  toJSON(message: FetchAnonymizedVoteCommentsBySchemeResponse_VoteCommentsBySchemeEntry): unknown {
    const obj: any = {};
    if (message.key !== "") {
      obj.key = message.key;
    }
    if (message.value !== undefined) {
      obj.value = GroupedVoteComments.toJSON(message.value);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<FetchAnonymizedVoteCommentsBySchemeResponse_VoteCommentsBySchemeEntry>, I>>(
    base?: I,
  ): FetchAnonymizedVoteCommentsBySchemeResponse_VoteCommentsBySchemeEntry {
    return FetchAnonymizedVoteCommentsBySchemeResponse_VoteCommentsBySchemeEntry.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<FetchAnonymizedVoteCommentsBySchemeResponse_VoteCommentsBySchemeEntry>, I>>(
    object: I,
  ): FetchAnonymizedVoteCommentsBySchemeResponse_VoteCommentsBySchemeEntry {
    const message = createBaseFetchAnonymizedVoteCommentsBySchemeResponse_VoteCommentsBySchemeEntry();
    message.key = object.key ?? "";
    message.value = (object.value !== undefined && object.value !== null)
      ? GroupedVoteComments.fromPartial(object.value)
      : undefined;
    return message;
  },
};

function createBaseFetchAnonymizedVoteCommentsBySchemeResponse_PollsEntry(): FetchAnonymizedVoteCommentsBySchemeResponse_PollsEntry {
  return { key: "", value: undefined };
}

export const FetchAnonymizedVoteCommentsBySchemeResponse_PollsEntry = {
  encode(
    message: FetchAnonymizedVoteCommentsBySchemeResponse_PollsEntry,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.key !== "") {
      writer.uint32(10).string(message.key);
    }
    if (message.value !== undefined) {
      Poll.encode(message.value, writer.uint32(18).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): FetchAnonymizedVoteCommentsBySchemeResponse_PollsEntry {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseFetchAnonymizedVoteCommentsBySchemeResponse_PollsEntry();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.key = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.value = Poll.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): FetchAnonymizedVoteCommentsBySchemeResponse_PollsEntry {
    return {
      key: isSet(object.key) ? String(object.key) : "",
      value: isSet(object.value) ? Poll.fromJSON(object.value) : undefined,
    };
  },

  toJSON(message: FetchAnonymizedVoteCommentsBySchemeResponse_PollsEntry): unknown {
    const obj: any = {};
    if (message.key !== "") {
      obj.key = message.key;
    }
    if (message.value !== undefined) {
      obj.value = Poll.toJSON(message.value);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<FetchAnonymizedVoteCommentsBySchemeResponse_PollsEntry>, I>>(
    base?: I,
  ): FetchAnonymizedVoteCommentsBySchemeResponse_PollsEntry {
    return FetchAnonymizedVoteCommentsBySchemeResponse_PollsEntry.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<FetchAnonymizedVoteCommentsBySchemeResponse_PollsEntry>, I>>(
    object: I,
  ): FetchAnonymizedVoteCommentsBySchemeResponse_PollsEntry {
    const message = createBaseFetchAnonymizedVoteCommentsBySchemeResponse_PollsEntry();
    message.key = object.key ?? "";
    message.value = (object.value !== undefined && object.value !== null) ? Poll.fromPartial(object.value) : undefined;
    return message;
  },
};

function createBaseFetchAnonymizedVoteCommentsBySchemeResponse_ProposalsEntry(): FetchAnonymizedVoteCommentsBySchemeResponse_ProposalsEntry {
  return { key: "", value: undefined };
}

export const FetchAnonymizedVoteCommentsBySchemeResponse_ProposalsEntry = {
  encode(
    message: FetchAnonymizedVoteCommentsBySchemeResponse_ProposalsEntry,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.key !== "") {
      writer.uint32(10).string(message.key);
    }
    if (message.value !== undefined) {
      Proposal.encode(message.value, writer.uint32(18).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): FetchAnonymizedVoteCommentsBySchemeResponse_ProposalsEntry {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseFetchAnonymizedVoteCommentsBySchemeResponse_ProposalsEntry();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.key = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.value = Proposal.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): FetchAnonymizedVoteCommentsBySchemeResponse_ProposalsEntry {
    return {
      key: isSet(object.key) ? String(object.key) : "",
      value: isSet(object.value) ? Proposal.fromJSON(object.value) : undefined,
    };
  },

  toJSON(message: FetchAnonymizedVoteCommentsBySchemeResponse_ProposalsEntry): unknown {
    const obj: any = {};
    if (message.key !== "") {
      obj.key = message.key;
    }
    if (message.value !== undefined) {
      obj.value = Proposal.toJSON(message.value);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<FetchAnonymizedVoteCommentsBySchemeResponse_ProposalsEntry>, I>>(
    base?: I,
  ): FetchAnonymizedVoteCommentsBySchemeResponse_ProposalsEntry {
    return FetchAnonymizedVoteCommentsBySchemeResponse_ProposalsEntry.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<FetchAnonymizedVoteCommentsBySchemeResponse_ProposalsEntry>, I>>(
    object: I,
  ): FetchAnonymizedVoteCommentsBySchemeResponse_ProposalsEntry {
    const message = createBaseFetchAnonymizedVoteCommentsBySchemeResponse_ProposalsEntry();
    message.key = object.key ?? "";
    message.value = (object.value !== undefined && object.value !== null)
      ? Proposal.fromPartial(object.value)
      : undefined;
    return message;
  },
};

function createBaseFetchAnonymizedVoteCommentsBySchemeResponse_GeneralMeetingsEntry(): FetchAnonymizedVoteCommentsBySchemeResponse_GeneralMeetingsEntry {
  return { key: "", value: undefined };
}

export const FetchAnonymizedVoteCommentsBySchemeResponse_GeneralMeetingsEntry = {
  encode(
    message: FetchAnonymizedVoteCommentsBySchemeResponse_GeneralMeetingsEntry,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.key !== "") {
      writer.uint32(10).string(message.key);
    }
    if (message.value !== undefined) {
      GeneralMeeting.encode(message.value, writer.uint32(18).fork()).ldelim();
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number,
  ): FetchAnonymizedVoteCommentsBySchemeResponse_GeneralMeetingsEntry {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseFetchAnonymizedVoteCommentsBySchemeResponse_GeneralMeetingsEntry();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.key = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.value = GeneralMeeting.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): FetchAnonymizedVoteCommentsBySchemeResponse_GeneralMeetingsEntry {
    return {
      key: isSet(object.key) ? String(object.key) : "",
      value: isSet(object.value) ? GeneralMeeting.fromJSON(object.value) : undefined,
    };
  },

  toJSON(message: FetchAnonymizedVoteCommentsBySchemeResponse_GeneralMeetingsEntry): unknown {
    const obj: any = {};
    if (message.key !== "") {
      obj.key = message.key;
    }
    if (message.value !== undefined) {
      obj.value = GeneralMeeting.toJSON(message.value);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<FetchAnonymizedVoteCommentsBySchemeResponse_GeneralMeetingsEntry>, I>>(
    base?: I,
  ): FetchAnonymizedVoteCommentsBySchemeResponse_GeneralMeetingsEntry {
    return FetchAnonymizedVoteCommentsBySchemeResponse_GeneralMeetingsEntry.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<FetchAnonymizedVoteCommentsBySchemeResponse_GeneralMeetingsEntry>, I>>(
    object: I,
  ): FetchAnonymizedVoteCommentsBySchemeResponse_GeneralMeetingsEntry {
    const message = createBaseFetchAnonymizedVoteCommentsBySchemeResponse_GeneralMeetingsEntry();
    message.key = object.key ?? "";
    message.value = (object.value !== undefined && object.value !== null)
      ? GeneralMeeting.fromPartial(object.value)
      : undefined;
    return message;
  },
};

function createBaseFetchAnonymizedVoteCommentsBySchemeResponse_OrganizationsEntry(): FetchAnonymizedVoteCommentsBySchemeResponse_OrganizationsEntry {
  return { key: "", value: undefined };
}

export const FetchAnonymizedVoteCommentsBySchemeResponse_OrganizationsEntry = {
  encode(
    message: FetchAnonymizedVoteCommentsBySchemeResponse_OrganizationsEntry,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.key !== "") {
      writer.uint32(10).string(message.key);
    }
    if (message.value !== undefined) {
      Organization.encode(message.value, writer.uint32(18).fork()).ldelim();
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number,
  ): FetchAnonymizedVoteCommentsBySchemeResponse_OrganizationsEntry {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseFetchAnonymizedVoteCommentsBySchemeResponse_OrganizationsEntry();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.key = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.value = Organization.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): FetchAnonymizedVoteCommentsBySchemeResponse_OrganizationsEntry {
    return {
      key: isSet(object.key) ? String(object.key) : "",
      value: isSet(object.value) ? Organization.fromJSON(object.value) : undefined,
    };
  },

  toJSON(message: FetchAnonymizedVoteCommentsBySchemeResponse_OrganizationsEntry): unknown {
    const obj: any = {};
    if (message.key !== "") {
      obj.key = message.key;
    }
    if (message.value !== undefined) {
      obj.value = Organization.toJSON(message.value);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<FetchAnonymizedVoteCommentsBySchemeResponse_OrganizationsEntry>, I>>(
    base?: I,
  ): FetchAnonymizedVoteCommentsBySchemeResponse_OrganizationsEntry {
    return FetchAnonymizedVoteCommentsBySchemeResponse_OrganizationsEntry.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<FetchAnonymizedVoteCommentsBySchemeResponse_OrganizationsEntry>, I>>(
    object: I,
  ): FetchAnonymizedVoteCommentsBySchemeResponse_OrganizationsEntry {
    const message = createBaseFetchAnonymizedVoteCommentsBySchemeResponse_OrganizationsEntry();
    message.key = object.key ?? "";
    message.value = (object.value !== undefined && object.value !== null)
      ? Organization.fromPartial(object.value)
      : undefined;
    return message;
  },
};

function createBaseFetchPrivilegesRequest(): FetchPrivilegesRequest {
  return {};
}

export const FetchPrivilegesRequest = {
  encode(_: FetchPrivilegesRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): FetchPrivilegesRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseFetchPrivilegesRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(_: any): FetchPrivilegesRequest {
    return {};
  },

  toJSON(_: FetchPrivilegesRequest): unknown {
    const obj: any = {};
    return obj;
  },

  create<I extends Exact<DeepPartial<FetchPrivilegesRequest>, I>>(base?: I): FetchPrivilegesRequest {
    return FetchPrivilegesRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<FetchPrivilegesRequest>, I>>(_: I): FetchPrivilegesRequest {
    const message = createBaseFetchPrivilegesRequest();
    return message;
  },
};

function createBaseGetUserRequest(): GetUserRequest {
  return {};
}

export const GetUserRequest = {
  encode(_: GetUserRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GetUserRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetUserRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(_: any): GetUserRequest {
    return {};
  },

  toJSON(_: GetUserRequest): unknown {
    const obj: any = {};
    return obj;
  },

  create<I extends Exact<DeepPartial<GetUserRequest>, I>>(base?: I): GetUserRequest {
    return GetUserRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<GetUserRequest>, I>>(_: I): GetUserRequest {
    const message = createBaseGetUserRequest();
    return message;
  },
};

function createBaseListSchemesRequest(): ListSchemesRequest {
  return { pageToken: "", pageSize: 0, names: [] };
}

export const ListSchemesRequest = {
  encode(message: ListSchemesRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.pageToken !== "") {
      writer.uint32(10).string(message.pageToken);
    }
    if (message.pageSize !== 0) {
      writer.uint32(16).int32(message.pageSize);
    }
    for (const v of message.names) {
      writer.uint32(26).string(v!);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ListSchemesRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseListSchemesRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.pageToken = reader.string();
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.pageSize = reader.int32();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.names.push(reader.string());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ListSchemesRequest {
    return {
      pageToken: isSet(object.pageToken) ? String(object.pageToken) : "",
      pageSize: isSet(object.pageSize) ? Number(object.pageSize) : 0,
      names: globalThis.Array.isArray(object?.names) ? object.names.map((e: any) => String(e)) : [],
    };
  },

  toJSON(message: ListSchemesRequest): unknown {
    const obj: any = {};
    if (message.pageToken !== "") {
      obj.pageToken = message.pageToken;
    }
    if (message.pageSize !== 0) {
      obj.pageSize = Math.round(message.pageSize);
    }
    if (message.names?.length) {
      obj.names = message.names;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<ListSchemesRequest>, I>>(base?: I): ListSchemesRequest {
    return ListSchemesRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<ListSchemesRequest>, I>>(object: I): ListSchemesRequest {
    const message = createBaseListSchemesRequest();
    message.pageToken = object.pageToken ?? "";
    message.pageSize = object.pageSize ?? 0;
    message.names = object.names?.map((e) => e) || [];
    return message;
  },
};

function createBaseListSchemesResponse(): ListSchemesResponse {
  return { schemes: [], nextPageToken: "" };
}

export const ListSchemesResponse = {
  encode(message: ListSchemesResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.schemes) {
      Scheme.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    if (message.nextPageToken !== "") {
      writer.uint32(18).string(message.nextPageToken);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ListSchemesResponse {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseListSchemesResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.schemes.push(Scheme.decode(reader, reader.uint32()));
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.nextPageToken = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ListSchemesResponse {
    return {
      schemes: globalThis.Array.isArray(object?.schemes) ? object.schemes.map((e: any) => Scheme.fromJSON(e)) : [],
      nextPageToken: isSet(object.nextPageToken) ? String(object.nextPageToken) : "",
    };
  },

  toJSON(message: ListSchemesResponse): unknown {
    const obj: any = {};
    if (message.schemes?.length) {
      obj.schemes = message.schemes.map((e) => Scheme.toJSON(e));
    }
    if (message.nextPageToken !== "") {
      obj.nextPageToken = message.nextPageToken;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<ListSchemesResponse>, I>>(base?: I): ListSchemesResponse {
    return ListSchemesResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<ListSchemesResponse>, I>>(object: I): ListSchemesResponse {
    const message = createBaseListSchemesResponse();
    message.schemes = object.schemes?.map((e) => Scheme.fromPartial(e)) || [];
    message.nextPageToken = object.nextPageToken ?? "";
    return message;
  },
};

function createBaseSubmitVoteRequest(): SubmitVoteRequest {
  return { ballotName: "", schemeName: "", response: 0, proposalName: "" };
}

export const SubmitVoteRequest = {
  encode(message: SubmitVoteRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.ballotName !== "") {
      writer.uint32(10).string(message.ballotName);
    }
    if (message.schemeName !== "") {
      writer.uint32(18).string(message.schemeName);
    }
    if (message.response !== 0) {
      writer.uint32(24).int32(message.response);
    }
    if (message.proposalName !== "") {
      writer.uint32(34).string(message.proposalName);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): SubmitVoteRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseSubmitVoteRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.ballotName = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.schemeName = reader.string();
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.response = reader.int32() as any;
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.proposalName = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): SubmitVoteRequest {
    return {
      ballotName: isSet(object.ballotName) ? String(object.ballotName) : "",
      schemeName: isSet(object.schemeName) ? String(object.schemeName) : "",
      response: isSet(object.response) ? optionFromJSON(object.response) : 0,
      proposalName: isSet(object.proposalName) ? String(object.proposalName) : "",
    };
  },

  toJSON(message: SubmitVoteRequest): unknown {
    const obj: any = {};
    if (message.ballotName !== "") {
      obj.ballotName = message.ballotName;
    }
    if (message.schemeName !== "") {
      obj.schemeName = message.schemeName;
    }
    if (message.response !== 0) {
      obj.response = optionToJSON(message.response);
    }
    if (message.proposalName !== "") {
      obj.proposalName = message.proposalName;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<SubmitVoteRequest>, I>>(base?: I): SubmitVoteRequest {
    return SubmitVoteRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<SubmitVoteRequest>, I>>(object: I): SubmitVoteRequest {
    const message = createBaseSubmitVoteRequest();
    message.ballotName = object.ballotName ?? "";
    message.schemeName = object.schemeName ?? "";
    message.response = object.response ?? 0;
    message.proposalName = object.proposalName ?? "";
    return message;
  },
};

function createBaseSubmitVoteResponse(): SubmitVoteResponse {
  return { response: 0 };
}

export const SubmitVoteResponse = {
  encode(message: SubmitVoteResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.response !== 0) {
      writer.uint32(8).int32(message.response);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): SubmitVoteResponse {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseSubmitVoteResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.response = reader.int32() as any;
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): SubmitVoteResponse {
    return { response: isSet(object.response) ? optionFromJSON(object.response) : 0 };
  },

  toJSON(message: SubmitVoteResponse): unknown {
    const obj: any = {};
    if (message.response !== 0) {
      obj.response = optionToJSON(message.response);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<SubmitVoteResponse>, I>>(base?: I): SubmitVoteResponse {
    return SubmitVoteResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<SubmitVoteResponse>, I>>(object: I): SubmitVoteResponse {
    const message = createBaseSubmitVoteResponse();
    message.response = object.response ?? 0;
    return message;
  },
};

function createBaseSubmitBallotCommentRequest(): SubmitBallotCommentRequest {
  return { ballotName: "", schemeName: "", investorComment: "", proposalName: "" };
}

export const SubmitBallotCommentRequest = {
  encode(message: SubmitBallotCommentRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.ballotName !== "") {
      writer.uint32(10).string(message.ballotName);
    }
    if (message.schemeName !== "") {
      writer.uint32(18).string(message.schemeName);
    }
    if (message.investorComment !== "") {
      writer.uint32(26).string(message.investorComment);
    }
    if (message.proposalName !== "") {
      writer.uint32(34).string(message.proposalName);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): SubmitBallotCommentRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseSubmitBallotCommentRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.ballotName = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.schemeName = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.investorComment = reader.string();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.proposalName = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): SubmitBallotCommentRequest {
    return {
      ballotName: isSet(object.ballotName) ? String(object.ballotName) : "",
      schemeName: isSet(object.schemeName) ? String(object.schemeName) : "",
      investorComment: isSet(object.investorComment) ? String(object.investorComment) : "",
      proposalName: isSet(object.proposalName) ? String(object.proposalName) : "",
    };
  },

  toJSON(message: SubmitBallotCommentRequest): unknown {
    const obj: any = {};
    if (message.ballotName !== "") {
      obj.ballotName = message.ballotName;
    }
    if (message.schemeName !== "") {
      obj.schemeName = message.schemeName;
    }
    if (message.investorComment !== "") {
      obj.investorComment = message.investorComment;
    }
    if (message.proposalName !== "") {
      obj.proposalName = message.proposalName;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<SubmitBallotCommentRequest>, I>>(base?: I): SubmitBallotCommentRequest {
    return SubmitBallotCommentRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<SubmitBallotCommentRequest>, I>>(object: I): SubmitBallotCommentRequest {
    const message = createBaseSubmitBallotCommentRequest();
    message.ballotName = object.ballotName ?? "";
    message.schemeName = object.schemeName ?? "";
    message.investorComment = object.investorComment ?? "";
    message.proposalName = object.proposalName ?? "";
    return message;
  },
};

function createBaseSubmitBallotCommentResponse(): SubmitBallotCommentResponse {
  return { investorComment: "" };
}

export const SubmitBallotCommentResponse = {
  encode(message: SubmitBallotCommentResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.investorComment !== "") {
      writer.uint32(10).string(message.investorComment);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): SubmitBallotCommentResponse {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseSubmitBallotCommentResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.investorComment = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): SubmitBallotCommentResponse {
    return { investorComment: isSet(object.investorComment) ? String(object.investorComment) : "" };
  },

  toJSON(message: SubmitBallotCommentResponse): unknown {
    const obj: any = {};
    if (message.investorComment !== "") {
      obj.investorComment = message.investorComment;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<SubmitBallotCommentResponse>, I>>(base?: I): SubmitBallotCommentResponse {
    return SubmitBallotCommentResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<SubmitBallotCommentResponse>, I>>(object: I): SubmitBallotCommentResponse {
    const message = createBaseSubmitBallotCommentResponse();
    message.investorComment = object.investorComment ?? "";
    return message;
  },
};

function createBaseListGeneralMeetingsRequest(): ListGeneralMeetingsRequest {
  return { pageSize: 0, pageToken: "", schemeName: "", search: "" };
}

export const ListGeneralMeetingsRequest = {
  encode(message: ListGeneralMeetingsRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.pageSize !== 0) {
      writer.uint32(8).int32(message.pageSize);
    }
    if (message.pageToken !== "") {
      writer.uint32(18).string(message.pageToken);
    }
    if (message.schemeName !== "") {
      writer.uint32(26).string(message.schemeName);
    }
    if (message.search !== "") {
      writer.uint32(34).string(message.search);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ListGeneralMeetingsRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseListGeneralMeetingsRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.pageSize = reader.int32();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.pageToken = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.schemeName = reader.string();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.search = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ListGeneralMeetingsRequest {
    return {
      pageSize: isSet(object.pageSize) ? Number(object.pageSize) : 0,
      pageToken: isSet(object.pageToken) ? String(object.pageToken) : "",
      schemeName: isSet(object.schemeName) ? String(object.schemeName) : "",
      search: isSet(object.search) ? String(object.search) : "",
    };
  },

  toJSON(message: ListGeneralMeetingsRequest): unknown {
    const obj: any = {};
    if (message.pageSize !== 0) {
      obj.pageSize = Math.round(message.pageSize);
    }
    if (message.pageToken !== "") {
      obj.pageToken = message.pageToken;
    }
    if (message.schemeName !== "") {
      obj.schemeName = message.schemeName;
    }
    if (message.search !== "") {
      obj.search = message.search;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<ListGeneralMeetingsRequest>, I>>(base?: I): ListGeneralMeetingsRequest {
    return ListGeneralMeetingsRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<ListGeneralMeetingsRequest>, I>>(object: I): ListGeneralMeetingsRequest {
    const message = createBaseListGeneralMeetingsRequest();
    message.pageSize = object.pageSize ?? 0;
    message.pageToken = object.pageToken ?? "";
    message.schemeName = object.schemeName ?? "";
    message.search = object.search ?? "";
    return message;
  },
};

function createBaseListGeneralMeetingsResponse(): ListGeneralMeetingsResponse {
  return { nextPageToken: "", meetings: [], organizations: {}, meetingNamesToBallotNames: {} };
}

export const ListGeneralMeetingsResponse = {
  encode(message: ListGeneralMeetingsResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.nextPageToken !== "") {
      writer.uint32(10).string(message.nextPageToken);
    }
    for (const v of message.meetings) {
      GeneralMeeting1.encode(v!, writer.uint32(18).fork()).ldelim();
    }
    Object.entries(message.organizations).forEach(([key, value]) => {
      ListGeneralMeetingsResponse_OrganizationsEntry.encode({ key: key as any, value }, writer.uint32(26).fork())
        .ldelim();
    });
    Object.entries(message.meetingNamesToBallotNames).forEach(([key, value]) => {
      ListGeneralMeetingsResponse_MeetingNamesToBallotNamesEntry.encode(
        { key: key as any, value },
        writer.uint32(58).fork(),
      ).ldelim();
    });
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ListGeneralMeetingsResponse {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseListGeneralMeetingsResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.nextPageToken = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.meetings.push(GeneralMeeting1.decode(reader, reader.uint32()));
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          const entry3 = ListGeneralMeetingsResponse_OrganizationsEntry.decode(reader, reader.uint32());
          if (entry3.value !== undefined) {
            message.organizations[entry3.key] = entry3.value;
          }
          continue;
        case 7:
          if (tag !== 58) {
            break;
          }

          const entry7 = ListGeneralMeetingsResponse_MeetingNamesToBallotNamesEntry.decode(reader, reader.uint32());
          if (entry7.value !== undefined) {
            message.meetingNamesToBallotNames[entry7.key] = entry7.value;
          }
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ListGeneralMeetingsResponse {
    return {
      nextPageToken: isSet(object.nextPageToken) ? String(object.nextPageToken) : "",
      meetings: globalThis.Array.isArray(object?.meetings)
        ? object.meetings.map((e: any) => GeneralMeeting.fromJSON(e))
        : [],
      organizations: isObject(object.organizations)
        ? Object.entries(object.organizations).reduce<{ [key: string]: Organization2 }>((acc, [key, value]) => {
          acc[key] = Organization2.fromJSON(value);
          return acc;
        }, {})
        : {},
      meetingNamesToBallotNames: isObject(object.meetingNamesToBallotNames)
        ? Object.entries(object.meetingNamesToBallotNames).reduce<{ [key: string]: string }>((acc, [key, value]) => {
          acc[key] = String(value);
          return acc;
        }, {})
        : {},
    };
  },

  toJSON(message: ListGeneralMeetingsResponse): unknown {
    const obj: any = {};
    if (message.nextPageToken !== "") {
      obj.nextPageToken = message.nextPageToken;
    }
    if (message.meetings?.length) {
      obj.meetings = message.meetings.map((e) => GeneralMeeting.toJSON(e));
    }
    if (message.organizations) {
      const entries = Object.entries(message.organizations);
      if (entries.length > 0) {
        obj.organizations = {};
        entries.forEach(([k, v]) => {
          obj.organizations[k] = Organization2.toJSON(v);
        });
      }
    }
    if (message.meetingNamesToBallotNames) {
      const entries = Object.entries(message.meetingNamesToBallotNames);
      if (entries.length > 0) {
        obj.meetingNamesToBallotNames = {};
        entries.forEach(([k, v]) => {
          obj.meetingNamesToBallotNames[k] = v;
        });
      }
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<ListGeneralMeetingsResponse>, I>>(base?: I): ListGeneralMeetingsResponse {
    return ListGeneralMeetingsResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<ListGeneralMeetingsResponse>, I>>(object: I): ListGeneralMeetingsResponse {
    const message = createBaseListGeneralMeetingsResponse();
    message.nextPageToken = object.nextPageToken ?? "";
    message.meetings = object.meetings?.map((e) => GeneralMeeting1.fromPartial(e)) || [];
    message.organizations = Object.entries(object.organizations ?? {}).reduce<{ [key: string]: Organization2 }>(
      (acc, [key, value]) => {
        if (value !== undefined) {
          acc[key] = Organization2.fromPartial(value);
        }
        return acc;
      },
      {},
    );
    message.meetingNamesToBallotNames = Object.entries(object.meetingNamesToBallotNames ?? {}).reduce<
      { [key: string]: string }
    >((acc, [key, value]) => {
      if (value !== undefined) {
        acc[key] = String(value);
      }
      return acc;
    }, {});
    return message;
  },
};

function createBaseListGeneralMeetingsResponse_OrganizationsEntry(): ListGeneralMeetingsResponse_OrganizationsEntry {
  return { key: "", value: undefined };
}

export const ListGeneralMeetingsResponse_OrganizationsEntry = {
  encode(
    message: ListGeneralMeetingsResponse_OrganizationsEntry,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.key !== "") {
      writer.uint32(10).string(message.key);
    }
    if (message.value !== undefined) {
      Organization2.encode(message.value, writer.uint32(18).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ListGeneralMeetingsResponse_OrganizationsEntry {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseListGeneralMeetingsResponse_OrganizationsEntry();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.key = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.value = Organization2.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ListGeneralMeetingsResponse_OrganizationsEntry {
    return {
      key: isSet(object.key) ? String(object.key) : "",
      value: isSet(object.value) ? Organization2.fromJSON(object.value) : undefined,
    };
  },

  toJSON(message: ListGeneralMeetingsResponse_OrganizationsEntry): unknown {
    const obj: any = {};
    if (message.key !== "") {
      obj.key = message.key;
    }
    if (message.value !== undefined) {
      obj.value = Organization2.toJSON(message.value);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<ListGeneralMeetingsResponse_OrganizationsEntry>, I>>(
    base?: I,
  ): ListGeneralMeetingsResponse_OrganizationsEntry {
    return ListGeneralMeetingsResponse_OrganizationsEntry.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<ListGeneralMeetingsResponse_OrganizationsEntry>, I>>(
    object: I,
  ): ListGeneralMeetingsResponse_OrganizationsEntry {
    const message = createBaseListGeneralMeetingsResponse_OrganizationsEntry();
    message.key = object.key ?? "";
    message.value = (object.value !== undefined && object.value !== null)
      ? Organization2.fromPartial(object.value)
      : undefined;
    return message;
  },
};

function createBaseListGeneralMeetingsResponse_MeetingNamesToBallotNamesEntry(): ListGeneralMeetingsResponse_MeetingNamesToBallotNamesEntry {
  return { key: "", value: "" };
}

export const ListGeneralMeetingsResponse_MeetingNamesToBallotNamesEntry = {
  encode(
    message: ListGeneralMeetingsResponse_MeetingNamesToBallotNamesEntry,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.key !== "") {
      writer.uint32(10).string(message.key);
    }
    if (message.value !== "") {
      writer.uint32(18).string(message.value);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ListGeneralMeetingsResponse_MeetingNamesToBallotNamesEntry {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseListGeneralMeetingsResponse_MeetingNamesToBallotNamesEntry();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.key = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.value = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ListGeneralMeetingsResponse_MeetingNamesToBallotNamesEntry {
    return { key: isSet(object.key) ? String(object.key) : "", value: isSet(object.value) ? String(object.value) : "" };
  },

  toJSON(message: ListGeneralMeetingsResponse_MeetingNamesToBallotNamesEntry): unknown {
    const obj: any = {};
    if (message.key !== "") {
      obj.key = message.key;
    }
    if (message.value !== "") {
      obj.value = message.value;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<ListGeneralMeetingsResponse_MeetingNamesToBallotNamesEntry>, I>>(
    base?: I,
  ): ListGeneralMeetingsResponse_MeetingNamesToBallotNamesEntry {
    return ListGeneralMeetingsResponse_MeetingNamesToBallotNamesEntry.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<ListGeneralMeetingsResponse_MeetingNamesToBallotNamesEntry>, I>>(
    object: I,
  ): ListGeneralMeetingsResponse_MeetingNamesToBallotNamesEntry {
    const message = createBaseListGeneralMeetingsResponse_MeetingNamesToBallotNamesEntry();
    message.key = object.key ?? "";
    message.value = object.value ?? "";
    return message;
  },
};

function createBaseGetGeneralMeetingRequest(): GetGeneralMeetingRequest {
  return { schemeName: "", meetingBallotName: "" };
}

export const GetGeneralMeetingRequest = {
  encode(message: GetGeneralMeetingRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.schemeName !== "") {
      writer.uint32(10).string(message.schemeName);
    }
    if (message.meetingBallotName !== "") {
      writer.uint32(26).string(message.meetingBallotName);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GetGeneralMeetingRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetGeneralMeetingRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.schemeName = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.meetingBallotName = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): GetGeneralMeetingRequest {
    return {
      schemeName: isSet(object.schemeName) ? String(object.schemeName) : "",
      meetingBallotName: isSet(object.meetingBallotName) ? String(object.meetingBallotName) : "",
    };
  },

  toJSON(message: GetGeneralMeetingRequest): unknown {
    const obj: any = {};
    if (message.schemeName !== "") {
      obj.schemeName = message.schemeName;
    }
    if (message.meetingBallotName !== "") {
      obj.meetingBallotName = message.meetingBallotName;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<GetGeneralMeetingRequest>, I>>(base?: I): GetGeneralMeetingRequest {
    return GetGeneralMeetingRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<GetGeneralMeetingRequest>, I>>(object: I): GetGeneralMeetingRequest {
    const message = createBaseGetGeneralMeetingRequest();
    message.schemeName = object.schemeName ?? "";
    message.meetingBallotName = object.meetingBallotName ?? "";
    return message;
  },
};

function createBaseGetGeneralMeetingResponse(): GetGeneralMeetingResponse {
  return { meeting: undefined, organization: undefined, proposals: [], ballots: {} };
}

export const GetGeneralMeetingResponse = {
  encode(message: GetGeneralMeetingResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.meeting !== undefined) {
      GeneralMeeting1.encode(message.meeting, writer.uint32(10).fork()).ldelim();
    }
    if (message.organization !== undefined) {
      Organization2.encode(message.organization, writer.uint32(26).fork()).ldelim();
    }
    for (const v of message.proposals) {
      Proposal3.encode(v!, writer.uint32(34).fork()).ldelim();
    }
    Object.entries(message.ballots).forEach(([key, value]) => {
      GetGeneralMeetingResponse_BallotsEntry.encode({ key: key as any, value }, writer.uint32(42).fork()).ldelim();
    });
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GetGeneralMeetingResponse {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetGeneralMeetingResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.meeting = GeneralMeeting1.decode(reader, reader.uint32());
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.organization = Organization2.decode(reader, reader.uint32());
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.proposals.push(Proposal3.decode(reader, reader.uint32()));
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          const entry5 = GetGeneralMeetingResponse_BallotsEntry.decode(reader, reader.uint32());
          if (entry5.value !== undefined) {
            message.ballots[entry5.key] = entry5.value;
          }
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): GetGeneralMeetingResponse {
    return {
      meeting: isSet(object.meeting) ? GeneralMeeting1.fromJSON(object.meeting) : undefined,
      organization: isSet(object.organization) ? Organization2.fromJSON(object.organization) : undefined,
      proposals: globalThis.Array.isArray(object?.proposals)
        ? object.proposals.map((e: any) => Proposal.fromJSON(e))
        : [],
      ballots: isObject(object.ballots)
        ? Object.entries(object.ballots).reduce<{ [key: string]: Ballot }>((acc, [key, value]) => {
          acc[key] = Ballot.fromJSON(value);
          return acc;
        }, {})
        : {},
    };
  },

  toJSON(message: GetGeneralMeetingResponse): unknown {
    const obj: any = {};
    if (message.meeting !== undefined) {
      obj.meeting = GeneralMeeting1.toJSON(message.meeting);
    }
    if (message.organization !== undefined) {
      obj.organization = Organization2.toJSON(message.organization);
    }
    if (message.proposals?.length) {
      obj.proposals = message.proposals.map((e) => Proposal.toJSON(e));
    }
    if (message.ballots) {
      const entries = Object.entries(message.ballots);
      if (entries.length > 0) {
        obj.ballots = {};
        entries.forEach(([k, v]) => {
          obj.ballots[k] = Ballot.toJSON(v);
        });
      }
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<GetGeneralMeetingResponse>, I>>(base?: I): GetGeneralMeetingResponse {
    return GetGeneralMeetingResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<GetGeneralMeetingResponse>, I>>(object: I): GetGeneralMeetingResponse {
    const message = createBaseGetGeneralMeetingResponse();
    message.meeting = (object.meeting !== undefined && object.meeting !== null)
      ? GeneralMeeting1.fromPartial(object.meeting)
      : undefined;
    message.organization = (object.organization !== undefined && object.organization !== null)
      ? Organization2.fromPartial(object.organization)
      : undefined;
    message.proposals = object.proposals?.map((e) => Proposal3.fromPartial(e)) || [];
    message.ballots = Object.entries(object.ballots ?? {}).reduce<{ [key: string]: Ballot }>((acc, [key, value]) => {
      if (value !== undefined) {
        acc[key] = Ballot.fromPartial(value);
      }
      return acc;
    }, {});
    return message;
  },
};

function createBaseGetGeneralMeetingResponse_BallotsEntry(): GetGeneralMeetingResponse_BallotsEntry {
  return { key: "", value: undefined };
}

export const GetGeneralMeetingResponse_BallotsEntry = {
  encode(message: GetGeneralMeetingResponse_BallotsEntry, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.key !== "") {
      writer.uint32(10).string(message.key);
    }
    if (message.value !== undefined) {
      Ballot.encode(message.value, writer.uint32(18).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GetGeneralMeetingResponse_BallotsEntry {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetGeneralMeetingResponse_BallotsEntry();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.key = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.value = Ballot.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): GetGeneralMeetingResponse_BallotsEntry {
    return {
      key: isSet(object.key) ? String(object.key) : "",
      value: isSet(object.value) ? Ballot.fromJSON(object.value) : undefined,
    };
  },

  toJSON(message: GetGeneralMeetingResponse_BallotsEntry): unknown {
    const obj: any = {};
    if (message.key !== "") {
      obj.key = message.key;
    }
    if (message.value !== undefined) {
      obj.value = Ballot.toJSON(message.value);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<GetGeneralMeetingResponse_BallotsEntry>, I>>(
    base?: I,
  ): GetGeneralMeetingResponse_BallotsEntry {
    return GetGeneralMeetingResponse_BallotsEntry.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<GetGeneralMeetingResponse_BallotsEntry>, I>>(
    object: I,
  ): GetGeneralMeetingResponse_BallotsEntry {
    const message = createBaseGetGeneralMeetingResponse_BallotsEntry();
    message.key = object.key ?? "";
    message.value = (object.value !== undefined && object.value !== null)
      ? Ballot.fromPartial(object.value)
      : undefined;
    return message;
  },
};

function createBaseListFeatureFlagsRequest(): ListFeatureFlagsRequest {
  return { pageToken: "", pageSize: 0, keys: [] };
}

export const ListFeatureFlagsRequest = {
  encode(message: ListFeatureFlagsRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.pageToken !== "") {
      writer.uint32(10).string(message.pageToken);
    }
    if (message.pageSize !== 0) {
      writer.uint32(16).int32(message.pageSize);
    }
    for (const v of message.keys) {
      writer.uint32(26).string(v!);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ListFeatureFlagsRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseListFeatureFlagsRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.pageToken = reader.string();
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.pageSize = reader.int32();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.keys.push(reader.string());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ListFeatureFlagsRequest {
    return {
      pageToken: isSet(object.pageToken) ? String(object.pageToken) : "",
      pageSize: isSet(object.pageSize) ? Number(object.pageSize) : 0,
      keys: globalThis.Array.isArray(object?.keys) ? object.keys.map((e: any) => String(e)) : [],
    };
  },

  toJSON(message: ListFeatureFlagsRequest): unknown {
    const obj: any = {};
    if (message.pageToken !== "") {
      obj.pageToken = message.pageToken;
    }
    if (message.pageSize !== 0) {
      obj.pageSize = Math.round(message.pageSize);
    }
    if (message.keys?.length) {
      obj.keys = message.keys;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<ListFeatureFlagsRequest>, I>>(base?: I): ListFeatureFlagsRequest {
    return ListFeatureFlagsRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<ListFeatureFlagsRequest>, I>>(object: I): ListFeatureFlagsRequest {
    const message = createBaseListFeatureFlagsRequest();
    message.pageToken = object.pageToken ?? "";
    message.pageSize = object.pageSize ?? 0;
    message.keys = object.keys?.map((e) => e) || [];
    return message;
  },
};

function createBaseAdminListFeatureFlagsRequest(): AdminListFeatureFlagsRequest {
  return { pageToken: "", pageSize: 0, keys: [] };
}

export const AdminListFeatureFlagsRequest = {
  encode(message: AdminListFeatureFlagsRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.pageToken !== "") {
      writer.uint32(10).string(message.pageToken);
    }
    if (message.pageSize !== 0) {
      writer.uint32(16).int32(message.pageSize);
    }
    for (const v of message.keys) {
      writer.uint32(26).string(v!);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): AdminListFeatureFlagsRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseAdminListFeatureFlagsRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.pageToken = reader.string();
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.pageSize = reader.int32();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.keys.push(reader.string());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): AdminListFeatureFlagsRequest {
    return {
      pageToken: isSet(object.pageToken) ? String(object.pageToken) : "",
      pageSize: isSet(object.pageSize) ? Number(object.pageSize) : 0,
      keys: globalThis.Array.isArray(object?.keys) ? object.keys.map((e: any) => String(e)) : [],
    };
  },

  toJSON(message: AdminListFeatureFlagsRequest): unknown {
    const obj: any = {};
    if (message.pageToken !== "") {
      obj.pageToken = message.pageToken;
    }
    if (message.pageSize !== 0) {
      obj.pageSize = Math.round(message.pageSize);
    }
    if (message.keys?.length) {
      obj.keys = message.keys;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<AdminListFeatureFlagsRequest>, I>>(base?: I): AdminListFeatureFlagsRequest {
    return AdminListFeatureFlagsRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<AdminListFeatureFlagsRequest>, I>>(object: I): AdminListFeatureFlagsRequest {
    const message = createBaseAdminListFeatureFlagsRequest();
    message.pageToken = object.pageToken ?? "";
    message.pageSize = object.pageSize ?? 0;
    message.keys = object.keys?.map((e) => e) || [];
    return message;
  },
};

function createBaseListFeatureFlagsResponse(): ListFeatureFlagsResponse {
  return { featureFlags: [], nextPageToken: "" };
}

export const ListFeatureFlagsResponse = {
  encode(message: ListFeatureFlagsResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.featureFlags) {
      FeatureFlag.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    if (message.nextPageToken !== "") {
      writer.uint32(18).string(message.nextPageToken);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ListFeatureFlagsResponse {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseListFeatureFlagsResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.featureFlags.push(FeatureFlag.decode(reader, reader.uint32()));
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.nextPageToken = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ListFeatureFlagsResponse {
    return {
      featureFlags: globalThis.Array.isArray(object?.featureFlags)
        ? object.featureFlags.map((e: any) => FeatureFlag.fromJSON(e))
        : [],
      nextPageToken: isSet(object.nextPageToken) ? String(object.nextPageToken) : "",
    };
  },

  toJSON(message: ListFeatureFlagsResponse): unknown {
    const obj: any = {};
    if (message.featureFlags?.length) {
      obj.featureFlags = message.featureFlags.map((e) => FeatureFlag.toJSON(e));
    }
    if (message.nextPageToken !== "") {
      obj.nextPageToken = message.nextPageToken;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<ListFeatureFlagsResponse>, I>>(base?: I): ListFeatureFlagsResponse {
    return ListFeatureFlagsResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<ListFeatureFlagsResponse>, I>>(object: I): ListFeatureFlagsResponse {
    const message = createBaseListFeatureFlagsResponse();
    message.featureFlags = object.featureFlags?.map((e) => FeatureFlag.fromPartial(e)) || [];
    message.nextPageToken = object.nextPageToken ?? "";
    return message;
  },
};

function createBaseAdminUpdateFeatureFlagRequest(): AdminUpdateFeatureFlagRequest {
  return { featureFlag: undefined };
}

export const AdminUpdateFeatureFlagRequest = {
  encode(message: AdminUpdateFeatureFlagRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.featureFlag !== undefined) {
      FeatureFlag.encode(message.featureFlag, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): AdminUpdateFeatureFlagRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseAdminUpdateFeatureFlagRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.featureFlag = FeatureFlag.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): AdminUpdateFeatureFlagRequest {
    return { featureFlag: isSet(object.featureFlag) ? FeatureFlag.fromJSON(object.featureFlag) : undefined };
  },

  toJSON(message: AdminUpdateFeatureFlagRequest): unknown {
    const obj: any = {};
    if (message.featureFlag !== undefined) {
      obj.featureFlag = FeatureFlag.toJSON(message.featureFlag);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<AdminUpdateFeatureFlagRequest>, I>>(base?: I): AdminUpdateFeatureFlagRequest {
    return AdminUpdateFeatureFlagRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<AdminUpdateFeatureFlagRequest>, I>>(
    object: I,
  ): AdminUpdateFeatureFlagRequest {
    const message = createBaseAdminUpdateFeatureFlagRequest();
    message.featureFlag = (object.featureFlag !== undefined && object.featureFlag !== null)
      ? FeatureFlag.fromPartial(object.featureFlag)
      : undefined;
    return message;
  },
};

function createBaseAdminCreateUserFeatureFlagOverrideRequest(): AdminCreateUserFeatureFlagOverrideRequest {
  return { userId: "", featureFlag: undefined };
}

export const AdminCreateUserFeatureFlagOverrideRequest = {
  encode(message: AdminCreateUserFeatureFlagOverrideRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.userId !== "") {
      writer.uint32(10).string(message.userId);
    }
    if (message.featureFlag !== undefined) {
      FeatureFlag.encode(message.featureFlag, writer.uint32(18).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): AdminCreateUserFeatureFlagOverrideRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseAdminCreateUserFeatureFlagOverrideRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.userId = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.featureFlag = FeatureFlag.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): AdminCreateUserFeatureFlagOverrideRequest {
    return {
      userId: isSet(object.userId) ? String(object.userId) : "",
      featureFlag: isSet(object.featureFlag) ? FeatureFlag.fromJSON(object.featureFlag) : undefined,
    };
  },

  toJSON(message: AdminCreateUserFeatureFlagOverrideRequest): unknown {
    const obj: any = {};
    if (message.userId !== "") {
      obj.userId = message.userId;
    }
    if (message.featureFlag !== undefined) {
      obj.featureFlag = FeatureFlag.toJSON(message.featureFlag);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<AdminCreateUserFeatureFlagOverrideRequest>, I>>(
    base?: I,
  ): AdminCreateUserFeatureFlagOverrideRequest {
    return AdminCreateUserFeatureFlagOverrideRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<AdminCreateUserFeatureFlagOverrideRequest>, I>>(
    object: I,
  ): AdminCreateUserFeatureFlagOverrideRequest {
    const message = createBaseAdminCreateUserFeatureFlagOverrideRequest();
    message.userId = object.userId ?? "";
    message.featureFlag = (object.featureFlag !== undefined && object.featureFlag !== null)
      ? FeatureFlag.fromPartial(object.featureFlag)
      : undefined;
    return message;
  },
};

function createBaseAdminDeleteUserFeatureFlagOverrideRequest(): AdminDeleteUserFeatureFlagOverrideRequest {
  return { userId: "", featureFlagKey: "" };
}

export const AdminDeleteUserFeatureFlagOverrideRequest = {
  encode(message: AdminDeleteUserFeatureFlagOverrideRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.userId !== "") {
      writer.uint32(10).string(message.userId);
    }
    if (message.featureFlagKey !== "") {
      writer.uint32(18).string(message.featureFlagKey);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): AdminDeleteUserFeatureFlagOverrideRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseAdminDeleteUserFeatureFlagOverrideRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.userId = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.featureFlagKey = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): AdminDeleteUserFeatureFlagOverrideRequest {
    return {
      userId: isSet(object.userId) ? String(object.userId) : "",
      featureFlagKey: isSet(object.featureFlagKey) ? String(object.featureFlagKey) : "",
    };
  },

  toJSON(message: AdminDeleteUserFeatureFlagOverrideRequest): unknown {
    const obj: any = {};
    if (message.userId !== "") {
      obj.userId = message.userId;
    }
    if (message.featureFlagKey !== "") {
      obj.featureFlagKey = message.featureFlagKey;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<AdminDeleteUserFeatureFlagOverrideRequest>, I>>(
    base?: I,
  ): AdminDeleteUserFeatureFlagOverrideRequest {
    return AdminDeleteUserFeatureFlagOverrideRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<AdminDeleteUserFeatureFlagOverrideRequest>, I>>(
    object: I,
  ): AdminDeleteUserFeatureFlagOverrideRequest {
    const message = createBaseAdminDeleteUserFeatureFlagOverrideRequest();
    message.userId = object.userId ?? "";
    message.featureFlagKey = object.featureFlagKey ?? "";
    return message;
  },
};

function createBaseFetchInvestmentsRequest(): FetchInvestmentsRequest {
  return { schemeName: "" };
}

export const FetchInvestmentsRequest = {
  encode(message: FetchInvestmentsRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.schemeName !== "") {
      writer.uint32(10).string(message.schemeName);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): FetchInvestmentsRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseFetchInvestmentsRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.schemeName = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): FetchInvestmentsRequest {
    return { schemeName: isSet(object.schemeName) ? String(object.schemeName) : "" };
  },

  toJSON(message: FetchInvestmentsRequest): unknown {
    const obj: any = {};
    if (message.schemeName !== "") {
      obj.schemeName = message.schemeName;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<FetchInvestmentsRequest>, I>>(base?: I): FetchInvestmentsRequest {
    return FetchInvestmentsRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<FetchInvestmentsRequest>, I>>(object: I): FetchInvestmentsRequest {
    const message = createBaseFetchInvestmentsRequest();
    message.schemeName = object.schemeName ?? "";
    return message;
  },
};

function createBaseFetchInvestmentsResponse(): FetchInvestmentsResponse {
  return { compositeInstruments: {}, fundManagerNameToTitle: {}, schemeNameToTitle: {}, edges: [] };
}

export const FetchInvestmentsResponse = {
  encode(message: FetchInvestmentsResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    Object.entries(message.compositeInstruments).forEach(([key, value]) => {
      FetchInvestmentsResponse_CompositeInstrumentsEntry.encode({ key: key as any, value }, writer.uint32(10).fork())
        .ldelim();
    });
    Object.entries(message.fundManagerNameToTitle).forEach(([key, value]) => {
      FetchInvestmentsResponse_FundManagerNameToTitleEntry.encode({ key: key as any, value }, writer.uint32(18).fork())
        .ldelim();
    });
    Object.entries(message.schemeNameToTitle).forEach(([key, value]) => {
      FetchInvestmentsResponse_SchemeNameToTitleEntry.encode({ key: key as any, value }, writer.uint32(26).fork())
        .ldelim();
    });
    for (const v of message.edges) {
      CompositeTreeEdge.encode(v!, writer.uint32(34).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): FetchInvestmentsResponse {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseFetchInvestmentsResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          const entry1 = FetchInvestmentsResponse_CompositeInstrumentsEntry.decode(reader, reader.uint32());
          if (entry1.value !== undefined) {
            message.compositeInstruments[entry1.key] = entry1.value;
          }
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          const entry2 = FetchInvestmentsResponse_FundManagerNameToTitleEntry.decode(reader, reader.uint32());
          if (entry2.value !== undefined) {
            message.fundManagerNameToTitle[entry2.key] = entry2.value;
          }
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          const entry3 = FetchInvestmentsResponse_SchemeNameToTitleEntry.decode(reader, reader.uint32());
          if (entry3.value !== undefined) {
            message.schemeNameToTitle[entry3.key] = entry3.value;
          }
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.edges.push(CompositeTreeEdge.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): FetchInvestmentsResponse {
    return {
      compositeInstruments: isObject(object.compositeInstruments)
        ? Object.entries(object.compositeInstruments).reduce<{ [key: string]: CompositeInstrument }>(
          (acc, [key, value]) => {
            acc[key] = CompositeInstrument.fromJSON(value);
            return acc;
          },
          {},
        )
        : {},
      fundManagerNameToTitle: isObject(object.fundManagerNameToTitle)
        ? Object.entries(object.fundManagerNameToTitle).reduce<{ [key: string]: string }>((acc, [key, value]) => {
          acc[key] = String(value);
          return acc;
        }, {})
        : {},
      schemeNameToTitle: isObject(object.schemeNameToTitle)
        ? Object.entries(object.schemeNameToTitle).reduce<{ [key: string]: string }>((acc, [key, value]) => {
          acc[key] = String(value);
          return acc;
        }, {})
        : {},
      edges: globalThis.Array.isArray(object?.edges) ? object.edges.map((e: any) => CompositeTreeEdge.fromJSON(e)) : [],
    };
  },

  toJSON(message: FetchInvestmentsResponse): unknown {
    const obj: any = {};
    if (message.compositeInstruments) {
      const entries = Object.entries(message.compositeInstruments);
      if (entries.length > 0) {
        obj.compositeInstruments = {};
        entries.forEach(([k, v]) => {
          obj.compositeInstruments[k] = CompositeInstrument.toJSON(v);
        });
      }
    }
    if (message.fundManagerNameToTitle) {
      const entries = Object.entries(message.fundManagerNameToTitle);
      if (entries.length > 0) {
        obj.fundManagerNameToTitle = {};
        entries.forEach(([k, v]) => {
          obj.fundManagerNameToTitle[k] = v;
        });
      }
    }
    if (message.schemeNameToTitle) {
      const entries = Object.entries(message.schemeNameToTitle);
      if (entries.length > 0) {
        obj.schemeNameToTitle = {};
        entries.forEach(([k, v]) => {
          obj.schemeNameToTitle[k] = v;
        });
      }
    }
    if (message.edges?.length) {
      obj.edges = message.edges.map((e) => CompositeTreeEdge.toJSON(e));
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<FetchInvestmentsResponse>, I>>(base?: I): FetchInvestmentsResponse {
    return FetchInvestmentsResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<FetchInvestmentsResponse>, I>>(object: I): FetchInvestmentsResponse {
    const message = createBaseFetchInvestmentsResponse();
    message.compositeInstruments = Object.entries(object.compositeInstruments ?? {}).reduce<
      { [key: string]: CompositeInstrument }
    >((acc, [key, value]) => {
      if (value !== undefined) {
        acc[key] = CompositeInstrument.fromPartial(value);
      }
      return acc;
    }, {});
    message.fundManagerNameToTitle = Object.entries(object.fundManagerNameToTitle ?? {}).reduce<
      { [key: string]: string }
    >((acc, [key, value]) => {
      if (value !== undefined) {
        acc[key] = String(value);
      }
      return acc;
    }, {});
    message.schemeNameToTitle = Object.entries(object.schemeNameToTitle ?? {}).reduce<{ [key: string]: string }>(
      (acc, [key, value]) => {
        if (value !== undefined) {
          acc[key] = String(value);
        }
        return acc;
      },
      {},
    );
    message.edges = object.edges?.map((e) => CompositeTreeEdge.fromPartial(e)) || [];
    return message;
  },
};

function createBaseFetchInvestmentsResponse_CompositeInstrumentsEntry(): FetchInvestmentsResponse_CompositeInstrumentsEntry {
  return { key: "", value: undefined };
}

export const FetchInvestmentsResponse_CompositeInstrumentsEntry = {
  encode(
    message: FetchInvestmentsResponse_CompositeInstrumentsEntry,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.key !== "") {
      writer.uint32(10).string(message.key);
    }
    if (message.value !== undefined) {
      CompositeInstrument.encode(message.value, writer.uint32(18).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): FetchInvestmentsResponse_CompositeInstrumentsEntry {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseFetchInvestmentsResponse_CompositeInstrumentsEntry();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.key = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.value = CompositeInstrument.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): FetchInvestmentsResponse_CompositeInstrumentsEntry {
    return {
      key: isSet(object.key) ? String(object.key) : "",
      value: isSet(object.value) ? CompositeInstrument.fromJSON(object.value) : undefined,
    };
  },

  toJSON(message: FetchInvestmentsResponse_CompositeInstrumentsEntry): unknown {
    const obj: any = {};
    if (message.key !== "") {
      obj.key = message.key;
    }
    if (message.value !== undefined) {
      obj.value = CompositeInstrument.toJSON(message.value);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<FetchInvestmentsResponse_CompositeInstrumentsEntry>, I>>(
    base?: I,
  ): FetchInvestmentsResponse_CompositeInstrumentsEntry {
    return FetchInvestmentsResponse_CompositeInstrumentsEntry.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<FetchInvestmentsResponse_CompositeInstrumentsEntry>, I>>(
    object: I,
  ): FetchInvestmentsResponse_CompositeInstrumentsEntry {
    const message = createBaseFetchInvestmentsResponse_CompositeInstrumentsEntry();
    message.key = object.key ?? "";
    message.value = (object.value !== undefined && object.value !== null)
      ? CompositeInstrument.fromPartial(object.value)
      : undefined;
    return message;
  },
};

function createBaseFetchInvestmentsResponse_FundManagerNameToTitleEntry(): FetchInvestmentsResponse_FundManagerNameToTitleEntry {
  return { key: "", value: "" };
}

export const FetchInvestmentsResponse_FundManagerNameToTitleEntry = {
  encode(
    message: FetchInvestmentsResponse_FundManagerNameToTitleEntry,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.key !== "") {
      writer.uint32(10).string(message.key);
    }
    if (message.value !== "") {
      writer.uint32(18).string(message.value);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): FetchInvestmentsResponse_FundManagerNameToTitleEntry {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseFetchInvestmentsResponse_FundManagerNameToTitleEntry();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.key = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.value = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): FetchInvestmentsResponse_FundManagerNameToTitleEntry {
    return { key: isSet(object.key) ? String(object.key) : "", value: isSet(object.value) ? String(object.value) : "" };
  },

  toJSON(message: FetchInvestmentsResponse_FundManagerNameToTitleEntry): unknown {
    const obj: any = {};
    if (message.key !== "") {
      obj.key = message.key;
    }
    if (message.value !== "") {
      obj.value = message.value;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<FetchInvestmentsResponse_FundManagerNameToTitleEntry>, I>>(
    base?: I,
  ): FetchInvestmentsResponse_FundManagerNameToTitleEntry {
    return FetchInvestmentsResponse_FundManagerNameToTitleEntry.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<FetchInvestmentsResponse_FundManagerNameToTitleEntry>, I>>(
    object: I,
  ): FetchInvestmentsResponse_FundManagerNameToTitleEntry {
    const message = createBaseFetchInvestmentsResponse_FundManagerNameToTitleEntry();
    message.key = object.key ?? "";
    message.value = object.value ?? "";
    return message;
  },
};

function createBaseFetchInvestmentsResponse_SchemeNameToTitleEntry(): FetchInvestmentsResponse_SchemeNameToTitleEntry {
  return { key: "", value: "" };
}

export const FetchInvestmentsResponse_SchemeNameToTitleEntry = {
  encode(
    message: FetchInvestmentsResponse_SchemeNameToTitleEntry,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.key !== "") {
      writer.uint32(10).string(message.key);
    }
    if (message.value !== "") {
      writer.uint32(18).string(message.value);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): FetchInvestmentsResponse_SchemeNameToTitleEntry {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseFetchInvestmentsResponse_SchemeNameToTitleEntry();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.key = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.value = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): FetchInvestmentsResponse_SchemeNameToTitleEntry {
    return { key: isSet(object.key) ? String(object.key) : "", value: isSet(object.value) ? String(object.value) : "" };
  },

  toJSON(message: FetchInvestmentsResponse_SchemeNameToTitleEntry): unknown {
    const obj: any = {};
    if (message.key !== "") {
      obj.key = message.key;
    }
    if (message.value !== "") {
      obj.value = message.value;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<FetchInvestmentsResponse_SchemeNameToTitleEntry>, I>>(
    base?: I,
  ): FetchInvestmentsResponse_SchemeNameToTitleEntry {
    return FetchInvestmentsResponse_SchemeNameToTitleEntry.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<FetchInvestmentsResponse_SchemeNameToTitleEntry>, I>>(
    object: I,
  ): FetchInvestmentsResponse_SchemeNameToTitleEntry {
    const message = createBaseFetchInvestmentsResponse_SchemeNameToTitleEntry();
    message.key = object.key ?? "";
    message.value = object.value ?? "";
    return message;
  },
};

function createBaseListFundManagersRequest(): ListFundManagersRequest {
  return { pageToken: "", pageSize: 0, schemeName: "" };
}

export const ListFundManagersRequest = {
  encode(message: ListFundManagersRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.pageToken !== "") {
      writer.uint32(10).string(message.pageToken);
    }
    if (message.pageSize !== 0) {
      writer.uint32(16).int32(message.pageSize);
    }
    if (message.schemeName !== "") {
      writer.uint32(26).string(message.schemeName);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ListFundManagersRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseListFundManagersRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.pageToken = reader.string();
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.pageSize = reader.int32();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.schemeName = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ListFundManagersRequest {
    return {
      pageToken: isSet(object.pageToken) ? String(object.pageToken) : "",
      pageSize: isSet(object.pageSize) ? Number(object.pageSize) : 0,
      schemeName: isSet(object.schemeName) ? String(object.schemeName) : "",
    };
  },

  toJSON(message: ListFundManagersRequest): unknown {
    const obj: any = {};
    if (message.pageToken !== "") {
      obj.pageToken = message.pageToken;
    }
    if (message.pageSize !== 0) {
      obj.pageSize = Math.round(message.pageSize);
    }
    if (message.schemeName !== "") {
      obj.schemeName = message.schemeName;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<ListFundManagersRequest>, I>>(base?: I): ListFundManagersRequest {
    return ListFundManagersRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<ListFundManagersRequest>, I>>(object: I): ListFundManagersRequest {
    const message = createBaseListFundManagersRequest();
    message.pageToken = object.pageToken ?? "";
    message.pageSize = object.pageSize ?? 0;
    message.schemeName = object.schemeName ?? "";
    return message;
  },
};

function createBaseListFundManagersResponse(): ListFundManagersResponse {
  return { fundManagers: [], nextPageToken: "" };
}

export const ListFundManagersResponse = {
  encode(message: ListFundManagersResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.fundManagers) {
      FundManager.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    if (message.nextPageToken !== "") {
      writer.uint32(18).string(message.nextPageToken);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ListFundManagersResponse {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseListFundManagersResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.fundManagers.push(FundManager.decode(reader, reader.uint32()));
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.nextPageToken = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ListFundManagersResponse {
    return {
      fundManagers: globalThis.Array.isArray(object?.fundManagers)
        ? object.fundManagers.map((e: any) => FundManager.fromJSON(e))
        : [],
      nextPageToken: isSet(object.nextPageToken) ? String(object.nextPageToken) : "",
    };
  },

  toJSON(message: ListFundManagersResponse): unknown {
    const obj: any = {};
    if (message.fundManagers?.length) {
      obj.fundManagers = message.fundManagers.map((e) => FundManager.toJSON(e));
    }
    if (message.nextPageToken !== "") {
      obj.nextPageToken = message.nextPageToken;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<ListFundManagersResponse>, I>>(base?: I): ListFundManagersResponse {
    return ListFundManagersResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<ListFundManagersResponse>, I>>(object: I): ListFundManagersResponse {
    const message = createBaseListFundManagersResponse();
    message.fundManagers = object.fundManagers?.map((e) => FundManager.fromPartial(e)) || [];
    message.nextPageToken = object.nextPageToken ?? "";
    return message;
  },
};

function createBaseGetVotingPolicyRequest(): GetVotingPolicyRequest {
  return { schemeName: "" };
}

export const GetVotingPolicyRequest = {
  encode(message: GetVotingPolicyRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.schemeName !== "") {
      writer.uint32(10).string(message.schemeName);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GetVotingPolicyRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetVotingPolicyRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.schemeName = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): GetVotingPolicyRequest {
    return { schemeName: isSet(object.schemeName) ? String(object.schemeName) : "" };
  },

  toJSON(message: GetVotingPolicyRequest): unknown {
    const obj: any = {};
    if (message.schemeName !== "") {
      obj.schemeName = message.schemeName;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<GetVotingPolicyRequest>, I>>(base?: I): GetVotingPolicyRequest {
    return GetVotingPolicyRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<GetVotingPolicyRequest>, I>>(object: I): GetVotingPolicyRequest {
    const message = createBaseGetVotingPolicyRequest();
    message.schemeName = object.schemeName ?? "";
    return message;
  },
};

function createBaseGetVotingPolicyResponse(): GetVotingPolicyResponse {
  return { votingPolicy: undefined };
}

export const GetVotingPolicyResponse = {
  encode(message: GetVotingPolicyResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.votingPolicy !== undefined) {
      VotingPolicy.encode(message.votingPolicy, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GetVotingPolicyResponse {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetVotingPolicyResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.votingPolicy = VotingPolicy.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): GetVotingPolicyResponse {
    return { votingPolicy: isSet(object.votingPolicy) ? VotingPolicy.fromJSON(object.votingPolicy) : undefined };
  },

  toJSON(message: GetVotingPolicyResponse): unknown {
    const obj: any = {};
    if (message.votingPolicy !== undefined) {
      obj.votingPolicy = VotingPolicy.toJSON(message.votingPolicy);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<GetVotingPolicyResponse>, I>>(base?: I): GetVotingPolicyResponse {
    return GetVotingPolicyResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<GetVotingPolicyResponse>, I>>(object: I): GetVotingPolicyResponse {
    const message = createBaseGetVotingPolicyResponse();
    message.votingPolicy = (object.votingPolicy !== undefined && object.votingPolicy !== null)
      ? VotingPolicy.fromPartial(object.votingPolicy)
      : undefined;
    return message;
  },
};

function createBaseAdminDeleteUserResponse(): AdminDeleteUserResponse {
  return {};
}

export const AdminDeleteUserResponse = {
  encode(_: AdminDeleteUserResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): AdminDeleteUserResponse {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseAdminDeleteUserResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(_: any): AdminDeleteUserResponse {
    return {};
  },

  toJSON(_: AdminDeleteUserResponse): unknown {
    const obj: any = {};
    return obj;
  },

  create<I extends Exact<DeepPartial<AdminDeleteUserResponse>, I>>(base?: I): AdminDeleteUserResponse {
    return AdminDeleteUserResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<AdminDeleteUserResponse>, I>>(_: I): AdminDeleteUserResponse {
    const message = createBaseAdminDeleteUserResponse();
    return message;
  },
};

function createBaseAdminPutQuarterlyReportRequest(): AdminPutQuarterlyReportRequest {
  return { calendarQuarter: undefined, parent: "", file: undefined };
}

export const AdminPutQuarterlyReportRequest = {
  encode(message: AdminPutQuarterlyReportRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.calendarQuarter !== undefined) {
      CalendarQuarter.encode(message.calendarQuarter, writer.uint32(10).fork()).ldelim();
    }
    if (message.parent !== "") {
      writer.uint32(18).string(message.parent);
    }
    if (message.file !== undefined) {
      File.encode(message.file, writer.uint32(26).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): AdminPutQuarterlyReportRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseAdminPutQuarterlyReportRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.calendarQuarter = CalendarQuarter.decode(reader, reader.uint32());
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.parent = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.file = File.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): AdminPutQuarterlyReportRequest {
    return {
      calendarQuarter: isSet(object.calendarQuarter) ? CalendarQuarter.fromJSON(object.calendarQuarter) : undefined,
      parent: isSet(object.parent) ? String(object.parent) : "",
      file: isSet(object.file) ? File.fromJSON(object.file) : undefined,
    };
  },

  toJSON(message: AdminPutQuarterlyReportRequest): unknown {
    const obj: any = {};
    if (message.calendarQuarter !== undefined) {
      obj.calendarQuarter = CalendarQuarter.toJSON(message.calendarQuarter);
    }
    if (message.parent !== "") {
      obj.parent = message.parent;
    }
    if (message.file !== undefined) {
      obj.file = File.toJSON(message.file);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<AdminPutQuarterlyReportRequest>, I>>(base?: I): AdminPutQuarterlyReportRequest {
    return AdminPutQuarterlyReportRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<AdminPutQuarterlyReportRequest>, I>>(
    object: I,
  ): AdminPutQuarterlyReportRequest {
    const message = createBaseAdminPutQuarterlyReportRequest();
    message.calendarQuarter = (object.calendarQuarter !== undefined && object.calendarQuarter !== null)
      ? CalendarQuarter.fromPartial(object.calendarQuarter)
      : undefined;
    message.parent = object.parent ?? "";
    message.file = (object.file !== undefined && object.file !== null) ? File.fromPartial(object.file) : undefined;
    return message;
  },
};

function createBaseListQuarterlyReportsRequest(): ListQuarterlyReportsRequest {
  return { parent: "", calendarQuarter: undefined };
}

export const ListQuarterlyReportsRequest = {
  encode(message: ListQuarterlyReportsRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.parent !== "") {
      writer.uint32(10).string(message.parent);
    }
    if (message.calendarQuarter !== undefined) {
      CalendarQuarter.encode(message.calendarQuarter, writer.uint32(18).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ListQuarterlyReportsRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseListQuarterlyReportsRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.parent = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.calendarQuarter = CalendarQuarter.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ListQuarterlyReportsRequest {
    return {
      parent: isSet(object.parent) ? String(object.parent) : "",
      calendarQuarter: isSet(object.calendarQuarter) ? CalendarQuarter.fromJSON(object.calendarQuarter) : undefined,
    };
  },

  toJSON(message: ListQuarterlyReportsRequest): unknown {
    const obj: any = {};
    if (message.parent !== "") {
      obj.parent = message.parent;
    }
    if (message.calendarQuarter !== undefined) {
      obj.calendarQuarter = CalendarQuarter.toJSON(message.calendarQuarter);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<ListQuarterlyReportsRequest>, I>>(base?: I): ListQuarterlyReportsRequest {
    return ListQuarterlyReportsRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<ListQuarterlyReportsRequest>, I>>(object: I): ListQuarterlyReportsRequest {
    const message = createBaseListQuarterlyReportsRequest();
    message.parent = object.parent ?? "";
    message.calendarQuarter = (object.calendarQuarter !== undefined && object.calendarQuarter !== null)
      ? CalendarQuarter.fromPartial(object.calendarQuarter)
      : undefined;
    return message;
  },
};

function createBaseListQuarterlyReportsResponse(): ListQuarterlyReportsResponse {
  return { quarterlyReports: [] };
}

export const ListQuarterlyReportsResponse = {
  encode(message: ListQuarterlyReportsResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.quarterlyReports) {
      QuarterlyReport.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ListQuarterlyReportsResponse {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseListQuarterlyReportsResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.quarterlyReports.push(QuarterlyReport.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ListQuarterlyReportsResponse {
    return {
      quarterlyReports: globalThis.Array.isArray(object?.quarterlyReports)
        ? object.quarterlyReports.map((e: any) => QuarterlyReport.fromJSON(e))
        : [],
    };
  },

  toJSON(message: ListQuarterlyReportsResponse): unknown {
    const obj: any = {};
    if (message.quarterlyReports?.length) {
      obj.quarterlyReports = message.quarterlyReports.map((e) => QuarterlyReport.toJSON(e));
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<ListQuarterlyReportsResponse>, I>>(base?: I): ListQuarterlyReportsResponse {
    return ListQuarterlyReportsResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<ListQuarterlyReportsResponse>, I>>(object: I): ListQuarterlyReportsResponse {
    const message = createBaseListQuarterlyReportsResponse();
    message.quarterlyReports = object.quarterlyReports?.map((e) => QuarterlyReport.fromPartial(e)) || [];
    return message;
  },
};

/** StewardshipBff is a backend service for the stewardship dashboard frontend */
export interface StewardshipBff {
  /**
   * GetFundManager gets a single fund manager and the funds managed by this fund manager
   *
   * Possible Errors:
   * - PermissionDenied - if the requesting user is not allowed to access this fund manager
   */
  GetFundManager(
    request: DeepPartial<GetFundManagerRequest>,
    metadata?: grpc.Metadata,
  ): Promise<GetFundManagerResponse>;
  /**
   * Lists all users
   * Ordered by sub
   */
  AdminListUsers(
    request: DeepPartial<AdminListUsersRequest>,
    metadata?: grpc.Metadata,
  ): Promise<AdminListUsersResponse>;
  /**
   * Lists all users
   * Ordered by sub
   * Extra information will be collected from Cognito to return detailedUsers
   * This endpoint will be much slower due to this extra data fetching
   */
  AdminListDetailedUsers(
    request: DeepPartial<AdminListDetailedUsersRequest>,
    metadata?: grpc.Metadata,
  ): Promise<AdminListDetailedUsersResponse>;
  /**
   * Updates a user
   *
   * Possible Errors:
   *
   * - NotFound if the user doesn't exist
   * - InvalidArgument if any of the schemes specified don't exist
   *
   * The following properties can be set:
   * - allowed scheme names
   */
  AdminUpdateUser(request: DeepPartial<AdminUpdateUserRequest>, metadata?: grpc.Metadata): Promise<User>;
  /**
   * Creates a user with an email
   *
   * Possible Errors:
   *
   * - InvalidArgument if email is incorrect format
   * - InvalidArgument if any of the linkedSchemes are in an error state
   * - AlreadyExists if the user with email already exists
   *
   * The following properties can be set during creation:
   * - linkedSchemes
   */
  AdminCreateUser(request: DeepPartial<AdminCreateUserRequest>, metadata?: grpc.Metadata): Promise<User>;
  /**
   * Deletes a user with given either an email or a sub
   *
   * Possible Errors:
   *
   * - InvalidArgument if email is supplied and incorrect format
   * - InvalidArgument if sub is supplied and incorrect format
   * - NotFound if the user doesn't exist
   */
  AdminDeleteUser(
    request: DeepPartial<AdminDeleteUserRequest>,
    metadata?: grpc.Metadata,
  ): Promise<AdminDeleteUserResponse>;
  /**
   * FetchAnonymizedVotePreferencesByScheme fetches anonymized vote preferences for the requested schemes
   *
   * Possible errors:
   * - InvalidArgument - if any of the request parameters are invalid
   */
  FetchAnonymizedVotePreferencesByScheme(
    request: DeepPartial<FetchAnonymizedVotePreferencesBySchemeRequest>,
    metadata?: grpc.Metadata,
  ): Promise<FetchAnonymizedVotePreferencesBySchemeResponse>;
  /**
   * FetchAnonymizedVoteCommentsByScheme fetches anonymized comments for the requested schemes
   *
   * Possible errors:
   * - InvalidArgument - if any of the request parameters are invalid
   */
  FetchAnonymizedVoteCommentsByScheme(
    request: DeepPartial<FetchAnonymizedVoteCommentsBySchemeRequest>,
    metadata?: grpc.Metadata,
  ): Promise<FetchAnonymizedVoteCommentsBySchemeResponse>;
  /** FetchPrivileges returns the logged in user privileges */
  FetchPrivileges(request: DeepPartial<FetchPrivilegesRequest>, metadata?: grpc.Metadata): Promise<Privileges>;
  /**
   * GetUser returns the logged in user
   *
   * Possible errors:
   * - Unauthenticated if there is not a valid user logged in
   */
  GetUser(request: DeepPartial<GetUserRequest>, metadata?: grpc.Metadata): Promise<User>;
  /**
   * List schemes lists the schemes available to the logged in user
   * TODO (TUM-1808) It is not currently paginated, the PageSize and PageToken parameters are currently ignored.
   * Results are ordered by habitat ID ascending, scheme title ascending
   */
  ListSchemes(request: DeepPartial<ListSchemesRequest>, metadata?: grpc.Metadata): Promise<ListSchemesResponse>;
  /**
   * SubmitVote cast by the given user. Will cast a vote on a proposal (or poll if EoW).
   * Possible errors:
   * - InvalidArgument - if any of the request parameters are invalid
   * - NotFound - if the ballot or scheme cannot be found
   * - PermissionDenied - if the user doesn't have permission to vote on the ballot
   */
  SubmitVote(request: DeepPartial<SubmitVoteRequest>, metadata?: grpc.Metadata): Promise<SubmitVoteResponse>;
  /**
   * SubmitBallotComment stores/updates the comment provided by the user for a specific ballot.
   * Possible errors:
   * - InvalidArgument - if any of the request parameters are invalid
   * - NotFound - if the ballot or scheme cannot be found
   * - PermissionDenied - if the user doesn't have permission to comment on the ballot
   */
  SubmitBallotComment(
    request: DeepPartial<SubmitBallotCommentRequest>,
    metadata?: grpc.Metadata,
  ): Promise<SubmitBallotCommentResponse>;
  /**
   * ListGeneralMeetings lists the general meetings available to the logged in user, to vote or comment on.
   * It is paginated.
   * Results are ordered by poll close time.
   *
   * Possible errors:
   * - InvalidArgument - if the scheme name is empty or invalid
   * - PermissionDenied - if the user does not have access to this scheme
   * - PermissionDenied - if the scheme does not have an investor
   */
  ListGeneralMeetings(
    request: DeepPartial<ListGeneralMeetingsRequest>,
    metadata?: grpc.Metadata,
  ): Promise<ListGeneralMeetingsResponse>;
  /**
   * GetGeneralMeeting returns the requested general meeting and its proposals, if available to the logged in user.
   *
   * Possible errors:
   * - InvalidArgument - if the scheme name is empty or invalid
   * - InvalidArgument - if the general meeting name is empty or invalid
   * - PermissionDenied - if the user does not have access to this scheme
   * - PermissionDenied - if the scheme does not have an investor
   * - NotFound - if the requested meeting ballot is not found
   */
  GetGeneralMeeting(
    request: DeepPartial<GetGeneralMeetingRequest>,
    metadata?: grpc.Metadata,
  ): Promise<GetGeneralMeetingResponse>;
  /**
   * ListFeatureFlags lists the current feature flags.
   * It will return the user's feature flag state if an override for that user is set.
   * It is paginated.
   * Results are ordered by key ascending.
   */
  ListFeatureFlags(
    request: DeepPartial<ListFeatureFlagsRequest>,
    metadata?: grpc.Metadata,
  ): Promise<ListFeatureFlagsResponse>;
  /**
   * AdminListFeatureFlags lists the current globally set feature flags.
   * It is paginated.
   * Results are ordered by key ascending.
   */
  AdminListFeatureFlags(
    request: DeepPartial<AdminListFeatureFlagsRequest>,
    metadata?: grpc.Metadata,
  ): Promise<ListFeatureFlagsResponse>;
  /**
   * AdminUpdateFeatureFlag updates the state of the given feature flag.
   *
   * Possible errors:
   * - InvalidArgument - if any of the request parameters are invalid.
   * - NotFound - if the feature flag does not exist.
   */
  AdminUpdateFeatureFlag(
    request: DeepPartial<AdminUpdateFeatureFlagRequest>,
    metadata?: grpc.Metadata,
  ): Promise<FeatureFlag>;
  /**
   * AdminCreateUserFeatureFlagOverride creates a feature flag override for a given user.
   *
   * Possible errors:
   * - InvalidArgument - if any of the request parameters are invalid.
   */
  AdminCreateUserFeatureFlagOverride(
    request: DeepPartial<AdminCreateUserFeatureFlagOverrideRequest>,
    metadata?: grpc.Metadata,
  ): Promise<FeatureFlag>;
  /**
   * AdminDeleteUserFeatureFlagOverride deletes a feature flag override for a given user.
   *
   * Possible errors:
   * - InvalidArgument - if any of the request parameters are invalid.
   */
  AdminDeleteUserFeatureFlagOverride(
    request: DeepPartial<AdminDeleteUserFeatureFlagOverrideRequest>,
    metadata?: grpc.Metadata,
  ): Promise<Empty>;
  /**
   * FetchInvestments retrieves the fund data.
   *
   * If the Scheme Name filter is provided, will return only the fund data for that Scheme,
   * otherwise will return fund data for all Schemes the current user is allowed to access.
   *
   * Possible errors:
   * - InvalidArgument - if any of the request parameters are invalid.
   * - PermissionDenied - if the user does not have access to this scheme.
   * - NotFound - if the scheme was not found.
   */
  FetchInvestments(
    request: DeepPartial<FetchInvestmentsRequest>,
    metadata?: grpc.Metadata,
  ): Promise<FetchInvestmentsResponse>;
  /**
   * ListFundManagers gives a paginated list of fund managers available to the logged in user.
   *
   * Possible errors:
   * - InvalidArgument - if any of the request parameters are invalid.
   */
  ListFundManagers(
    request: DeepPartial<ListFundManagersRequest>,
    metadata?: grpc.Metadata,
  ): Promise<ListFundManagersResponse>;
  /**
   * GetVotingPolicy retrieves the voting policy for the given Scheme.
   *
   * Possible errors:
   * - InvalidArgument - if any of the request parameters are invalid.
   * - PermissionDenied - if the user does not have access to this scheme.
   * - NotFound - if the scheme was not found.
   */
  GetVotingPolicy(
    request: DeepPartial<GetVotingPolicyRequest>,
    metadata?: grpc.Metadata,
  ): Promise<GetVotingPolicyResponse>;
  /**
   * AdminPutQuarterlyReport stores a quarterly report document.
   * Making another request with the same file name, year and quarter will result in the existent file being overridden.
   *
   * Possible errors:
   * - InvalidArgument - if any of the request parameters are invalid.
   * - NotFound - if the habitat or scheme was not found.
   */
  AdminPutQuarterlyReport(
    request: DeepPartial<AdminPutQuarterlyReportRequest>,
    metadata?: grpc.Metadata,
  ): Promise<QuarterlyReport>;
  /**
   * ListQuarterlyReports returns a list of (optionally filtered) quarterly reports, ordered by quarter descending (most recent first).
   * It is not paginated, as the expected number of quarterly reports is low.
   * Example of http usage  GET /stewardshipbff/v1/habitats/7a9567cd-af85-4011-9500-4bf273bcd39a/schemes/ff515089-c96a-4d6b-9d3a-e88fb4f68890/quarterlyReports?calendarQuarter.quarter=1,calendarQuarter.year=2024
   *
   * Possible errors:
   * - InvalidArgument - if any of the request parameters are invalid.
   * - PermissionDenied - if the user does not have access to this scheme.
   */
  ListQuarterlyReports(
    request: DeepPartial<ListQuarterlyReportsRequest>,
    metadata?: grpc.Metadata,
  ): Promise<ListQuarterlyReportsResponse>;
}

export class StewardshipBffClientImpl implements StewardshipBff {
  private readonly rpc: Rpc;

  constructor(rpc: Rpc) {
    this.rpc = rpc;
    this.GetFundManager = this.GetFundManager.bind(this);
    this.AdminListUsers = this.AdminListUsers.bind(this);
    this.AdminListDetailedUsers = this.AdminListDetailedUsers.bind(this);
    this.AdminUpdateUser = this.AdminUpdateUser.bind(this);
    this.AdminCreateUser = this.AdminCreateUser.bind(this);
    this.AdminDeleteUser = this.AdminDeleteUser.bind(this);
    this.FetchAnonymizedVotePreferencesByScheme = this.FetchAnonymizedVotePreferencesByScheme.bind(this);
    this.FetchAnonymizedVoteCommentsByScheme = this.FetchAnonymizedVoteCommentsByScheme.bind(this);
    this.FetchPrivileges = this.FetchPrivileges.bind(this);
    this.GetUser = this.GetUser.bind(this);
    this.ListSchemes = this.ListSchemes.bind(this);
    this.SubmitVote = this.SubmitVote.bind(this);
    this.SubmitBallotComment = this.SubmitBallotComment.bind(this);
    this.ListGeneralMeetings = this.ListGeneralMeetings.bind(this);
    this.GetGeneralMeeting = this.GetGeneralMeeting.bind(this);
    this.ListFeatureFlags = this.ListFeatureFlags.bind(this);
    this.AdminListFeatureFlags = this.AdminListFeatureFlags.bind(this);
    this.AdminUpdateFeatureFlag = this.AdminUpdateFeatureFlag.bind(this);
    this.AdminCreateUserFeatureFlagOverride = this.AdminCreateUserFeatureFlagOverride.bind(this);
    this.AdminDeleteUserFeatureFlagOverride = this.AdminDeleteUserFeatureFlagOverride.bind(this);
    this.FetchInvestments = this.FetchInvestments.bind(this);
    this.ListFundManagers = this.ListFundManagers.bind(this);
    this.GetVotingPolicy = this.GetVotingPolicy.bind(this);
    this.AdminPutQuarterlyReport = this.AdminPutQuarterlyReport.bind(this);
    this.ListQuarterlyReports = this.ListQuarterlyReports.bind(this);
  }

  GetFundManager(
    request: DeepPartial<GetFundManagerRequest>,
    metadata?: grpc.Metadata,
  ): Promise<GetFundManagerResponse> {
    return this.rpc.unary(StewardshipBffGetFundManagerDesc, GetFundManagerRequest.fromPartial(request), metadata);
  }

  AdminListUsers(
    request: DeepPartial<AdminListUsersRequest>,
    metadata?: grpc.Metadata,
  ): Promise<AdminListUsersResponse> {
    return this.rpc.unary(StewardshipBffAdminListUsersDesc, AdminListUsersRequest.fromPartial(request), metadata);
  }

  AdminListDetailedUsers(
    request: DeepPartial<AdminListDetailedUsersRequest>,
    metadata?: grpc.Metadata,
  ): Promise<AdminListDetailedUsersResponse> {
    return this.rpc.unary(
      StewardshipBffAdminListDetailedUsersDesc,
      AdminListDetailedUsersRequest.fromPartial(request),
      metadata,
    );
  }

  AdminUpdateUser(request: DeepPartial<AdminUpdateUserRequest>, metadata?: grpc.Metadata): Promise<User> {
    return this.rpc.unary(StewardshipBffAdminUpdateUserDesc, AdminUpdateUserRequest.fromPartial(request), metadata);
  }

  AdminCreateUser(request: DeepPartial<AdminCreateUserRequest>, metadata?: grpc.Metadata): Promise<User> {
    return this.rpc.unary(StewardshipBffAdminCreateUserDesc, AdminCreateUserRequest.fromPartial(request), metadata);
  }

  AdminDeleteUser(
    request: DeepPartial<AdminDeleteUserRequest>,
    metadata?: grpc.Metadata,
  ): Promise<AdminDeleteUserResponse> {
    return this.rpc.unary(StewardshipBffAdminDeleteUserDesc, AdminDeleteUserRequest.fromPartial(request), metadata);
  }

  FetchAnonymizedVotePreferencesByScheme(
    request: DeepPartial<FetchAnonymizedVotePreferencesBySchemeRequest>,
    metadata?: grpc.Metadata,
  ): Promise<FetchAnonymizedVotePreferencesBySchemeResponse> {
    return this.rpc.unary(
      StewardshipBffFetchAnonymizedVotePreferencesBySchemeDesc,
      FetchAnonymizedVotePreferencesBySchemeRequest.fromPartial(request),
      metadata,
    );
  }

  FetchAnonymizedVoteCommentsByScheme(
    request: DeepPartial<FetchAnonymizedVoteCommentsBySchemeRequest>,
    metadata?: grpc.Metadata,
  ): Promise<FetchAnonymizedVoteCommentsBySchemeResponse> {
    return this.rpc.unary(
      StewardshipBffFetchAnonymizedVoteCommentsBySchemeDesc,
      FetchAnonymizedVoteCommentsBySchemeRequest.fromPartial(request),
      metadata,
    );
  }

  FetchPrivileges(request: DeepPartial<FetchPrivilegesRequest>, metadata?: grpc.Metadata): Promise<Privileges> {
    return this.rpc.unary(StewardshipBffFetchPrivilegesDesc, FetchPrivilegesRequest.fromPartial(request), metadata);
  }

  GetUser(request: DeepPartial<GetUserRequest>, metadata?: grpc.Metadata): Promise<User> {
    return this.rpc.unary(StewardshipBffGetUserDesc, GetUserRequest.fromPartial(request), metadata);
  }

  ListSchemes(request: DeepPartial<ListSchemesRequest>, metadata?: grpc.Metadata): Promise<ListSchemesResponse> {
    return this.rpc.unary(StewardshipBffListSchemesDesc, ListSchemesRequest.fromPartial(request), metadata);
  }

  SubmitVote(request: DeepPartial<SubmitVoteRequest>, metadata?: grpc.Metadata): Promise<SubmitVoteResponse> {
    return this.rpc.unary(StewardshipBffSubmitVoteDesc, SubmitVoteRequest.fromPartial(request), metadata);
  }

  SubmitBallotComment(
    request: DeepPartial<SubmitBallotCommentRequest>,
    metadata?: grpc.Metadata,
  ): Promise<SubmitBallotCommentResponse> {
    return this.rpc.unary(
      StewardshipBffSubmitBallotCommentDesc,
      SubmitBallotCommentRequest.fromPartial(request),
      metadata,
    );
  }

  ListGeneralMeetings(
    request: DeepPartial<ListGeneralMeetingsRequest>,
    metadata?: grpc.Metadata,
  ): Promise<ListGeneralMeetingsResponse> {
    return this.rpc.unary(
      StewardshipBffListGeneralMeetingsDesc,
      ListGeneralMeetingsRequest.fromPartial(request),
      metadata,
    );
  }

  GetGeneralMeeting(
    request: DeepPartial<GetGeneralMeetingRequest>,
    metadata?: grpc.Metadata,
  ): Promise<GetGeneralMeetingResponse> {
    return this.rpc.unary(StewardshipBffGetGeneralMeetingDesc, GetGeneralMeetingRequest.fromPartial(request), metadata);
  }

  ListFeatureFlags(
    request: DeepPartial<ListFeatureFlagsRequest>,
    metadata?: grpc.Metadata,
  ): Promise<ListFeatureFlagsResponse> {
    return this.rpc.unary(StewardshipBffListFeatureFlagsDesc, ListFeatureFlagsRequest.fromPartial(request), metadata);
  }

  AdminListFeatureFlags(
    request: DeepPartial<AdminListFeatureFlagsRequest>,
    metadata?: grpc.Metadata,
  ): Promise<ListFeatureFlagsResponse> {
    return this.rpc.unary(
      StewardshipBffAdminListFeatureFlagsDesc,
      AdminListFeatureFlagsRequest.fromPartial(request),
      metadata,
    );
  }

  AdminUpdateFeatureFlag(
    request: DeepPartial<AdminUpdateFeatureFlagRequest>,
    metadata?: grpc.Metadata,
  ): Promise<FeatureFlag> {
    return this.rpc.unary(
      StewardshipBffAdminUpdateFeatureFlagDesc,
      AdminUpdateFeatureFlagRequest.fromPartial(request),
      metadata,
    );
  }

  AdminCreateUserFeatureFlagOverride(
    request: DeepPartial<AdminCreateUserFeatureFlagOverrideRequest>,
    metadata?: grpc.Metadata,
  ): Promise<FeatureFlag> {
    return this.rpc.unary(
      StewardshipBffAdminCreateUserFeatureFlagOverrideDesc,
      AdminCreateUserFeatureFlagOverrideRequest.fromPartial(request),
      metadata,
    );
  }

  AdminDeleteUserFeatureFlagOverride(
    request: DeepPartial<AdminDeleteUserFeatureFlagOverrideRequest>,
    metadata?: grpc.Metadata,
  ): Promise<Empty> {
    return this.rpc.unary(
      StewardshipBffAdminDeleteUserFeatureFlagOverrideDesc,
      AdminDeleteUserFeatureFlagOverrideRequest.fromPartial(request),
      metadata,
    );
  }

  FetchInvestments(
    request: DeepPartial<FetchInvestmentsRequest>,
    metadata?: grpc.Metadata,
  ): Promise<FetchInvestmentsResponse> {
    return this.rpc.unary(StewardshipBffFetchInvestmentsDesc, FetchInvestmentsRequest.fromPartial(request), metadata);
  }

  ListFundManagers(
    request: DeepPartial<ListFundManagersRequest>,
    metadata?: grpc.Metadata,
  ): Promise<ListFundManagersResponse> {
    return this.rpc.unary(StewardshipBffListFundManagersDesc, ListFundManagersRequest.fromPartial(request), metadata);
  }

  GetVotingPolicy(
    request: DeepPartial<GetVotingPolicyRequest>,
    metadata?: grpc.Metadata,
  ): Promise<GetVotingPolicyResponse> {
    return this.rpc.unary(StewardshipBffGetVotingPolicyDesc, GetVotingPolicyRequest.fromPartial(request), metadata);
  }

  AdminPutQuarterlyReport(
    request: DeepPartial<AdminPutQuarterlyReportRequest>,
    metadata?: grpc.Metadata,
  ): Promise<QuarterlyReport> {
    return this.rpc.unary(
      StewardshipBffAdminPutQuarterlyReportDesc,
      AdminPutQuarterlyReportRequest.fromPartial(request),
      metadata,
    );
  }

  ListQuarterlyReports(
    request: DeepPartial<ListQuarterlyReportsRequest>,
    metadata?: grpc.Metadata,
  ): Promise<ListQuarterlyReportsResponse> {
    return this.rpc.unary(
      StewardshipBffListQuarterlyReportsDesc,
      ListQuarterlyReportsRequest.fromPartial(request),
      metadata,
    );
  }
}

export const StewardshipBffDesc = { serviceName: "tumelo.stewardshipbff.v1.StewardshipBff" };

export const StewardshipBffGetFundManagerDesc: UnaryMethodDefinitionish = {
  methodName: "GetFundManager",
  service: StewardshipBffDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return GetFundManagerRequest.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      const value = GetFundManagerResponse.decode(data);
      return {
        ...value,
        toObject() {
          return value;
        },
      };
    },
  } as any,
};

export const StewardshipBffAdminListUsersDesc: UnaryMethodDefinitionish = {
  methodName: "AdminListUsers",
  service: StewardshipBffDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return AdminListUsersRequest.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      const value = AdminListUsersResponse.decode(data);
      return {
        ...value,
        toObject() {
          return value;
        },
      };
    },
  } as any,
};

export const StewardshipBffAdminListDetailedUsersDesc: UnaryMethodDefinitionish = {
  methodName: "AdminListDetailedUsers",
  service: StewardshipBffDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return AdminListDetailedUsersRequest.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      const value = AdminListDetailedUsersResponse.decode(data);
      return {
        ...value,
        toObject() {
          return value;
        },
      };
    },
  } as any,
};

export const StewardshipBffAdminUpdateUserDesc: UnaryMethodDefinitionish = {
  methodName: "AdminUpdateUser",
  service: StewardshipBffDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return AdminUpdateUserRequest.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      const value = User.decode(data);
      return {
        ...value,
        toObject() {
          return value;
        },
      };
    },
  } as any,
};

export const StewardshipBffAdminCreateUserDesc: UnaryMethodDefinitionish = {
  methodName: "AdminCreateUser",
  service: StewardshipBffDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return AdminCreateUserRequest.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      const value = User.decode(data);
      return {
        ...value,
        toObject() {
          return value;
        },
      };
    },
  } as any,
};

export const StewardshipBffAdminDeleteUserDesc: UnaryMethodDefinitionish = {
  methodName: "AdminDeleteUser",
  service: StewardshipBffDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return AdminDeleteUserRequest.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      const value = AdminDeleteUserResponse.decode(data);
      return {
        ...value,
        toObject() {
          return value;
        },
      };
    },
  } as any,
};

export const StewardshipBffFetchAnonymizedVotePreferencesBySchemeDesc: UnaryMethodDefinitionish = {
  methodName: "FetchAnonymizedVotePreferencesByScheme",
  service: StewardshipBffDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return FetchAnonymizedVotePreferencesBySchemeRequest.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      const value = FetchAnonymizedVotePreferencesBySchemeResponse.decode(data);
      return {
        ...value,
        toObject() {
          return value;
        },
      };
    },
  } as any,
};

export const StewardshipBffFetchAnonymizedVoteCommentsBySchemeDesc: UnaryMethodDefinitionish = {
  methodName: "FetchAnonymizedVoteCommentsByScheme",
  service: StewardshipBffDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return FetchAnonymizedVoteCommentsBySchemeRequest.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      const value = FetchAnonymizedVoteCommentsBySchemeResponse.decode(data);
      return {
        ...value,
        toObject() {
          return value;
        },
      };
    },
  } as any,
};

export const StewardshipBffFetchPrivilegesDesc: UnaryMethodDefinitionish = {
  methodName: "FetchPrivileges",
  service: StewardshipBffDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return FetchPrivilegesRequest.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      const value = Privileges.decode(data);
      return {
        ...value,
        toObject() {
          return value;
        },
      };
    },
  } as any,
};

export const StewardshipBffGetUserDesc: UnaryMethodDefinitionish = {
  methodName: "GetUser",
  service: StewardshipBffDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return GetUserRequest.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      const value = User.decode(data);
      return {
        ...value,
        toObject() {
          return value;
        },
      };
    },
  } as any,
};

export const StewardshipBffListSchemesDesc: UnaryMethodDefinitionish = {
  methodName: "ListSchemes",
  service: StewardshipBffDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return ListSchemesRequest.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      const value = ListSchemesResponse.decode(data);
      return {
        ...value,
        toObject() {
          return value;
        },
      };
    },
  } as any,
};

export const StewardshipBffSubmitVoteDesc: UnaryMethodDefinitionish = {
  methodName: "SubmitVote",
  service: StewardshipBffDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return SubmitVoteRequest.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      const value = SubmitVoteResponse.decode(data);
      return {
        ...value,
        toObject() {
          return value;
        },
      };
    },
  } as any,
};

export const StewardshipBffSubmitBallotCommentDesc: UnaryMethodDefinitionish = {
  methodName: "SubmitBallotComment",
  service: StewardshipBffDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return SubmitBallotCommentRequest.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      const value = SubmitBallotCommentResponse.decode(data);
      return {
        ...value,
        toObject() {
          return value;
        },
      };
    },
  } as any,
};

export const StewardshipBffListGeneralMeetingsDesc: UnaryMethodDefinitionish = {
  methodName: "ListGeneralMeetings",
  service: StewardshipBffDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return ListGeneralMeetingsRequest.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      const value = ListGeneralMeetingsResponse.decode(data);
      return {
        ...value,
        toObject() {
          return value;
        },
      };
    },
  } as any,
};

export const StewardshipBffGetGeneralMeetingDesc: UnaryMethodDefinitionish = {
  methodName: "GetGeneralMeeting",
  service: StewardshipBffDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return GetGeneralMeetingRequest.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      const value = GetGeneralMeetingResponse.decode(data);
      return {
        ...value,
        toObject() {
          return value;
        },
      };
    },
  } as any,
};

export const StewardshipBffListFeatureFlagsDesc: UnaryMethodDefinitionish = {
  methodName: "ListFeatureFlags",
  service: StewardshipBffDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return ListFeatureFlagsRequest.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      const value = ListFeatureFlagsResponse.decode(data);
      return {
        ...value,
        toObject() {
          return value;
        },
      };
    },
  } as any,
};

export const StewardshipBffAdminListFeatureFlagsDesc: UnaryMethodDefinitionish = {
  methodName: "AdminListFeatureFlags",
  service: StewardshipBffDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return AdminListFeatureFlagsRequest.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      const value = ListFeatureFlagsResponse.decode(data);
      return {
        ...value,
        toObject() {
          return value;
        },
      };
    },
  } as any,
};

export const StewardshipBffAdminUpdateFeatureFlagDesc: UnaryMethodDefinitionish = {
  methodName: "AdminUpdateFeatureFlag",
  service: StewardshipBffDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return AdminUpdateFeatureFlagRequest.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      const value = FeatureFlag.decode(data);
      return {
        ...value,
        toObject() {
          return value;
        },
      };
    },
  } as any,
};

export const StewardshipBffAdminCreateUserFeatureFlagOverrideDesc: UnaryMethodDefinitionish = {
  methodName: "AdminCreateUserFeatureFlagOverride",
  service: StewardshipBffDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return AdminCreateUserFeatureFlagOverrideRequest.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      const value = FeatureFlag.decode(data);
      return {
        ...value,
        toObject() {
          return value;
        },
      };
    },
  } as any,
};

export const StewardshipBffAdminDeleteUserFeatureFlagOverrideDesc: UnaryMethodDefinitionish = {
  methodName: "AdminDeleteUserFeatureFlagOverride",
  service: StewardshipBffDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return AdminDeleteUserFeatureFlagOverrideRequest.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      const value = Empty.decode(data);
      return {
        ...value,
        toObject() {
          return value;
        },
      };
    },
  } as any,
};

export const StewardshipBffFetchInvestmentsDesc: UnaryMethodDefinitionish = {
  methodName: "FetchInvestments",
  service: StewardshipBffDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return FetchInvestmentsRequest.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      const value = FetchInvestmentsResponse.decode(data);
      return {
        ...value,
        toObject() {
          return value;
        },
      };
    },
  } as any,
};

export const StewardshipBffListFundManagersDesc: UnaryMethodDefinitionish = {
  methodName: "ListFundManagers",
  service: StewardshipBffDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return ListFundManagersRequest.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      const value = ListFundManagersResponse.decode(data);
      return {
        ...value,
        toObject() {
          return value;
        },
      };
    },
  } as any,
};

export const StewardshipBffGetVotingPolicyDesc: UnaryMethodDefinitionish = {
  methodName: "GetVotingPolicy",
  service: StewardshipBffDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return GetVotingPolicyRequest.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      const value = GetVotingPolicyResponse.decode(data);
      return {
        ...value,
        toObject() {
          return value;
        },
      };
    },
  } as any,
};

export const StewardshipBffAdminPutQuarterlyReportDesc: UnaryMethodDefinitionish = {
  methodName: "AdminPutQuarterlyReport",
  service: StewardshipBffDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return AdminPutQuarterlyReportRequest.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      const value = QuarterlyReport.decode(data);
      return {
        ...value,
        toObject() {
          return value;
        },
      };
    },
  } as any,
};

export const StewardshipBffListQuarterlyReportsDesc: UnaryMethodDefinitionish = {
  methodName: "ListQuarterlyReports",
  service: StewardshipBffDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return ListQuarterlyReportsRequest.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      const value = ListQuarterlyReportsResponse.decode(data);
      return {
        ...value,
        toObject() {
          return value;
        },
      };
    },
  } as any,
};

interface UnaryMethodDefinitionishR extends grpc.UnaryMethodDefinition<any, any> {
  requestStream: any;
  responseStream: any;
}

type UnaryMethodDefinitionish = UnaryMethodDefinitionishR;

interface Rpc {
  unary<T extends UnaryMethodDefinitionish>(
    methodDesc: T,
    request: any,
    metadata: grpc.Metadata | undefined,
  ): Promise<any>;
}

export class GrpcWebImpl {
  private host: string;
  private options: {
    transport?: grpc.TransportFactory;

    debug?: boolean;
    metadata?: grpc.Metadata;
    upStreamRetryCodes?: number[];
  };

  constructor(
    host: string,
    options: {
      transport?: grpc.TransportFactory;

      debug?: boolean;
      metadata?: grpc.Metadata;
      upStreamRetryCodes?: number[];
    },
  ) {
    this.host = host;
    this.options = options;
  }

  unary<T extends UnaryMethodDefinitionish>(
    methodDesc: T,
    _request: any,
    metadata: grpc.Metadata | undefined,
  ): Promise<any> {
    const request = { ..._request, ...methodDesc.requestType };
    const maybeCombinedMetadata = metadata && this.options.metadata
      ? new BrowserHeaders({ ...this.options?.metadata.headersMap, ...metadata?.headersMap })
      : metadata ?? this.options.metadata;
    return new Promise((resolve, reject) => {
      grpc.unary(methodDesc, {
        request,
        host: this.host,
        metadata: maybeCombinedMetadata ?? {},
        ...(this.options.transport !== undefined ? { transport: this.options.transport } : {}),
        debug: this.options.debug ?? false,
        onEnd: function (response) {
          if (response.status === grpc.Code.OK) {
            resolve(response.message!.toObject());
          } else {
            const err = new GrpcWebError(response.statusMessage, response.status, response.trailers);
            reject(err);
          }
        },
      });
    });
  }
}

type Builtin = Date | Function | Uint8Array | string | number | boolean | undefined;

export type DeepPartial<T> = T extends Builtin ? T
  : T extends Array<infer U> ? Array<DeepPartial<U>> : T extends ReadonlyArray<infer U> ? ReadonlyArray<DeepPartial<U>>
  : T extends {} ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;

type KeysOfUnion<T> = T extends T ? keyof T : never;
export type Exact<P, I extends P> = P extends Builtin ? P
  : P & { [K in keyof P]: Exact<P[K], I[K]> } & { [K in Exclude<keyof I, KeysOfUnion<P>>]: never };

function toTimestamp(date: Date): Timestamp {
  const seconds = date.getTime() / 1_000;
  const nanos = (date.getTime() % 1_000) * 1_000_000;
  return { seconds, nanos };
}

function fromTimestamp(t: Timestamp): Date {
  let millis = (t.seconds || 0) * 1_000;
  millis += (t.nanos || 0) / 1_000_000;
  return new globalThis.Date(millis);
}

function fromJsonTimestamp(o: any): Date {
  if (o instanceof globalThis.Date) {
    return o;
  } else if (typeof o === "string") {
    return new globalThis.Date(o);
  } else {
    return fromTimestamp(Timestamp.fromJSON(o));
  }
}

function isObject(value: any): boolean {
  return typeof value === "object" && value !== null;
}

function isSet(value: any): boolean {
  return value !== null && value !== undefined;
}

export class GrpcWebError extends globalThis.Error {
  constructor(message: string, public code: grpc.Code, public metadata: grpc.Metadata) {
    super(message);
  }
}
