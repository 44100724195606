/*eslint-disable*/
// @ts-nocheck
/* eslint-disable */
import _m0 from "protobufjs/minimal";
import { Timestamp } from "../../../google/protobuf/timestamp";

export const protobufPackage = "tumelo.transparency.v1";

/** GeneralMeeting describes a general meeting */
export interface GeneralMeeting {
  /**
   * OUTPUT ONLY is a unique identifier for a general meeting
   * It is of format "organizations/{uuid}/generalMeetings/{uuid}"
   */
  name: string;
  /**
   * REQUIRED organization_name is the name of the organization that this general meeting is for
   * in the format "organizations/{uuid}""
   * TODO remove this duplication
   */
  organizationName: string;
  /** REQUIRED type of the general meeting */
  type: GeneralMeeting_Type;
  /** REQUIRED meeting_date is the date of the general meeting */
  meetingDate:
    | Date
    | undefined;
  /** GENERATED the time the general meeting was created */
  createTime:
    | Date
    | undefined;
  /** GENERATED the time the general meeting was last updated */
  updateTime:
    | Date
    | undefined;
  /** OPTIONAL the time the general meeting became invalid */
  invalidTime:
    | Date
    | undefined;
  /** OPTIONAL the time the general meeting was announced */
  announcementTime:
    | Date
    | undefined;
  /** OPTIONAL the edi event ID the general meeting as presented in existing EDI World Wide Corporate Actions service */
  ediEventId: string;
  /** OPTIONAL url of the official general meeting announcement */
  announcementUrl: string;
  /** OPTIONAL url links to a page containing the proxy notice of the general meeting */
  proxyNoticeUrl: string;
  /** OPTIONAL url of the official results for the general meeting */
  resultsUrl: string;
  /** OPTIONAL the record date for the general meeting */
  recordDate:
    | Date
    | undefined;
  /** OPTIONAL the record date for the general meeting as reported by EDI */
  ediRecordDate:
    | Date
    | undefined;
  /** GENERATED the time voting was enabled for the general meeting. Null if voting is not enabled. */
  votingEnabledTime:
    | Date
    | undefined;
  /** OPTIONAL set to true if voting should be enabled for this general meeting. */
  votingEnabled: boolean;
  /** OPTIONAL the proxy submission deadlines for the general meeting */
  proxySubmission: ProxySubmission | undefined;
}

/** Type is the enumerated set of possible general meeting types */
export enum GeneralMeeting_Type {
  GENERAL_MEETING_TYPE_INVALID = 0,
  GENERAL_MEETING_TYPE_ANNUAL = 1,
  GENERAL_MEETING_TYPE_EXTRAORDINARY = 2,
  GENERAL_MEETING_TYPE_SPECIAL = 3,
  GENERAL_MEETING_TYPE_COURT_ORDERED = 4,
  GENERAL_MEETING_TYPE_GENERAL = 5,
  GENERAL_MEETING_TYPE_POSTAL_BALLOT = 6,
  GENERAL_MEETING_TYPE_BOND_HOLDER = 7,
  UNRECOGNIZED = -1,
}

export function generalMeeting_TypeFromJSON(object: any): GeneralMeeting_Type {
  switch (object) {
    case 0:
    case "GENERAL_MEETING_TYPE_INVALID":
      return GeneralMeeting_Type.GENERAL_MEETING_TYPE_INVALID;
    case 1:
    case "GENERAL_MEETING_TYPE_ANNUAL":
      return GeneralMeeting_Type.GENERAL_MEETING_TYPE_ANNUAL;
    case 2:
    case "GENERAL_MEETING_TYPE_EXTRAORDINARY":
      return GeneralMeeting_Type.GENERAL_MEETING_TYPE_EXTRAORDINARY;
    case 3:
    case "GENERAL_MEETING_TYPE_SPECIAL":
      return GeneralMeeting_Type.GENERAL_MEETING_TYPE_SPECIAL;
    case 4:
    case "GENERAL_MEETING_TYPE_COURT_ORDERED":
      return GeneralMeeting_Type.GENERAL_MEETING_TYPE_COURT_ORDERED;
    case 5:
    case "GENERAL_MEETING_TYPE_GENERAL":
      return GeneralMeeting_Type.GENERAL_MEETING_TYPE_GENERAL;
    case 6:
    case "GENERAL_MEETING_TYPE_POSTAL_BALLOT":
      return GeneralMeeting_Type.GENERAL_MEETING_TYPE_POSTAL_BALLOT;
    case 7:
    case "GENERAL_MEETING_TYPE_BOND_HOLDER":
      return GeneralMeeting_Type.GENERAL_MEETING_TYPE_BOND_HOLDER;
    case -1:
    case "UNRECOGNIZED":
    default:
      return GeneralMeeting_Type.UNRECOGNIZED;
  }
}

export function generalMeeting_TypeToJSON(object: GeneralMeeting_Type): string {
  switch (object) {
    case GeneralMeeting_Type.GENERAL_MEETING_TYPE_INVALID:
      return "GENERAL_MEETING_TYPE_INVALID";
    case GeneralMeeting_Type.GENERAL_MEETING_TYPE_ANNUAL:
      return "GENERAL_MEETING_TYPE_ANNUAL";
    case GeneralMeeting_Type.GENERAL_MEETING_TYPE_EXTRAORDINARY:
      return "GENERAL_MEETING_TYPE_EXTRAORDINARY";
    case GeneralMeeting_Type.GENERAL_MEETING_TYPE_SPECIAL:
      return "GENERAL_MEETING_TYPE_SPECIAL";
    case GeneralMeeting_Type.GENERAL_MEETING_TYPE_COURT_ORDERED:
      return "GENERAL_MEETING_TYPE_COURT_ORDERED";
    case GeneralMeeting_Type.GENERAL_MEETING_TYPE_GENERAL:
      return "GENERAL_MEETING_TYPE_GENERAL";
    case GeneralMeeting_Type.GENERAL_MEETING_TYPE_POSTAL_BALLOT:
      return "GENERAL_MEETING_TYPE_POSTAL_BALLOT";
    case GeneralMeeting_Type.GENERAL_MEETING_TYPE_BOND_HOLDER:
      return "GENERAL_MEETING_TYPE_BOND_HOLDER";
    case GeneralMeeting_Type.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

/** ProxySubmission records proxy submission information for different proxy itermediaries */
export interface ProxySubmission {
  /** OPTIONAL votes for this general meeting must be submitted to Broadridge's Proxy Edge by this time in order to guarantee they will be counted */
  proxyEdgeDeadline: Date | undefined;
}

function createBaseGeneralMeeting(): GeneralMeeting {
  return {
    name: "",
    organizationName: "",
    type: 0,
    meetingDate: undefined,
    createTime: undefined,
    updateTime: undefined,
    invalidTime: undefined,
    announcementTime: undefined,
    ediEventId: "",
    announcementUrl: "",
    proxyNoticeUrl: "",
    resultsUrl: "",
    recordDate: undefined,
    ediRecordDate: undefined,
    votingEnabledTime: undefined,
    votingEnabled: false,
    proxySubmission: undefined,
  };
}

export const GeneralMeeting = {
  encode(message: GeneralMeeting, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.name !== "") {
      writer.uint32(10).string(message.name);
    }
    if (message.organizationName !== "") {
      writer.uint32(26).string(message.organizationName);
    }
    if (message.type !== 0) {
      writer.uint32(32).int32(message.type);
    }
    if (message.meetingDate !== undefined) {
      Timestamp.encode(toTimestamp(message.meetingDate), writer.uint32(50).fork()).ldelim();
    }
    if (message.createTime !== undefined) {
      Timestamp.encode(toTimestamp(message.createTime), writer.uint32(58).fork()).ldelim();
    }
    if (message.updateTime !== undefined) {
      Timestamp.encode(toTimestamp(message.updateTime), writer.uint32(66).fork()).ldelim();
    }
    if (message.invalidTime !== undefined) {
      Timestamp.encode(toTimestamp(message.invalidTime), writer.uint32(130).fork()).ldelim();
    }
    if (message.announcementTime !== undefined) {
      Timestamp.encode(toTimestamp(message.announcementTime), writer.uint32(74).fork()).ldelim();
    }
    if (message.ediEventId !== "") {
      writer.uint32(82).string(message.ediEventId);
    }
    if (message.announcementUrl !== "") {
      writer.uint32(90).string(message.announcementUrl);
    }
    if (message.proxyNoticeUrl !== "") {
      writer.uint32(98).string(message.proxyNoticeUrl);
    }
    if (message.resultsUrl !== "") {
      writer.uint32(106).string(message.resultsUrl);
    }
    if (message.recordDate !== undefined) {
      Timestamp.encode(toTimestamp(message.recordDate), writer.uint32(114).fork()).ldelim();
    }
    if (message.ediRecordDate !== undefined) {
      Timestamp.encode(toTimestamp(message.ediRecordDate), writer.uint32(138).fork()).ldelim();
    }
    if (message.votingEnabledTime !== undefined) {
      Timestamp.encode(toTimestamp(message.votingEnabledTime), writer.uint32(146).fork()).ldelim();
    }
    if (message.votingEnabled === true) {
      writer.uint32(152).bool(message.votingEnabled);
    }
    if (message.proxySubmission !== undefined) {
      ProxySubmission.encode(message.proxySubmission, writer.uint32(122).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GeneralMeeting {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGeneralMeeting();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.name = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.organizationName = reader.string();
          continue;
        case 4:
          if (tag !== 32) {
            break;
          }

          message.type = reader.int32() as any;
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.meetingDate = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          continue;
        case 7:
          if (tag !== 58) {
            break;
          }

          message.createTime = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          continue;
        case 8:
          if (tag !== 66) {
            break;
          }

          message.updateTime = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          continue;
        case 16:
          if (tag !== 130) {
            break;
          }

          message.invalidTime = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          continue;
        case 9:
          if (tag !== 74) {
            break;
          }

          message.announcementTime = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          continue;
        case 10:
          if (tag !== 82) {
            break;
          }

          message.ediEventId = reader.string();
          continue;
        case 11:
          if (tag !== 90) {
            break;
          }

          message.announcementUrl = reader.string();
          continue;
        case 12:
          if (tag !== 98) {
            break;
          }

          message.proxyNoticeUrl = reader.string();
          continue;
        case 13:
          if (tag !== 106) {
            break;
          }

          message.resultsUrl = reader.string();
          continue;
        case 14:
          if (tag !== 114) {
            break;
          }

          message.recordDate = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          continue;
        case 17:
          if (tag !== 138) {
            break;
          }

          message.ediRecordDate = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          continue;
        case 18:
          if (tag !== 146) {
            break;
          }

          message.votingEnabledTime = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          continue;
        case 19:
          if (tag !== 152) {
            break;
          }

          message.votingEnabled = reader.bool();
          continue;
        case 15:
          if (tag !== 122) {
            break;
          }

          message.proxySubmission = ProxySubmission.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): GeneralMeeting {
    return {
      name: isSet(object.name) ? String(object.name) : "",
      organizationName: isSet(object.organizationName) ? String(object.organizationName) : "",
      type: isSet(object.type) ? generalMeeting_TypeFromJSON(object.type) : 0,
      meetingDate: isSet(object.meetingDate) ? fromJsonTimestamp(object.meetingDate) : undefined,
      createTime: isSet(object.createTime) ? fromJsonTimestamp(object.createTime) : undefined,
      updateTime: isSet(object.updateTime) ? fromJsonTimestamp(object.updateTime) : undefined,
      invalidTime: isSet(object.invalidTime) ? fromJsonTimestamp(object.invalidTime) : undefined,
      announcementTime: isSet(object.announcementTime) ? fromJsonTimestamp(object.announcementTime) : undefined,
      ediEventId: isSet(object.ediEventId) ? String(object.ediEventId) : "",
      announcementUrl: isSet(object.announcementUrl) ? String(object.announcementUrl) : "",
      proxyNoticeUrl: isSet(object.proxyNoticeUrl) ? String(object.proxyNoticeUrl) : "",
      resultsUrl: isSet(object.resultsUrl) ? String(object.resultsUrl) : "",
      recordDate: isSet(object.recordDate) ? fromJsonTimestamp(object.recordDate) : undefined,
      ediRecordDate: isSet(object.ediRecordDate) ? fromJsonTimestamp(object.ediRecordDate) : undefined,
      votingEnabledTime: isSet(object.votingEnabledTime) ? fromJsonTimestamp(object.votingEnabledTime) : undefined,
      votingEnabled: isSet(object.votingEnabled) ? Boolean(object.votingEnabled) : false,
      proxySubmission: isSet(object.proxySubmission) ? ProxySubmission.fromJSON(object.proxySubmission) : undefined,
    };
  },

  toJSON(message: GeneralMeeting): unknown {
    const obj: any = {};
    if (message.name !== "") {
      obj.name = message.name;
    }
    if (message.organizationName !== "") {
      obj.organizationName = message.organizationName;
    }
    if (message.type !== 0) {
      obj.type = generalMeeting_TypeToJSON(message.type);
    }
    if (message.meetingDate !== undefined) {
      obj.meetingDate = message.meetingDate.toISOString();
    }
    if (message.createTime !== undefined) {
      obj.createTime = message.createTime.toISOString();
    }
    if (message.updateTime !== undefined) {
      obj.updateTime = message.updateTime.toISOString();
    }
    if (message.invalidTime !== undefined) {
      obj.invalidTime = message.invalidTime.toISOString();
    }
    if (message.announcementTime !== undefined) {
      obj.announcementTime = message.announcementTime.toISOString();
    }
    if (message.ediEventId !== "") {
      obj.ediEventId = message.ediEventId;
    }
    if (message.announcementUrl !== "") {
      obj.announcementUrl = message.announcementUrl;
    }
    if (message.proxyNoticeUrl !== "") {
      obj.proxyNoticeUrl = message.proxyNoticeUrl;
    }
    if (message.resultsUrl !== "") {
      obj.resultsUrl = message.resultsUrl;
    }
    if (message.recordDate !== undefined) {
      obj.recordDate = message.recordDate.toISOString();
    }
    if (message.ediRecordDate !== undefined) {
      obj.ediRecordDate = message.ediRecordDate.toISOString();
    }
    if (message.votingEnabledTime !== undefined) {
      obj.votingEnabledTime = message.votingEnabledTime.toISOString();
    }
    if (message.votingEnabled === true) {
      obj.votingEnabled = message.votingEnabled;
    }
    if (message.proxySubmission !== undefined) {
      obj.proxySubmission = ProxySubmission.toJSON(message.proxySubmission);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<GeneralMeeting>, I>>(base?: I): GeneralMeeting {
    return GeneralMeeting.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<GeneralMeeting>, I>>(object: I): GeneralMeeting {
    const message = createBaseGeneralMeeting();
    message.name = object.name ?? "";
    message.organizationName = object.organizationName ?? "";
    message.type = object.type ?? 0;
    message.meetingDate = object.meetingDate ?? undefined;
    message.createTime = object.createTime ?? undefined;
    message.updateTime = object.updateTime ?? undefined;
    message.invalidTime = object.invalidTime ?? undefined;
    message.announcementTime = object.announcementTime ?? undefined;
    message.ediEventId = object.ediEventId ?? "";
    message.announcementUrl = object.announcementUrl ?? "";
    message.proxyNoticeUrl = object.proxyNoticeUrl ?? "";
    message.resultsUrl = object.resultsUrl ?? "";
    message.recordDate = object.recordDate ?? undefined;
    message.ediRecordDate = object.ediRecordDate ?? undefined;
    message.votingEnabledTime = object.votingEnabledTime ?? undefined;
    message.votingEnabled = object.votingEnabled ?? false;
    message.proxySubmission = (object.proxySubmission !== undefined && object.proxySubmission !== null)
      ? ProxySubmission.fromPartial(object.proxySubmission)
      : undefined;
    return message;
  },
};

function createBaseProxySubmission(): ProxySubmission {
  return { proxyEdgeDeadline: undefined };
}

export const ProxySubmission = {
  encode(message: ProxySubmission, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.proxyEdgeDeadline !== undefined) {
      Timestamp.encode(toTimestamp(message.proxyEdgeDeadline), writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ProxySubmission {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseProxySubmission();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.proxyEdgeDeadline = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ProxySubmission {
    return {
      proxyEdgeDeadline: isSet(object.proxyEdgeDeadline) ? fromJsonTimestamp(object.proxyEdgeDeadline) : undefined,
    };
  },

  toJSON(message: ProxySubmission): unknown {
    const obj: any = {};
    if (message.proxyEdgeDeadline !== undefined) {
      obj.proxyEdgeDeadline = message.proxyEdgeDeadline.toISOString();
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<ProxySubmission>, I>>(base?: I): ProxySubmission {
    return ProxySubmission.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<ProxySubmission>, I>>(object: I): ProxySubmission {
    const message = createBaseProxySubmission();
    message.proxyEdgeDeadline = object.proxyEdgeDeadline ?? undefined;
    return message;
  },
};

type Builtin = Date | Function | Uint8Array | string | number | boolean | undefined;

export type DeepPartial<T> = T extends Builtin ? T
  : T extends Array<infer U> ? Array<DeepPartial<U>> : T extends ReadonlyArray<infer U> ? ReadonlyArray<DeepPartial<U>>
  : T extends {} ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;

type KeysOfUnion<T> = T extends T ? keyof T : never;
export type Exact<P, I extends P> = P extends Builtin ? P
  : P & { [K in keyof P]: Exact<P[K], I[K]> } & { [K in Exclude<keyof I, KeysOfUnion<P>>]: never };

function toTimestamp(date: Date): Timestamp {
  const seconds = date.getTime() / 1_000;
  const nanos = (date.getTime() % 1_000) * 1_000_000;
  return { seconds, nanos };
}

function fromTimestamp(t: Timestamp): Date {
  let millis = (t.seconds || 0) * 1_000;
  millis += (t.nanos || 0) / 1_000_000;
  return new globalThis.Date(millis);
}

function fromJsonTimestamp(o: any): Date {
  if (o instanceof globalThis.Date) {
    return o;
  } else if (typeof o === "string") {
    return new globalThis.Date(o);
  } else {
    return fromTimestamp(Timestamp.fromJSON(o));
  }
}

function isSet(value: any): boolean {
  return value !== null && value !== undefined;
}
