import React, { useContext } from 'react'
import { useRouter } from 'next/router'
import { CalendarIcon, HelpIcon, MoneyIcon, PieIcon } from '@tumelo/designsystem'
import { getCurrentSlug } from '../../utils/getCurrentSlug'
import { PrivilegesContext } from '../user'

interface RouteItem {
  href: string
  current?: boolean
  label: string
  icon?: React.ReactElement
  hidden: boolean
}

export const useRoutes: () => RouteItem[] = () => {
  const router = useRouter()
  const { mappedPrivileges } = useContext(PrivilegesContext)

  if (mappedPrivileges.canGetSchemes.length === 0) {
    return []
  }

  const currentSlug = getCurrentSlug(router)
  const isCurrent = (toMatch: string, matchingPaths?: string[]) =>
    toMatch === router.asPath || matchingPaths?.some(p => router.asPath.includes(p))

  return [
    {
      href: `${currentSlug}/investments`,
      current: isCurrent('/', [`${currentSlug}/investments`]),
      label: 'Investments',
      icon: <MoneyIcon width="15" height="15" />,
      hidden: false,
    },
    {
      href: `${currentSlug}/meetings`,
      current: isCurrent(`${currentSlug}/meetings`),
      label: 'Meetings',
      icon: <CalendarIcon width="15" height="15" />,
      hidden: false,
    },
    {
      href: `${currentSlug}/reports`,
      current: isCurrent(`${currentSlug}/reports`),
      label: 'Reports',
      icon: <PieIcon width="15" height="15" />,
      hidden: false,
    },
    {
      href: `${currentSlug}/help`,
      current: isCurrent(`${currentSlug}/help`),
      label: 'Help',
      icon: <HelpIcon width="15" height="15" />,
      hidden: false,
    },
  ]
}
