import { VotingOption } from '../proto/tumelo/transparency/v1/proposal';
import { VotingInstruction } from '../proto/tumelo/votingpolicy/v1/voting_recommendation';
export var VotingInstructionToVotingOption = function (votingInstruction) {
    switch (votingInstruction) {
        case VotingInstruction.VOTING_INSTRUCTION_FOR:
            return VotingOption.VOTING_OPTION_FOR;
        case VotingInstruction.VOTING_INSTRUCTION_AGAINST:
            return VotingOption.VOTING_OPTION_AGAINST;
        case VotingInstruction.VOTING_INSTRUCTION_ABSTAIN:
            return VotingOption.VOTING_OPTION_ABSTAIN;
        case VotingInstruction.VOTING_INSTRUCTION_WITHHOLD:
            return VotingOption.VOTING_OPTION_WITHHOLD;
        case VotingInstruction.VOTING_INSTRUCTION_NO_ACTION:
            return VotingOption.VOTING_OPTION_NO_ACTION;
        default:
            return VotingOption.VOTING_OPTION_INVALID;
    }
};
export var VotingOptionToString = function (votingOption) {
    switch (votingOption) {
        case VotingOption.VOTING_OPTION_INVALID:
            return 'invalid';
        case VotingOption.VOTING_OPTION_FOR:
            return 'For';
        case VotingOption.VOTING_OPTION_AGAINST:
            return 'Against';
        case VotingOption.VOTING_OPTION_ABSTAIN:
            return 'Abstain';
        case VotingOption.VOTING_OPTION_WITHHOLD:
            return 'Withhold';
        case VotingOption.VOTING_OPTION_NO_ACTION:
            return 'No Action';
        case VotingOption.VOTING_OPTION_ONE_YEAR:
            return 'One Year';
        case VotingOption.VOTING_OPTION_TWO_YEARS:
            return 'Two Years';
        case VotingOption.VOTING_OPTION_THREE_YEARS:
            return 'Three Years';
        default:
            return 'unknown';
    }
};
export var StringToVotingOption = function (votingOption) {
    switch (votingOption) {
        case 'invalid':
            return VotingOption.VOTING_OPTION_INVALID;
        case 'For':
            return VotingOption.VOTING_OPTION_FOR;
        case 'Against':
            return VotingOption.VOTING_OPTION_AGAINST;
        case 'Abstain':
            return VotingOption.VOTING_OPTION_ABSTAIN;
        case 'Withhold':
            return VotingOption.VOTING_OPTION_WITHHOLD;
        case 'No Action':
            return VotingOption.VOTING_OPTION_NO_ACTION;
        case 'One Year':
            return VotingOption.VOTING_OPTION_ONE_YEAR;
        case 'Two Years':
            return VotingOption.VOTING_OPTION_TWO_YEARS;
        case 'Three Years':
            return VotingOption.VOTING_OPTION_THREE_YEARS;
        default:
            return VotingOption.VOTING_OPTION_INVALID;
    }
};
export var getVotingOptionsToValueLabelPairs = function () {
    return [
        { value: VotingOption.VOTING_OPTION_FOR, label: 'For' },
        { value: VotingOption.VOTING_OPTION_AGAINST, label: 'Against' },
        { value: VotingOption.VOTING_OPTION_ABSTAIN, label: 'Abstain' },
        { value: VotingOption.VOTING_OPTION_WITHHOLD, label: 'Withhold' },
        { value: VotingOption.VOTING_OPTION_ONE_YEAR, label: 'One Year' },
        { value: VotingOption.VOTING_OPTION_TWO_YEARS, label: 'Two Years' },
        { value: VotingOption.VOTING_OPTION_THREE_YEARS, label: 'Three Years' },
        { value: VotingOption.VOTING_OPTION_NO_ACTION, label: 'No Action' },
    ];
};
