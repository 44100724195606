/*eslint-disable*/
// @ts-nocheck
/* eslint-disable */
import Long from "long";
import _m0 from "protobufjs/minimal";
import { Timestamp } from "../../../google/protobuf/timestamp";
import { Option, optionFromJSON, optionToJSON } from "./option";

export const protobufPackage = "tumelo.transparency.v1";

export enum VotingOption {
  VOTING_OPTION_INVALID = 0,
  VOTING_OPTION_FOR = 1,
  VOTING_OPTION_AGAINST = 2,
  VOTING_OPTION_ABSTAIN = 3,
  VOTING_OPTION_WITHHOLD = 4,
  VOTING_OPTION_NO_ACTION = 5,
  VOTING_OPTION_ONE_YEAR = 6,
  VOTING_OPTION_TWO_YEARS = 7,
  VOTING_OPTION_THREE_YEARS = 8,
  UNRECOGNIZED = -1,
}

export function votingOptionFromJSON(object: any): VotingOption {
  switch (object) {
    case 0:
    case "VOTING_OPTION_INVALID":
      return VotingOption.VOTING_OPTION_INVALID;
    case 1:
    case "VOTING_OPTION_FOR":
      return VotingOption.VOTING_OPTION_FOR;
    case 2:
    case "VOTING_OPTION_AGAINST":
      return VotingOption.VOTING_OPTION_AGAINST;
    case 3:
    case "VOTING_OPTION_ABSTAIN":
      return VotingOption.VOTING_OPTION_ABSTAIN;
    case 4:
    case "VOTING_OPTION_WITHHOLD":
      return VotingOption.VOTING_OPTION_WITHHOLD;
    case 5:
    case "VOTING_OPTION_NO_ACTION":
      return VotingOption.VOTING_OPTION_NO_ACTION;
    case 6:
    case "VOTING_OPTION_ONE_YEAR":
      return VotingOption.VOTING_OPTION_ONE_YEAR;
    case 7:
    case "VOTING_OPTION_TWO_YEARS":
      return VotingOption.VOTING_OPTION_TWO_YEARS;
    case 8:
    case "VOTING_OPTION_THREE_YEARS":
      return VotingOption.VOTING_OPTION_THREE_YEARS;
    case -1:
    case "UNRECOGNIZED":
    default:
      return VotingOption.UNRECOGNIZED;
  }
}

export function votingOptionToJSON(object: VotingOption): string {
  switch (object) {
    case VotingOption.VOTING_OPTION_INVALID:
      return "VOTING_OPTION_INVALID";
    case VotingOption.VOTING_OPTION_FOR:
      return "VOTING_OPTION_FOR";
    case VotingOption.VOTING_OPTION_AGAINST:
      return "VOTING_OPTION_AGAINST";
    case VotingOption.VOTING_OPTION_ABSTAIN:
      return "VOTING_OPTION_ABSTAIN";
    case VotingOption.VOTING_OPTION_WITHHOLD:
      return "VOTING_OPTION_WITHHOLD";
    case VotingOption.VOTING_OPTION_NO_ACTION:
      return "VOTING_OPTION_NO_ACTION";
    case VotingOption.VOTING_OPTION_ONE_YEAR:
      return "VOTING_OPTION_ONE_YEAR";
    case VotingOption.VOTING_OPTION_TWO_YEARS:
      return "VOTING_OPTION_TWO_YEARS";
    case VotingOption.VOTING_OPTION_THREE_YEARS:
      return "VOTING_OPTION_THREE_YEARS";
    case VotingOption.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

/** Proposal represents a question being asked at the General Meeting */
export interface Proposal {
  /** OUTPUT ONLY the name of the proposal of format organizations/{uuid}/generalMeetings/{uuid}/proposals/{uuid} */
  name: string;
  /**
   * REQUIRED the identifier given in the GM document e.g 1 or 1.a
   * The sequence_identifier is a “free text” label.
   * The sequence_identifier must be unique within the meeting, if the withdraw_time is non nil the unique constraint does not apply.
   */
  sequenceIdentifier: string;
  /** REQUIRED the title of the proposal given in the GM document */
  title: string;
  /** OPTIONAL a markdown formatted description of the proposal given ion the GM document */
  description: string;
  /** OPTIONAL */
  managementRecommendation:
    | Proposal_ManagementRecommendation
    | undefined;
  /** REQUIRED */
  source: Proposal_Source;
  /** OPTIONAL */
  passThreshold:
    | Proposal_PassThreshold
    | undefined;
  /** OPTIONAL added when a decision on the proposal is available */
  outcome:
    | Proposal_Outcome
    | undefined;
  /**
   * OPTIONAL (EDI data feed) unique identifier for the proposal within this AGM
   * If a proposal is withdrawn the edi_proposal_identifier will NOT be reused
   * Proposals that have the same general meeting identifier (derived from the name field) & edi_proposal_identifier pair are considered to be the same proposal
   */
  ediProposalIdentifier: number;
  /** OPTIONAL (EDI data feed) the name of the parent proposal (format organizations/{uuid}/generalMeetings/{uuid}/proposals/{uuid}) */
  parentProposalName: string;
  /**
   * OPTIONAL (EDI data feed) number representing the ordinal position of the proposal within the proxy document
   * The edi_sequence_number specifies the order of the proposals
   * The edi_sequence_number must be unique within the meeting, if the withdraw_time is non nil the unique constraint does not apply
   */
  ediSequenceNumber: number;
  /** REQUIRED (EDI data feed) true if there is a vote on this proposal */
  votable: boolean;
  /** OPTIONAL (EDI data feed) full proposal text (> 250 chars) */
  fullText: string;
  /** OPTIONAL (EDI data feed) list of one or more 2-letter classification codes that apply to this proposal */
  classificationCodes: string[];
  /**
   * OPTIONAL available voting options for this proposal
   * For all proposals for which we don't have data available, the available voting options for the proposal should be an empty list
   */
  votingOptions: VotingOption[];
  /**
   * OPTIONAL captures the time (or as close to that time as possible) at which the proposal was withdrawn from the meeting.
   * It is expected that this will only be set if a proposal is withdrawn prior to the meeting; the outcome field is used to capture proposals that are withdrawn during the meeting.
   * A nil value indicates that the proposal has not been withdrawn, a non-nil value indicates that the proposal has been withdrawn.
   * The withdraw_time can not be set to a time in the future.
   */
  withdrawTime:
    | Date
    | undefined;
  /**
   * OUTPUT ONLY true if the proposal is currently receiving votes.
   * The proposal must be votable and not withdrawn.
   * This will be false if the proposal is not votable or has been withdrawn.
   * This property does not change once the associated general meeting has closed.
   */
  currentlyVotable: boolean;
  /** OPTIONAL (EDI data feed) list of one or more single letter SEC classification codes that apply to this proposal */
  secClassificationCodes: string[];
  /**
   * OPTIONAL
   * The "proposal number" used by proxy edge to identify the proposal
   * We store this number as is from proxy edge and don't modify it
   * Must be unique per meeting for currently_votable=true proposals
   * Must not have leading or trailing whitespace
   */
  proxyEdgeProposalNumber: string;
}

/** Source describes who raised the proposal */
export enum Proposal_Source {
  PROPOSAL_SOURCE_INVALID = 0,
  PROPOSAL_SOURCE_SHAREHOLDER = 1,
  PROPOSAL_SOURCE_MANAGEMENT = 2,
  UNRECOGNIZED = -1,
}

export function proposal_SourceFromJSON(object: any): Proposal_Source {
  switch (object) {
    case 0:
    case "PROPOSAL_SOURCE_INVALID":
      return Proposal_Source.PROPOSAL_SOURCE_INVALID;
    case 1:
    case "PROPOSAL_SOURCE_SHAREHOLDER":
      return Proposal_Source.PROPOSAL_SOURCE_SHAREHOLDER;
    case 2:
    case "PROPOSAL_SOURCE_MANAGEMENT":
      return Proposal_Source.PROPOSAL_SOURCE_MANAGEMENT;
    case -1:
    case "UNRECOGNIZED":
    default:
      return Proposal_Source.UNRECOGNIZED;
  }
}

export function proposal_SourceToJSON(object: Proposal_Source): string {
  switch (object) {
    case Proposal_Source.PROPOSAL_SOURCE_INVALID:
      return "PROPOSAL_SOURCE_INVALID";
    case Proposal_Source.PROPOSAL_SOURCE_SHAREHOLDER:
      return "PROPOSAL_SOURCE_SHAREHOLDER";
    case Proposal_Source.PROPOSAL_SOURCE_MANAGEMENT:
      return "PROPOSAL_SOURCE_MANAGEMENT";
    case Proposal_Source.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

/**
 * ManagementRecommendation is a recommendation from the management of the organization
 * describing how they recommend shareholders should vote in response to the proposal
 */
export interface Proposal_ManagementRecommendation {
  /** REQUIRED */
  recommendation: Option;
  /** OPTIONAL a markdown formatted description of the recommendation given */
  recommendationDescription: string;
}

/** PassThreshold represents the share of vote required for a proposal to pass */
export interface Proposal_PassThreshold {
  /** REQUIRED decimal in range 0 <= pass_threshold <= 1, represents share of the vote required for the proposal to pass */
  threshold: number;
}

/** Outcome is the result of the proposal vote at the general meeting */
export interface Proposal_Outcome {
  /** REQUIRED the decision taken on the proposal after the votes have been counted */
  decision: Proposal_Outcome_Decision;
  /** OPTIONAL added when the count of the votes is available */
  votes: Proposal_Outcome_Votes | undefined;
  createTime:
    | Date
    | undefined;
  /** OPTIONAL reason for withdrawing the proposal, only applicable when decision is "Withdrawn" */
  withdrawalReason: string;
}

/** Decision is the final decision on the proposal */
export enum Proposal_Outcome_Decision {
  PROPOSAL_OUTCOME_DECISION_INVALID = 0,
  PROPOSAL_OUTCOME_DECISION_FOR = 1,
  PROPOSAL_OUTCOME_DECISION_AGAINST = 2,
  PROPOSAL_OUTCOME_DECISION_WITHDRAWN = 3,
  /** PROPOSAL_OUTCOME_DECISION_ONE_YEAR - say on frequency options */
  PROPOSAL_OUTCOME_DECISION_ONE_YEAR = 4,
  PROPOSAL_OUTCOME_DECISION_TWO_YEARS = 5,
  PROPOSAL_OUTCOME_DECISION_THREE_YEARS = 6,
  UNRECOGNIZED = -1,
}

export function proposal_Outcome_DecisionFromJSON(object: any): Proposal_Outcome_Decision {
  switch (object) {
    case 0:
    case "PROPOSAL_OUTCOME_DECISION_INVALID":
      return Proposal_Outcome_Decision.PROPOSAL_OUTCOME_DECISION_INVALID;
    case 1:
    case "PROPOSAL_OUTCOME_DECISION_FOR":
      return Proposal_Outcome_Decision.PROPOSAL_OUTCOME_DECISION_FOR;
    case 2:
    case "PROPOSAL_OUTCOME_DECISION_AGAINST":
      return Proposal_Outcome_Decision.PROPOSAL_OUTCOME_DECISION_AGAINST;
    case 3:
    case "PROPOSAL_OUTCOME_DECISION_WITHDRAWN":
      return Proposal_Outcome_Decision.PROPOSAL_OUTCOME_DECISION_WITHDRAWN;
    case 4:
    case "PROPOSAL_OUTCOME_DECISION_ONE_YEAR":
      return Proposal_Outcome_Decision.PROPOSAL_OUTCOME_DECISION_ONE_YEAR;
    case 5:
    case "PROPOSAL_OUTCOME_DECISION_TWO_YEARS":
      return Proposal_Outcome_Decision.PROPOSAL_OUTCOME_DECISION_TWO_YEARS;
    case 6:
    case "PROPOSAL_OUTCOME_DECISION_THREE_YEARS":
      return Proposal_Outcome_Decision.PROPOSAL_OUTCOME_DECISION_THREE_YEARS;
    case -1:
    case "UNRECOGNIZED":
    default:
      return Proposal_Outcome_Decision.UNRECOGNIZED;
  }
}

export function proposal_Outcome_DecisionToJSON(object: Proposal_Outcome_Decision): string {
  switch (object) {
    case Proposal_Outcome_Decision.PROPOSAL_OUTCOME_DECISION_INVALID:
      return "PROPOSAL_OUTCOME_DECISION_INVALID";
    case Proposal_Outcome_Decision.PROPOSAL_OUTCOME_DECISION_FOR:
      return "PROPOSAL_OUTCOME_DECISION_FOR";
    case Proposal_Outcome_Decision.PROPOSAL_OUTCOME_DECISION_AGAINST:
      return "PROPOSAL_OUTCOME_DECISION_AGAINST";
    case Proposal_Outcome_Decision.PROPOSAL_OUTCOME_DECISION_WITHDRAWN:
      return "PROPOSAL_OUTCOME_DECISION_WITHDRAWN";
    case Proposal_Outcome_Decision.PROPOSAL_OUTCOME_DECISION_ONE_YEAR:
      return "PROPOSAL_OUTCOME_DECISION_ONE_YEAR";
    case Proposal_Outcome_Decision.PROPOSAL_OUTCOME_DECISION_TWO_YEARS:
      return "PROPOSAL_OUTCOME_DECISION_TWO_YEARS";
    case Proposal_Outcome_Decision.PROPOSAL_OUTCOME_DECISION_THREE_YEARS:
      return "PROPOSAL_OUTCOME_DECISION_THREE_YEARS";
    case Proposal_Outcome_Decision.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

/** Votes contains the count of the votes for a proposal */
export interface Proposal_Outcome_Votes {
  for: Proposal_Outcome_Votes_Count | undefined;
  against: Proposal_Outcome_Votes_Count | undefined;
  abstentions: Proposal_Outcome_Votes_Count | undefined;
  brokerNonVotes: Proposal_Outcome_Votes_Count | undefined;
  withheld: Proposal_Outcome_Votes_Count | undefined;
  oneYear: Proposal_Outcome_Votes_Count | undefined;
  twoYears: Proposal_Outcome_Votes_Count | undefined;
  threeYears: Proposal_Outcome_Votes_Count | undefined;
}

export interface Proposal_Outcome_Votes_Count {
  votes: number;
}

function createBaseProposal(): Proposal {
  return {
    name: "",
    sequenceIdentifier: "",
    title: "",
    description: "",
    managementRecommendation: undefined,
    source: 0,
    passThreshold: undefined,
    outcome: undefined,
    ediProposalIdentifier: 0,
    parentProposalName: "",
    ediSequenceNumber: 0,
    votable: false,
    fullText: "",
    classificationCodes: [],
    votingOptions: [],
    withdrawTime: undefined,
    currentlyVotable: false,
    secClassificationCodes: [],
    proxyEdgeProposalNumber: "",
  };
}

export const Proposal = {
  encode(message: Proposal, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.name !== "") {
      writer.uint32(10).string(message.name);
    }
    if (message.sequenceIdentifier !== "") {
      writer.uint32(18).string(message.sequenceIdentifier);
    }
    if (message.title !== "") {
      writer.uint32(26).string(message.title);
    }
    if (message.description !== "") {
      writer.uint32(34).string(message.description);
    }
    if (message.managementRecommendation !== undefined) {
      Proposal_ManagementRecommendation.encode(message.managementRecommendation, writer.uint32(42).fork()).ldelim();
    }
    if (message.source !== 0) {
      writer.uint32(48).int32(message.source);
    }
    if (message.passThreshold !== undefined) {
      Proposal_PassThreshold.encode(message.passThreshold, writer.uint32(58).fork()).ldelim();
    }
    if (message.outcome !== undefined) {
      Proposal_Outcome.encode(message.outcome, writer.uint32(66).fork()).ldelim();
    }
    if (message.ediProposalIdentifier !== 0) {
      writer.uint32(120).int32(message.ediProposalIdentifier);
    }
    if (message.parentProposalName !== "") {
      writer.uint32(82).string(message.parentProposalName);
    }
    if (message.ediSequenceNumber !== 0) {
      writer.uint32(128).int32(message.ediSequenceNumber);
    }
    if (message.votable === true) {
      writer.uint32(96).bool(message.votable);
    }
    if (message.fullText !== "") {
      writer.uint32(106).string(message.fullText);
    }
    for (const v of message.classificationCodes) {
      writer.uint32(114).string(v!);
    }
    writer.uint32(138).fork();
    for (const v of message.votingOptions) {
      writer.int32(v);
    }
    writer.ldelim();
    if (message.withdrawTime !== undefined) {
      Timestamp.encode(toTimestamp(message.withdrawTime), writer.uint32(146).fork()).ldelim();
    }
    if (message.currentlyVotable === true) {
      writer.uint32(152).bool(message.currentlyVotable);
    }
    for (const v of message.secClassificationCodes) {
      writer.uint32(162).string(v!);
    }
    if (message.proxyEdgeProposalNumber !== "") {
      writer.uint32(170).string(message.proxyEdgeProposalNumber);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Proposal {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseProposal();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.name = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.sequenceIdentifier = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.title = reader.string();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.description = reader.string();
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.managementRecommendation = Proposal_ManagementRecommendation.decode(reader, reader.uint32());
          continue;
        case 6:
          if (tag !== 48) {
            break;
          }

          message.source = reader.int32() as any;
          continue;
        case 7:
          if (tag !== 58) {
            break;
          }

          message.passThreshold = Proposal_PassThreshold.decode(reader, reader.uint32());
          continue;
        case 8:
          if (tag !== 66) {
            break;
          }

          message.outcome = Proposal_Outcome.decode(reader, reader.uint32());
          continue;
        case 15:
          if (tag !== 120) {
            break;
          }

          message.ediProposalIdentifier = reader.int32();
          continue;
        case 10:
          if (tag !== 82) {
            break;
          }

          message.parentProposalName = reader.string();
          continue;
        case 16:
          if (tag !== 128) {
            break;
          }

          message.ediSequenceNumber = reader.int32();
          continue;
        case 12:
          if (tag !== 96) {
            break;
          }

          message.votable = reader.bool();
          continue;
        case 13:
          if (tag !== 106) {
            break;
          }

          message.fullText = reader.string();
          continue;
        case 14:
          if (tag !== 114) {
            break;
          }

          message.classificationCodes.push(reader.string());
          continue;
        case 17:
          if (tag === 136) {
            message.votingOptions.push(reader.int32() as any);

            continue;
          }

          if (tag === 138) {
            const end2 = reader.uint32() + reader.pos;
            while (reader.pos < end2) {
              message.votingOptions.push(reader.int32() as any);
            }

            continue;
          }

          break;
        case 18:
          if (tag !== 146) {
            break;
          }

          message.withdrawTime = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          continue;
        case 19:
          if (tag !== 152) {
            break;
          }

          message.currentlyVotable = reader.bool();
          continue;
        case 20:
          if (tag !== 162) {
            break;
          }

          message.secClassificationCodes.push(reader.string());
          continue;
        case 21:
          if (tag !== 170) {
            break;
          }

          message.proxyEdgeProposalNumber = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): Proposal {
    return {
      name: isSet(object.name) ? String(object.name) : "",
      sequenceIdentifier: isSet(object.sequenceIdentifier) ? String(object.sequenceIdentifier) : "",
      title: isSet(object.title) ? String(object.title) : "",
      description: isSet(object.description) ? String(object.description) : "",
      managementRecommendation: isSet(object.managementRecommendation)
        ? Proposal_ManagementRecommendation.fromJSON(object.managementRecommendation)
        : undefined,
      source: isSet(object.source) ? proposal_SourceFromJSON(object.source) : 0,
      passThreshold: isSet(object.passThreshold) ? Proposal_PassThreshold.fromJSON(object.passThreshold) : undefined,
      outcome: isSet(object.outcome) ? Proposal_Outcome.fromJSON(object.outcome) : undefined,
      ediProposalIdentifier: isSet(object.ediProposalIdentifier) ? Number(object.ediProposalIdentifier) : 0,
      parentProposalName: isSet(object.parentProposalName) ? String(object.parentProposalName) : "",
      ediSequenceNumber: isSet(object.ediSequenceNumber) ? Number(object.ediSequenceNumber) : 0,
      votable: isSet(object.votable) ? Boolean(object.votable) : false,
      fullText: isSet(object.fullText) ? String(object.fullText) : "",
      classificationCodes: globalThis.Array.isArray(object?.classificationCodes)
        ? object.classificationCodes.map((e: any) => String(e))
        : [],
      votingOptions: globalThis.Array.isArray(object?.votingOptions)
        ? object.votingOptions.map((e: any) => votingOptionFromJSON(e))
        : [],
      withdrawTime: isSet(object.withdrawTime) ? fromJsonTimestamp(object.withdrawTime) : undefined,
      currentlyVotable: isSet(object.currentlyVotable) ? Boolean(object.currentlyVotable) : false,
      secClassificationCodes: globalThis.Array.isArray(object?.secClassificationCodes)
        ? object.secClassificationCodes.map((e: any) => String(e))
        : [],
      proxyEdgeProposalNumber: isSet(object.proxyEdgeProposalNumber) ? String(object.proxyEdgeProposalNumber) : "",
    };
  },

  toJSON(message: Proposal): unknown {
    const obj: any = {};
    if (message.name !== "") {
      obj.name = message.name;
    }
    if (message.sequenceIdentifier !== "") {
      obj.sequenceIdentifier = message.sequenceIdentifier;
    }
    if (message.title !== "") {
      obj.title = message.title;
    }
    if (message.description !== "") {
      obj.description = message.description;
    }
    if (message.managementRecommendation !== undefined) {
      obj.managementRecommendation = Proposal_ManagementRecommendation.toJSON(message.managementRecommendation);
    }
    if (message.source !== 0) {
      obj.source = proposal_SourceToJSON(message.source);
    }
    if (message.passThreshold !== undefined) {
      obj.passThreshold = Proposal_PassThreshold.toJSON(message.passThreshold);
    }
    if (message.outcome !== undefined) {
      obj.outcome = Proposal_Outcome.toJSON(message.outcome);
    }
    if (message.ediProposalIdentifier !== 0) {
      obj.ediProposalIdentifier = Math.round(message.ediProposalIdentifier);
    }
    if (message.parentProposalName !== "") {
      obj.parentProposalName = message.parentProposalName;
    }
    if (message.ediSequenceNumber !== 0) {
      obj.ediSequenceNumber = Math.round(message.ediSequenceNumber);
    }
    if (message.votable === true) {
      obj.votable = message.votable;
    }
    if (message.fullText !== "") {
      obj.fullText = message.fullText;
    }
    if (message.classificationCodes?.length) {
      obj.classificationCodes = message.classificationCodes;
    }
    if (message.votingOptions?.length) {
      obj.votingOptions = message.votingOptions.map((e) => votingOptionToJSON(e));
    }
    if (message.withdrawTime !== undefined) {
      obj.withdrawTime = message.withdrawTime.toISOString();
    }
    if (message.currentlyVotable === true) {
      obj.currentlyVotable = message.currentlyVotable;
    }
    if (message.secClassificationCodes?.length) {
      obj.secClassificationCodes = message.secClassificationCodes;
    }
    if (message.proxyEdgeProposalNumber !== "") {
      obj.proxyEdgeProposalNumber = message.proxyEdgeProposalNumber;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<Proposal>, I>>(base?: I): Proposal {
    return Proposal.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<Proposal>, I>>(object: I): Proposal {
    const message = createBaseProposal();
    message.name = object.name ?? "";
    message.sequenceIdentifier = object.sequenceIdentifier ?? "";
    message.title = object.title ?? "";
    message.description = object.description ?? "";
    message.managementRecommendation =
      (object.managementRecommendation !== undefined && object.managementRecommendation !== null)
        ? Proposal_ManagementRecommendation.fromPartial(object.managementRecommendation)
        : undefined;
    message.source = object.source ?? 0;
    message.passThreshold = (object.passThreshold !== undefined && object.passThreshold !== null)
      ? Proposal_PassThreshold.fromPartial(object.passThreshold)
      : undefined;
    message.outcome = (object.outcome !== undefined && object.outcome !== null)
      ? Proposal_Outcome.fromPartial(object.outcome)
      : undefined;
    message.ediProposalIdentifier = object.ediProposalIdentifier ?? 0;
    message.parentProposalName = object.parentProposalName ?? "";
    message.ediSequenceNumber = object.ediSequenceNumber ?? 0;
    message.votable = object.votable ?? false;
    message.fullText = object.fullText ?? "";
    message.classificationCodes = object.classificationCodes?.map((e) => e) || [];
    message.votingOptions = object.votingOptions?.map((e) => e) || [];
    message.withdrawTime = object.withdrawTime ?? undefined;
    message.currentlyVotable = object.currentlyVotable ?? false;
    message.secClassificationCodes = object.secClassificationCodes?.map((e) => e) || [];
    message.proxyEdgeProposalNumber = object.proxyEdgeProposalNumber ?? "";
    return message;
  },
};

function createBaseProposal_ManagementRecommendation(): Proposal_ManagementRecommendation {
  return { recommendation: 0, recommendationDescription: "" };
}

export const Proposal_ManagementRecommendation = {
  encode(message: Proposal_ManagementRecommendation, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.recommendation !== 0) {
      writer.uint32(8).int32(message.recommendation);
    }
    if (message.recommendationDescription !== "") {
      writer.uint32(18).string(message.recommendationDescription);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Proposal_ManagementRecommendation {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseProposal_ManagementRecommendation();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.recommendation = reader.int32() as any;
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.recommendationDescription = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): Proposal_ManagementRecommendation {
    return {
      recommendation: isSet(object.recommendation) ? optionFromJSON(object.recommendation) : 0,
      recommendationDescription: isSet(object.recommendationDescription)
        ? String(object.recommendationDescription)
        : "",
    };
  },

  toJSON(message: Proposal_ManagementRecommendation): unknown {
    const obj: any = {};
    if (message.recommendation !== 0) {
      obj.recommendation = optionToJSON(message.recommendation);
    }
    if (message.recommendationDescription !== "") {
      obj.recommendationDescription = message.recommendationDescription;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<Proposal_ManagementRecommendation>, I>>(
    base?: I,
  ): Proposal_ManagementRecommendation {
    return Proposal_ManagementRecommendation.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<Proposal_ManagementRecommendation>, I>>(
    object: I,
  ): Proposal_ManagementRecommendation {
    const message = createBaseProposal_ManagementRecommendation();
    message.recommendation = object.recommendation ?? 0;
    message.recommendationDescription = object.recommendationDescription ?? "";
    return message;
  },
};

function createBaseProposal_PassThreshold(): Proposal_PassThreshold {
  return { threshold: 0 };
}

export const Proposal_PassThreshold = {
  encode(message: Proposal_PassThreshold, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.threshold !== 0) {
      writer.uint32(9).double(message.threshold);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Proposal_PassThreshold {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseProposal_PassThreshold();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 9) {
            break;
          }

          message.threshold = reader.double();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): Proposal_PassThreshold {
    return { threshold: isSet(object.threshold) ? Number(object.threshold) : 0 };
  },

  toJSON(message: Proposal_PassThreshold): unknown {
    const obj: any = {};
    if (message.threshold !== 0) {
      obj.threshold = message.threshold;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<Proposal_PassThreshold>, I>>(base?: I): Proposal_PassThreshold {
    return Proposal_PassThreshold.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<Proposal_PassThreshold>, I>>(object: I): Proposal_PassThreshold {
    const message = createBaseProposal_PassThreshold();
    message.threshold = object.threshold ?? 0;
    return message;
  },
};

function createBaseProposal_Outcome(): Proposal_Outcome {
  return { decision: 0, votes: undefined, createTime: undefined, withdrawalReason: "" };
}

export const Proposal_Outcome = {
  encode(message: Proposal_Outcome, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.decision !== 0) {
      writer.uint32(8).int32(message.decision);
    }
    if (message.votes !== undefined) {
      Proposal_Outcome_Votes.encode(message.votes, writer.uint32(18).fork()).ldelim();
    }
    if (message.createTime !== undefined) {
      Timestamp.encode(toTimestamp(message.createTime), writer.uint32(26).fork()).ldelim();
    }
    if (message.withdrawalReason !== "") {
      writer.uint32(34).string(message.withdrawalReason);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Proposal_Outcome {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseProposal_Outcome();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.decision = reader.int32() as any;
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.votes = Proposal_Outcome_Votes.decode(reader, reader.uint32());
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.createTime = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.withdrawalReason = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): Proposal_Outcome {
    return {
      decision: isSet(object.decision) ? proposal_Outcome_DecisionFromJSON(object.decision) : 0,
      votes: isSet(object.votes) ? Proposal_Outcome_Votes.fromJSON(object.votes) : undefined,
      createTime: isSet(object.createTime) ? fromJsonTimestamp(object.createTime) : undefined,
      withdrawalReason: isSet(object.withdrawalReason) ? String(object.withdrawalReason) : "",
    };
  },

  toJSON(message: Proposal_Outcome): unknown {
    const obj: any = {};
    if (message.decision !== 0) {
      obj.decision = proposal_Outcome_DecisionToJSON(message.decision);
    }
    if (message.votes !== undefined) {
      obj.votes = Proposal_Outcome_Votes.toJSON(message.votes);
    }
    if (message.createTime !== undefined) {
      obj.createTime = message.createTime.toISOString();
    }
    if (message.withdrawalReason !== "") {
      obj.withdrawalReason = message.withdrawalReason;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<Proposal_Outcome>, I>>(base?: I): Proposal_Outcome {
    return Proposal_Outcome.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<Proposal_Outcome>, I>>(object: I): Proposal_Outcome {
    const message = createBaseProposal_Outcome();
    message.decision = object.decision ?? 0;
    message.votes = (object.votes !== undefined && object.votes !== null)
      ? Proposal_Outcome_Votes.fromPartial(object.votes)
      : undefined;
    message.createTime = object.createTime ?? undefined;
    message.withdrawalReason = object.withdrawalReason ?? "";
    return message;
  },
};

function createBaseProposal_Outcome_Votes(): Proposal_Outcome_Votes {
  return {
    for: undefined,
    against: undefined,
    abstentions: undefined,
    brokerNonVotes: undefined,
    withheld: undefined,
    oneYear: undefined,
    twoYears: undefined,
    threeYears: undefined,
  };
}

export const Proposal_Outcome_Votes = {
  encode(message: Proposal_Outcome_Votes, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.for !== undefined) {
      Proposal_Outcome_Votes_Count.encode(message.for, writer.uint32(10).fork()).ldelim();
    }
    if (message.against !== undefined) {
      Proposal_Outcome_Votes_Count.encode(message.against, writer.uint32(18).fork()).ldelim();
    }
    if (message.abstentions !== undefined) {
      Proposal_Outcome_Votes_Count.encode(message.abstentions, writer.uint32(26).fork()).ldelim();
    }
    if (message.brokerNonVotes !== undefined) {
      Proposal_Outcome_Votes_Count.encode(message.brokerNonVotes, writer.uint32(34).fork()).ldelim();
    }
    if (message.withheld !== undefined) {
      Proposal_Outcome_Votes_Count.encode(message.withheld, writer.uint32(42).fork()).ldelim();
    }
    if (message.oneYear !== undefined) {
      Proposal_Outcome_Votes_Count.encode(message.oneYear, writer.uint32(50).fork()).ldelim();
    }
    if (message.twoYears !== undefined) {
      Proposal_Outcome_Votes_Count.encode(message.twoYears, writer.uint32(58).fork()).ldelim();
    }
    if (message.threeYears !== undefined) {
      Proposal_Outcome_Votes_Count.encode(message.threeYears, writer.uint32(66).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Proposal_Outcome_Votes {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseProposal_Outcome_Votes();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.for = Proposal_Outcome_Votes_Count.decode(reader, reader.uint32());
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.against = Proposal_Outcome_Votes_Count.decode(reader, reader.uint32());
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.abstentions = Proposal_Outcome_Votes_Count.decode(reader, reader.uint32());
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.brokerNonVotes = Proposal_Outcome_Votes_Count.decode(reader, reader.uint32());
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.withheld = Proposal_Outcome_Votes_Count.decode(reader, reader.uint32());
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.oneYear = Proposal_Outcome_Votes_Count.decode(reader, reader.uint32());
          continue;
        case 7:
          if (tag !== 58) {
            break;
          }

          message.twoYears = Proposal_Outcome_Votes_Count.decode(reader, reader.uint32());
          continue;
        case 8:
          if (tag !== 66) {
            break;
          }

          message.threeYears = Proposal_Outcome_Votes_Count.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): Proposal_Outcome_Votes {
    return {
      for: isSet(object.for) ? Proposal_Outcome_Votes_Count.fromJSON(object.for) : undefined,
      against: isSet(object.against) ? Proposal_Outcome_Votes_Count.fromJSON(object.against) : undefined,
      abstentions: isSet(object.abstentions) ? Proposal_Outcome_Votes_Count.fromJSON(object.abstentions) : undefined,
      brokerNonVotes: isSet(object.brokerNonVotes)
        ? Proposal_Outcome_Votes_Count.fromJSON(object.brokerNonVotes)
        : undefined,
      withheld: isSet(object.withheld) ? Proposal_Outcome_Votes_Count.fromJSON(object.withheld) : undefined,
      oneYear: isSet(object.oneYear) ? Proposal_Outcome_Votes_Count.fromJSON(object.oneYear) : undefined,
      twoYears: isSet(object.twoYears) ? Proposal_Outcome_Votes_Count.fromJSON(object.twoYears) : undefined,
      threeYears: isSet(object.threeYears) ? Proposal_Outcome_Votes_Count.fromJSON(object.threeYears) : undefined,
    };
  },

  toJSON(message: Proposal_Outcome_Votes): unknown {
    const obj: any = {};
    if (message.for !== undefined) {
      obj.for = Proposal_Outcome_Votes_Count.toJSON(message.for);
    }
    if (message.against !== undefined) {
      obj.against = Proposal_Outcome_Votes_Count.toJSON(message.against);
    }
    if (message.abstentions !== undefined) {
      obj.abstentions = Proposal_Outcome_Votes_Count.toJSON(message.abstentions);
    }
    if (message.brokerNonVotes !== undefined) {
      obj.brokerNonVotes = Proposal_Outcome_Votes_Count.toJSON(message.brokerNonVotes);
    }
    if (message.withheld !== undefined) {
      obj.withheld = Proposal_Outcome_Votes_Count.toJSON(message.withheld);
    }
    if (message.oneYear !== undefined) {
      obj.oneYear = Proposal_Outcome_Votes_Count.toJSON(message.oneYear);
    }
    if (message.twoYears !== undefined) {
      obj.twoYears = Proposal_Outcome_Votes_Count.toJSON(message.twoYears);
    }
    if (message.threeYears !== undefined) {
      obj.threeYears = Proposal_Outcome_Votes_Count.toJSON(message.threeYears);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<Proposal_Outcome_Votes>, I>>(base?: I): Proposal_Outcome_Votes {
    return Proposal_Outcome_Votes.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<Proposal_Outcome_Votes>, I>>(object: I): Proposal_Outcome_Votes {
    const message = createBaseProposal_Outcome_Votes();
    message.for = (object.for !== undefined && object.for !== null)
      ? Proposal_Outcome_Votes_Count.fromPartial(object.for)
      : undefined;
    message.against = (object.against !== undefined && object.against !== null)
      ? Proposal_Outcome_Votes_Count.fromPartial(object.against)
      : undefined;
    message.abstentions = (object.abstentions !== undefined && object.abstentions !== null)
      ? Proposal_Outcome_Votes_Count.fromPartial(object.abstentions)
      : undefined;
    message.brokerNonVotes = (object.brokerNonVotes !== undefined && object.brokerNonVotes !== null)
      ? Proposal_Outcome_Votes_Count.fromPartial(object.brokerNonVotes)
      : undefined;
    message.withheld = (object.withheld !== undefined && object.withheld !== null)
      ? Proposal_Outcome_Votes_Count.fromPartial(object.withheld)
      : undefined;
    message.oneYear = (object.oneYear !== undefined && object.oneYear !== null)
      ? Proposal_Outcome_Votes_Count.fromPartial(object.oneYear)
      : undefined;
    message.twoYears = (object.twoYears !== undefined && object.twoYears !== null)
      ? Proposal_Outcome_Votes_Count.fromPartial(object.twoYears)
      : undefined;
    message.threeYears = (object.threeYears !== undefined && object.threeYears !== null)
      ? Proposal_Outcome_Votes_Count.fromPartial(object.threeYears)
      : undefined;
    return message;
  },
};

function createBaseProposal_Outcome_Votes_Count(): Proposal_Outcome_Votes_Count {
  return { votes: 0 };
}

export const Proposal_Outcome_Votes_Count = {
  encode(message: Proposal_Outcome_Votes_Count, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.votes !== 0) {
      writer.uint32(8).uint64(message.votes);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Proposal_Outcome_Votes_Count {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseProposal_Outcome_Votes_Count();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.votes = longToNumber(reader.uint64() as Long);
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): Proposal_Outcome_Votes_Count {
    return { votes: isSet(object.votes) ? Number(object.votes) : 0 };
  },

  toJSON(message: Proposal_Outcome_Votes_Count): unknown {
    const obj: any = {};
    if (message.votes !== 0) {
      obj.votes = Math.round(message.votes);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<Proposal_Outcome_Votes_Count>, I>>(base?: I): Proposal_Outcome_Votes_Count {
    return Proposal_Outcome_Votes_Count.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<Proposal_Outcome_Votes_Count>, I>>(object: I): Proposal_Outcome_Votes_Count {
    const message = createBaseProposal_Outcome_Votes_Count();
    message.votes = object.votes ?? 0;
    return message;
  },
};

type Builtin = Date | Function | Uint8Array | string | number | boolean | undefined;

export type DeepPartial<T> = T extends Builtin ? T
  : T extends Array<infer U> ? Array<DeepPartial<U>> : T extends ReadonlyArray<infer U> ? ReadonlyArray<DeepPartial<U>>
  : T extends {} ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;

type KeysOfUnion<T> = T extends T ? keyof T : never;
export type Exact<P, I extends P> = P extends Builtin ? P
  : P & { [K in keyof P]: Exact<P[K], I[K]> } & { [K in Exclude<keyof I, KeysOfUnion<P>>]: never };

function toTimestamp(date: Date): Timestamp {
  const seconds = date.getTime() / 1_000;
  const nanos = (date.getTime() % 1_000) * 1_000_000;
  return { seconds, nanos };
}

function fromTimestamp(t: Timestamp): Date {
  let millis = (t.seconds || 0) * 1_000;
  millis += (t.nanos || 0) / 1_000_000;
  return new globalThis.Date(millis);
}

function fromJsonTimestamp(o: any): Date {
  if (o instanceof globalThis.Date) {
    return o;
  } else if (typeof o === "string") {
    return new globalThis.Date(o);
  } else {
    return fromTimestamp(Timestamp.fromJSON(o));
  }
}

function longToNumber(long: Long): number {
  if (long.gt(globalThis.Number.MAX_SAFE_INTEGER)) {
    throw new globalThis.Error("Value is larger than Number.MAX_SAFE_INTEGER");
  }
  return long.toNumber();
}

if (_m0.util.Long !== Long) {
  _m0.util.Long = Long as any;
  _m0.configure();
}

function isSet(value: any): boolean {
  return value !== null && value !== undefined;
}
